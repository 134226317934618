import { useQueries } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';
import { getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { getFacetedMinMaxValues, getFacetedUniqueValues } from '@tanstack/table-core';
import dayjs from 'dayjs';
import type { SortingState } from '@tanstack/table-core';

import { DownloadIcon, PendingIcon, RemoveIcon } from '@apple/assets/icons';
import { getBackorderedProductsExportUri } from '@apple/features/order-retail/api/backorders';
import { useCancelOrderLine } from '@apple/features/order-retail/hooks/use-cancel-order-line';
import { backorderQueries } from '@apple/features/order-retail/queries/backorders';
import { OrderStatusDisplay } from '@apple/features/order/components/status';
import { useTranslation } from '@apple/lib/i18next';
import { DataTable, useTableState } from '@apple/ui/data-table';
import { ToolbarButton } from '@apple/ui/data-table/controls/Toolbar/toolbar-button';
import { TableLayout } from '@apple/ui/layouts';
import { Link } from '@apple/ui/link';
import { useFileDownload } from '@apple/utils/files/hooks/useFileDownload';
import { FormattedDate } from '@apple/utils/globalization';
import type {
	BackorderListing,
	BackorderSearchFilters,
} from '@apple/features/order-retail/models/backorders';

export const Route = createFileRoute('/_authed/reports/backorders')({
	component: BackorderedProductsReportRoute,
});

const defaultFilters: BackorderSearchFilters = {};
const defaultSorting: SortingState = [{ id: 'dateOrdered', desc: true }];

function BackorderedProductsReportRoute() {
	const { t } = useTranslation('order');
	const { auth } = Route.useRouteContext();
	const search = Route.useSearch();
	const navigate = Route.useNavigate();
	const cancelOrderLine = useCancelOrderLine();
	const tableState = useTableState<BackorderListing, BackorderSearchFilters>({
		search,
		navigate,
		defaultFilters,
		defaultSorting,
		fieldMap: [],
	});
	const excelTemplate = useFileDownload({
		method: 'post',
		url: getBackorderedProductsExportUri(tableState.currentFilterData),
		fallbackFilename: `backordered-products-${dayjs().format('YYYY-MM-DD')}.xlsx`,
		errorTitle: t('common:error.title'),
		errorMessage: t('common:error.fileDownload'),
	});

	const [backorderedProductsQuery] = useQueries({
		queries: [backorderQueries.list(tableState.state.pagination, tableState.currentFilterData)],
	});

	const table = useReactTable<BackorderListing>({
		data: backorderedProductsQuery.data.items,
		rowCount: backorderedProductsQuery.data.count,
		...tableState,
		enableFilters: true,
		enableColumnFilters: true,
		enableSorting: true,
		manualFiltering: true,
		manualSorting: true,
		manualPagination: true,
		getCoreRowModel: getCoreRowModel(),
		getFacetedMinMaxValues: (table, columnId) => {
			switch (columnId) {
				default:
					return getFacetedMinMaxValues<BackorderListing>()(table, columnId);
			}
		},
		getFacetedUniqueValues: (table, columnId) => {
			switch (columnId) {
				default:
					return getFacetedUniqueValues<BackorderListing>()(table, columnId);
			}
		},
		columnResizeMode: 'onChange',
		columns: [
			{
				id: 'actions',
				header: '',
				size: 25,
				enableColumnFilter: false,
				cell: ({ row }) => {
					if (row.original.isCancellationRequested) {
						return (
							<PendingIcon
								tooltip={t('backorders.labels.cancellationPending', {
									date: dayjs(row.original.cancellationRequestedOn).format('L'),
								})}
								c='yellow'
							/>
						);
					}

					const isDistroOrRefresh =
						row.original.orderType === 'Distro' ||
						row.original.orderType === 'NSO_Upgrade';

					if (
						!isDistroOrRefresh ||
						auth.hasPermission('Manzanita.Security.Orders.CanCancelDistroOrders')
					) {
						return (
							<RemoveIcon
								tooltip={t('backorders.labels.cancelOrderLine')}
								c='red'
								onClick={() => {
									cancelOrderLine.mutateWithConfirmation({
										orderNumber: row.original.purchaseOrderNumber,
										itemKey: row.original.lineItemKey,
									});
								}}
							/>
						);
					}

					return null;
				},
			},
			{
				accessorKey: 'storeId',
				header: t('backorders.fields.storeId'),
				enableColumnFilter: false,
			},
			{
				accessorKey: 'partNumber',
				header: t('backorders.fields.partNumber'),
				minSize: 350,
				enableColumnFilter: true,
				filter: {
					variant: 'text',
				},
			},
			{
				accessorKey: 'productName',
				header: t('backorders.fields.productName'),
				enableColumnFilter: false,
				minSize: 450,
			},
			{
				accessorKey: 'quantity',
				header: t('backorders.fields.quantity'),
				enableColumnFilter: false,
			},
			{
				accessorKey: 'purchaseOrderNumber',
				header: t('backorders.fields.purchaseOrderNumber'),
				enableColumnFilter: false,
				cell: ({ row }) => (
					<Link
						to={'/orders/$poNumber'}
						params={{ poNumber: row.original.purchaseOrderNumber }}
						label={row.original.purchaseOrderNumber}
						title={row.original.purchaseOrderNumber}
					/>
				),
			},
			{
				accessorKey: 'orderStatus',
				header: t('backorders.fields.orderStatus'),
				enableColumnFilter: false,
				cell: ({ row }) => (
					<OrderStatusDisplay
						status={row.original.orderStatus}
						isOrderMarkedForCancellation={row.original.isCancellationRequested}
						size='sm'
					/>
				),
			},
			{
				accessorKey: 'dateOrdered',
				header: t('backorders.fields.created'),
				enableColumnFilter: false,
				cell: ({ row }) => <FormattedDate value={row.original.dateOrdered} />,
			},
			{
				accessorKey: 'estimatedInStockDate',
				header: t('backorders.fields.estimatedInStockDate'),
				enableColumnFilter: false,
				cell: ({ row }) => <FormattedDate value={row.original.estimatedInStockDate} />,
			},
		],
	});

	return (
		<TableLayout
			title={t('backorders.title')}
			table={table}
			toolbarButtons={[
				<ToolbarButton
					key='download'
					tooltip={t('backorders.toolbar.export')}
					icon={DownloadIcon}
					loading={excelTemplate.downloading}
					onClick={() => void excelTemplate.download()}
				/>,
			]}
		>
			<DataTable
				table={table}
				variant='apple-table'
				loading={backorderedProductsQuery.isFetching}
			/>
		</TableLayout>
	);
}
