import { queryOptions } from '@tanstack/react-query';

import { getItemsCount, getOrCreateCart } from '../api';

export const cartQueryKeys = {
	all: ['cart'],
	cartId: ['cart', 'id'],
	selectedQuantitiesCount: ['cart', 'quantities', 'count'],
} as const;

export const cartQueryOptions = {
	cartId: (enabled: boolean) =>
		queryOptions({
			queryKey: cartQueryKeys.cartId,
			queryFn: ({ signal }) => getOrCreateCart(signal),
			enabled,
		}),
	selectedQuantitiesCount: ({ enabled }: { enabled: boolean }) =>
		queryOptions({
			queryKey: cartQueryKeys.selectedQuantitiesCount,
			queryFn: ({ signal }) => getItemsCount(signal),
			enabled,
			initialData: 0,
		}),
} as const;
