import { keepPreviousData, queryOptions } from '@tanstack/react-query';
import type { OdataQueryBuilder } from 'odata-builder';

import { storeEventQueryKeys } from '@apple/features/store-events/queries';
import { createDataTableQueryOptionsSchema } from '@apple/ui/data-table';
import type { DataTableQueryOptions } from '@apple/ui/data-table';
import { DEFAULT_ODATA_PAGE_STATE, escapeODataFilterValue, execODataQuery } from '@apple/utils/api';
import type { StoreEventFilters, StoreEventListingModel } from '../models/listing';
import { storeEventFiltersSchema, storeEventListingModelSchema } from '../models/listing';

const storeEventQueryOptionsSchema =
	createDataTableQueryOptionsSchema<StoreEventFilters>(storeEventFiltersSchema);

export function storeEventsOdataQueryOptions(options: DataTableQueryOptions<StoreEventFilters>) {
	const { pagination, filters } = storeEventQueryOptionsSchema.parse(options);
	return queryOptions({
		queryKey: storeEventQueryKeys.listPage(pagination, filters),
		queryFn: async ({ signal }) =>
			await execODataQuery<StoreEventListingModel>({
				urlPath: '/odata/management/store-events',
				rowSchema: storeEventListingModelSchema,
				builderFn: odataBuilder => buildStoreEventODataQuery(odataBuilder, options.filters),
				pagination,
				signal,
			}),
		placeholderData: keepPreviousData,
		throwOnError: true,
		initialData: DEFAULT_ODATA_PAGE_STATE,
		retry: false,
	});
}

function buildStoreEventODataQuery(
	builder: OdataQueryBuilder<StoreEventListingModel>,
	filters: StoreEventFilters,
) {
	builder.orderBy({
		field: 'fullKitDeliveryDate',
		orderDirection: 'asc',
	});

	if (filters.warehouseCode !== undefined) {
		builder.filter({
			field: 'warehouseCode',
			operator: 'eq',
			value: filters.warehouseCode,
		});
	}
	if (filters.type !== undefined) {
		builder.filter({
			field: 'type',
			operator: 'eq',
			value: filters.type,
		});
	}
	if (filters.status !== undefined) {
		builder.filter({
			field: 'status',
			operator: 'eq',
			value: filters.status,
		});
	}
	if (filters.hasLongPendingLockedItems !== undefined) {
		builder.filter({
			field: 'hasLongPendingLockedItems',
			operator: 'eq',
			value: filters.hasLongPendingLockedItems,
		});
	}
	if (filters.thirdPartyCustomerId !== undefined) {
		builder.filter({
			field: 'thirdPartyCustomerId',
			operator: 'contains',
			value: escapeODataFilterValue(filters.thirdPartyCustomerId),
			ignoreCase: true,
		});
	}
}
