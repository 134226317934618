import { notifications } from '@mantine/notifications';
import { useSuspenseQueries } from '@tanstack/react-query';
import { createFileRoute, useNavigate } from '@tanstack/react-router';
import { z } from 'zod';

import { ErrorIcon, Success } from '@apple/assets/icons';
import { requireAuth } from '@apple/features/auth';
import { PlantForm } from '@apple/features/plants-retail/components/PlantForm';
import { useManagePlant } from '@apple/features/plants/hooks/useManagePlant';
import { plantQueryOptions } from '@apple/features/plants/queries/queries';
import { useTranslation } from '@apple/lib/i18next';
import { DetailsLayout } from '@apple/ui/layouts/DetailsLayout';
import { globalizationQueryOptions } from '@apple/utils/globalization';
import type { PlantFormData } from '@apple/features/plants/models/listing';

export const Route = createFileRoute('/_authed/_admin/manage/plants_/$warehouseLocationId')({
	parseParams: ({ warehouseLocationId }) => ({
		warehouseLocationId: z.string().parse(warehouseLocationId),
	}),
	beforeLoad: ({ params: { warehouseLocationId }, ...args }) => {
		requireAuth(args, {
			requirePermission: 'Manzanita.Security.Features.PlantManagement.Read',
		});

		return {
			plantListingQueryOptions: plantQueryOptions.odata,
			plantsDetailQueryOptions: plantQueryOptions.plant(warehouseLocationId),
		};
	},
	loader: async ({
		context: { queryClient, plantListingQueryOptions, plantsDetailQueryOptions },
	}) => {
		await queryClient.fetchQuery(plantListingQueryOptions);
		await queryClient.fetchQuery(plantsDetailQueryOptions);
		await queryClient.fetchQuery(globalizationQueryOptions.countriesWithCultures(false));
	},
	wrapInSuspense: true,
	component: PlantRoute,
});

function PlantRoute() {
	const { t } = useTranslation('plant');
	const navigate = useNavigate();

	const { plantListingQueryOptions, plantsDetailQueryOptions } = Route.useRouteContext();

	const [plantDetailsQuery, allPlantsQuery, countriesQuery] = useSuspenseQueries({
		queries: [
			plantsDetailQueryOptions,
			plantListingQueryOptions,
			globalizationQueryOptions.countriesWithCultures(false),
		],
	});

	const { plant, availableCountries, mutation } = useManagePlant({
		plant: plantDetailsQuery.data,
		allPlants: allPlantsQuery.data,
		countries: countriesQuery.data,
	});

	async function handleSave(plant: PlantFormData) {
		await mutation.mutateAsync(plant, {
			onSuccess: () => {
				notifications.show({
					title: t('notifications.successTitle'),
					message: t('notifications.updateSuccess'),
					icon: <Success />,
					color: 'green',
					autoClose: 5000,
				});
				void navigate({ to: '/manage/plants' });
			},
			onError: () => {
				notifications.show({
					message: t('common:error.generic'),
					icon: <ErrorIcon />,
					color: 'red',
					autoClose: 5000,
				});
			},
		});
	}

	return (
		<DetailsLayout title={t('title.edit')}>
			<PlantForm plant={plant} countries={availableCountries} onSave={handleSave} />
		</DetailsLayout>
	);
}
