import { Container } from '@mantine/core';
import { createFileRoute } from '@tanstack/react-router';

import { requireAuth } from '@apple/features/auth';
import { useTranslation } from '@apple/lib/i18next';
import { useAppleRetailAdminDashboard } from '@apple/retail/hooks/useAppleRetailAdminDashboard';
import { AdminDashboard } from '@apple/retail/routes/_authed/-dashboard/admin';
import { UserHomePage } from '@apple/retail/routes/_authed/-dashboard/user';
import { TitleLayout } from '@apple/ui/layouts/TitleLayout';

export const Route = createFileRoute('/_authed/')({
	beforeLoad: args => requireAuth(args),
	component: DashboardRoute,
});

export function DashboardRoute() {
	const { auth } = Route.useRouteContext();
	return auth.hasPermissions(['Manzanita.Security.Features.Snapshot']) ? (
		<AdminDashboardView />
	) : (
		<UserDashboardView />
	);
}

export function UserDashboardView() {
	const { auth } = Route.useRouteContext();
	const navigate = Route.useNavigate();
	const search = Route.useSearch();
	return (
		!auth.hasPermissions(['Manzanita.Security.Features.Snapshot']) && (
			<Container size='xl' mt='md'>
				<UserHomePage navigate={navigate} search={search} />
			</Container>
		)
	);
}

function AdminDashboardView() {
	const { t } = useTranslation('admin-dashboard');
	const { cards } = useAppleRetailAdminDashboard();
	const { auth } = Route.useRouteContext();

	return (
		<TitleLayout title={t('welcome', { name: auth.profile?.firstName })}>
			<AdminDashboard cards={cards} />
		</TitleLayout>
	);
}
