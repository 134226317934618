import { useCallback } from 'react';
import { Divider, Group, Text } from '@mantine/core';
import { useQueries } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';
import { useReactTable } from '@tanstack/react-table';
import {
	getCoreRowModel,
	getExpandedRowModel,
	getFacetedRowModel,
	getFacetedUniqueValues,
	getFilteredRowModel,
	getSortedRowModel,
} from '@tanstack/table-core';

import { AddIcon, ExportIcon } from '@apple/assets/icons';
import { requireAuth, useUserRoles } from '@apple/features/auth';
import { getUserDataTableQueryOptions } from '@apple/features/user-retail/queries/odata';
import { useTranslation } from '@apple/lib/i18next';
import { DataTable, useTableDownload, useTableState } from '@apple/ui/data-table';
import { ToolbarButton } from '@apple/ui/data-table/controls/Toolbar';
import { TableLayout } from '@apple/ui/layouts';
import { Link } from '@apple/ui/link';
import { FormattedBoolean, FormattedDate } from '@apple/utils/globalization';
import type { UserFilters, UserListing } from '@apple/features/user/models/listing';

export const Route = createFileRoute('/_authed/_admin/manage/users')({
	beforeLoad: args =>
		requireAuth(args, {
			requirePermission: 'Manzanita.Security.Features.UserManagement.Read',
		}),
	component: UserManagementRoute,
});

const defaultFilters: UserFilters = {
	isActive: 'active',
};

function UserManagementRoute() {
	const { t } = useTranslation('manage');
	const navigate = Route.useNavigate();
	const search = Route.useSearch();
	const userRoles = useUserRoles();

	const tableState = useTableState<UserListing, UserFilters>({
		search,
		navigate,
		defaultFilters: defaultFilters,
		defaultSorting: [{ id: 'Username', desc: false }],
		fieldMap: [],
	});

	const [usersQuery] = useQueries({
		queries: [
			getUserDataTableQueryOptions({
				filters: tableState.currentFilterData,
				pagination: tableState.state.pagination,
				commonFilters: {},
			}),
		],
	});

	const table = useReactTable<UserListing>({
		data: usersQuery.data.rows,
		rowCount: usersQuery.data.totalRowCount,
		...tableState,
		enableFilters: true,
		enableColumnFilters: true,
		enableSorting: true,
		manualFiltering: true,
		manualSorting: true,
		manualPagination: true,
		getCoreRowModel: getCoreRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getExpandedRowModel: getExpandedRowModel(),
		getFacetedRowModel: getFacetedRowModel(),
		getFacetedUniqueValues: (table, columnId) => {
			switch (columnId) {
				case 'userRoles':
					return () =>
						new Map(
							!userRoles.loading && !userRoles.error
								? userRoles.roles?.map(x => [x.name, 1])
								: [],
						);
				case 'isActive':
					return () => new Map([undefined, 'active', 'inactive'].map(x => [x, 1]));
				default:
					return getFacetedUniqueValues<UserListing>()(table, columnId);
			}
		},
		columnResizeMode: 'onChange',
		columns: [
			{
				id: 'username',
				accessorKey: 'username',
				header: t('users.fields.userName'),
				filter: {
					group: t('users.filterGroups.details'),
					label: t('users.filters.nameOrEmail'),
					sortOrder: 1,
				},
				cell: ({ row }) => (
					<Link to='/manage/users/$userName' params={{ userName: row.original.username }}>
						<Text size='sm'>{row.original.username}</Text>
					</Link>
				),
			},
			{
				id: 'email',
				accessorKey: 'email',
				header: t('users.fields.emailAddress'),
				enableColumnFilter: false,
			},
			{
				id: 'firstName',
				accessorKey: 'firstName',
				header: t('users.fields.firstName'),
				enableColumnFilter: false,
			},
			{
				id: 'lastName',
				accessorKey: 'lastName',
				header: t('users.fields.lastName'),
				enableColumnFilter: false,
			},
			{
				id: 'userRoles',
				accessorKey: 'userRoles',
				header: t('users.fields.userRole'),
				filter: {
					group: t('users.filterGroups.details'),
					variant: 'select',
					sortOrder: 2,
				},
			},
			{
				accessorKey: 'customerCount',
				header: t('users.fields.storeCount'),
				enableColumnFilter: false,
				cell: ({ row }) => <Text>{row.original.customerCount}</Text>,
			},
			{
				id: 'isActive',
				accessorKey: 'isActive',
				header: t('users.fields.userStatus'),
				cell: ({ row }) => (
					<Group>
						<FormattedBoolean value={row.original.isActive} useIcon />
						<Text>
							{row.original.isActive
								? t('users.fields.active')
								: t('users.fields.inactive')}
						</Text>
					</Group>
				),
				filter: {
					group: t('users.filterGroups.details'),
					variant: 'segmented-switch',
					sortOrder: 11,
					getFilterDisplayValue: x => {
						switch (x) {
							case 'active':
								return t('user:profile.labels.active');
							case 'inactive':
								return t('user:profile.labels.inactive');
							default:
								return t('common:label.all');
						}
					},
				},
			},
			{
				id: 'lastSignInDate',
				accessorKey: 'lastSignInDate',
				header: t('users.fields.lastSignInDate'),
				filter: {
					group: t('users.filterGroups.details'),
					variant: 'date-range',
					sortOrder: 4,
				},
				cell: ({ row }) =>
					row.original.lastSignInDate && (
						<FormattedDate value={row.original.lastSignInDate} humanize />
					),
			},
			{
				id: 'createDate',
				accessorKey: 'createDate',
				header: t('users.fields.createdDate'),
				filter: {
					group: t('users.filterGroups.details'),
					variant: 'date-range',
					sortOrder: 5,
				},
				cell: ({ row }) =>
					row.original.createDate && <FormattedDate value={row.original.createDate} />,
			},
			{
				id: 'activeDate',
				accessorKey: 'activeDate',
				header: t('users.fields.activeDate'),
				cell: ({ row }) =>
					row.original.activeDate && <FormattedDate value={row.original.activeDate} />,
				enableColumnFilter: false,
			},
			{
				id: 'inactiveDate',
				accessorKey: 'inactiveDate',
				header: t('users.fields.inactiveDate'),
				cell: ({ row }) =>
					row.original.inactiveDate && (
						<FormattedDate value={row.original.inactiveDate} />
					),
				enableColumnFilter: false,
			},
		],
	});

	const tableDownload = useTableDownload({
		table,
		queryOptionsFn: getUserDataTableQueryOptions,
	});

	const handleExport = useCallback(() => {
		void tableDownload.download({
			filters: tableState.currentFilterData,
			fileName: 'Users',
		});
	}, [tableDownload, tableState.currentFilterData]);

	return (
		<TableLayout
			title={t('users.title')}
			table={table}
			toolbarButtons={[
				<ToolbarButton
					key='export'
					loading={tableDownload.downloading}
					tooltip={t('users.buttons.export.tooltip')}
					icon={ExportIcon}
					onClick={handleExport}
				/>,
				<Divider key='divider' orientation='vertical' mx='xs' />,
				<ToolbarButton
					key='add'
					tooltip={t('users.buttons.add.tooltip')}
					icon={AddIcon}
					onClick={() =>
						void navigate({
							to: '/manage/users/add',
						})
					}
				/>,
			]}
		>
			<DataTable table={table} minWidth='3000px' loading={usersQuery.isFetching} />
		</TableLayout>
	);
}
