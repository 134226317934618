import { useSuspenseQueries } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';

import { requireAuth } from '@apple/features/auth';
import { OrderApprovalDetail } from '@apple/features/order-retail/components/OrderDetails/OrderApprovalDetail';
import { orderQueries } from '@apple/features/order-retail/queries/order';
import { useTranslation } from '@apple/lib/i18next';
import { DetailsLayout } from '@apple/ui/layouts';
import { NotFoundPage } from '@apple/ui/shell/pages/NotFoundPage';
import { NotAuthorizedError } from '@apple/utils/api';

export const Route = createFileRoute('/_authed/_admin/approvals/orders_/$poNumber')({
	errorComponent: ({ error }) => {
		if (error instanceof NotAuthorizedError) {
			return <NotFoundPage />;
		} else {
			throw error;
		}
	},
	parseParams: ({ poNumber }) => ({
		poNumber: z.string().parse(poNumber),
	}),
	beforeLoad: ({ ...args }) => {
		requireAuth(args, {
			requirePermission: 'Manzanita.Security.Features.OrderManagement.Write',
		});
	},
	wrapInSuspense: true,
	component: OrderRoute,
});

function OrderRoute() {
	const { t } = useTranslation('order');
	const { poNumber } = Route.useParams();
	const [orderQuery, orderLinesQuery, orderCommentsQuery] = useSuspenseQueries({
		queries: [
			orderQueries.order(poNumber),
			orderQueries.orderLines(poNumber),
			orderQueries.orderComments(poNumber),
		],
	});

	return (
		<DetailsLayout
			title={t('orderDetails.title', {
				orderNumber: orderQuery.data.purchaseOrderNumber,
			})}
		>
			<OrderApprovalDetail
				order={orderQuery.data}
				lines={orderLinesQuery.data}
				comments={orderCommentsQuery.data}
			/>
		</DetailsLayout>
	);
}
