import { useCallback } from 'react';
import { Divider, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useQueries } from '@tanstack/react-query';
import { createFileRoute, Link } from '@tanstack/react-router';
import { getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { getFacetedMinMaxValues, getFacetedUniqueValues } from '@tanstack/table-core';
import camelCase from 'lodash-es/camelCase';
import type { SortingState } from '@tanstack/table-core';

import { AddIcon, DownloadIcon, Upload } from '@apple/assets/icons';
import {
	deleteRecurringOrders,
	getRecurringOrderDataTableQueryOptions,
	getRecurringOrdersTemplateUrl,
	saveRecurringOrders,
	uploadRecurringOrdersExcelFile,
} from '@apple/features/order-retail/api/recurring';
import { recurringOrderFrequencySchema } from '@apple/features/order-retail/models/recurring';
import { recurringOrderQueryKeys } from '@apple/features/order-retail/queries/recurring';
import { useTranslation } from '@apple/lib/i18next';
import { createFilterOnlyColumn, DataTable, useTableState } from '@apple/ui/data-table';
import { ToolbarButton } from '@apple/ui/data-table/controls/Toolbar';
import { TableLayout } from '@apple/ui/layouts';
import { ExcelUploadDialog } from '@apple/utils/files/components/ExcelUploadDialog';
import { useFileDownload } from '@apple/utils/files/hooks/useFileDownload';
import {
	FormattedBoolean,
	FormattedDate,
	globalizationQueryOptions,
} from '@apple/utils/globalization';
import type {
	RecurringOrderEntity,
	RecurringOrderFilters,
	RecurringOrderListing,
} from '@apple/features/order-retail/models/recurring';

export const Route = createFileRoute('/_authed/orders/recurring')({
	component: RecurringOrderListRoute,
});

const defaultFilters: RecurringOrderFilters = {};
const defaultSorting: SortingState = [{ id: 'storeId', desc: true }];

function RecurringOrderListRoute() {
	const { t } = useTranslation('order-recurring');
	const search = Route.useSearch();
	const navigate = Route.useNavigate();
	const { auth, queryClient } = Route.useRouteContext();
	const [uploadOpen, uploadActions] = useDisclosure(false);
	const excelTemplate = useFileDownload({
		method: 'get',
		url: getRecurringOrdersTemplateUrl(),
		fallbackFilename: 'recurring-orders.xlsx',
		errorTitle: t('common:error.title'),
		errorMessage: t('common:error.fileDownload'),
	});

	const tableState = useTableState<RecurringOrderListing, RecurringOrderFilters>({
		search,
		navigate,
		defaultFilters,
		defaultSorting,
		fieldMap: [],
	});

	const [recurringOrderQuery, countriesQuery] = useQueries({
		queries: [
			getRecurringOrderDataTableQueryOptions<RecurringOrderFilters>({
				pagination: tableState.state.pagination,
				filters: tableState.currentFilterData,
			}),
			globalizationQueryOptions.countries,
		],
	});

	const table = useReactTable<RecurringOrderListing>({
		data: recurringOrderQuery.data.rows,
		rowCount: recurringOrderQuery.data.totalRowCount,
		...tableState,
		enableFilters: true,
		enableColumnFilters: true,
		enableSorting: true,
		manualFiltering: true,
		manualSorting: true,
		manualPagination: true,
		getCoreRowModel: getCoreRowModel(),
		getFacetedMinMaxValues: getFacetedMinMaxValues(),
		getFacetedUniqueValues: (table, columnId) => {
			switch (columnId) {
				case 'frequencyCode':
					return () =>
						new Map(recurringOrderFrequencySchema._def.values.map(x => [x, 1]));
				case 'countryCode':
					return () => new Map(countriesQuery.data?.map(x => [x.Key, 1]));
				case 'isActive':
					return () => new Map([undefined, true, false].map(x => [x, 1]));
				default:
					return getFacetedUniqueValues<RecurringOrderListing>()(table, columnId);
			}
		},
		columnResizeMode: 'onChange',
		columns: [
			{
				accessorKey: 'orderNumber',
				enableColumnFilter: true,
				header: t('fields.orderNumber'),
				filter: {
					group: t('filterGroups.details'),
				},
				cell: ({ row }) => (
					<Link
						to='/orders/recurring/$id'
						params={{ id: row.original.id.toString() }}
						title={t('links.view')}
					>
						<Text size='sm'>{row.original.orderNumber}</Text>
					</Link>
				),
			},
			{
				accessorKey: 'orderName',
				header: t('fields.orderName'),
				enableColumnFilter: true,
				filter: {
					group: t('filterGroups.details'),
				},
			},
			{
				accessorKey: 'storeId',
				header: t('fields.storeId'),
				enableColumnFilter: true,
				filter: {
					group: t('filterGroups.details'),
				},
			},
			{
				accessorKey: 'isActive',
				header: t('fields.status'),
				enableColumnFilter: true,
				filter: {
					group: t('filterGroups.details'),
					variant: 'segmented-switch',
					getFilterDisplayValue: x => {
						switch (x) {
							case true:
								return t('labels.active');
							case false:
								return t('labels.inactive');
							default:
								return t('common:label.all');
						}
					},
				},
				cell: ({ row }) => <FormattedBoolean value={row.original.isActive} useIcon />,
			},
			{
				accessorKey: 'orderTotal',
				header: t('fields.orderTotal'),
				enableColumnFilter: false,
			},
			{
				accessorKey: 'frequencyCode',
				header: t('fields.frequency'),
				enableColumnFilter: true,
				filter: {
					group: t('filterGroups.details'),
					variant: 'select',
					getFilterDisplayValue: value =>
						t(`labels.frequency.${camelCase(value as string)}`),
				},
				cell: ({ row }) => row.original.frequency,
			},
			{
				accessorKey: 'nextProcessingDate',
				header: t('fields.nextProcessingDate'),
				enableColumnFilter: false,
				cell: ({ row }) => <FormattedDate value={row.original.nextProcessingDate} />,
			},
			createFilterOnlyColumn<RecurringOrderListing, RecurringOrderFilters>({
				group: t('filterGroups.details'),
				label: t('fields.country'),
				field: 'countryCode',
				variant: 'select',
				getFilterDisplayValue: value =>
					countriesQuery.data?.find(x => x.Key === value)?.Value ?? String(value),
			}),
		],
	});

	const handleUploadConfirmed = useCallback(async () => {
		await queryClient.resetQueries({
			queryKey: recurringOrderQueryKeys.all,
		});
		await recurringOrderQuery.refetch();
	}, [queryClient, recurringOrderQuery]);

	return (
		<TableLayout
			title={t('title')}
			table={table}
			toolbarButtons={
				!auth.hasPermission('AppleRetail.Security.Features.RecurringOrdersManagement')
					? []
					: [
							<ToolbarButton
								key='add'
								tooltip={t('buttons.add.tooltip')}
								icon={AddIcon}
								onClick={() => {
									void navigate({ to: '/orders/recurring/add' });
								}}
							/>,
							<Divider key='divider' orientation='vertical' mx='xs' />,
							<ToolbarButton
								key='download'
								tooltip={t('buttons.download.tooltip')}
								icon={DownloadIcon}
								loading={excelTemplate.downloading}
								onClick={() => void excelTemplate.download()}
							/>,
							<ToolbarButton
								key='upload'
								tooltip={t('buttons.upload.tooltip')}
								icon={Upload}
								disabled={uploadOpen}
								onClick={uploadActions.open}
							/>,
						]
			}
		>
			<DataTable
				table={table}
				variant='apple-table'
				loading={recurringOrderQuery.isFetching}
			/>
			<ExcelUploadDialog<RecurringOrderEntity, 'orderNumber'>
				keyProperty={'orderNumber'}
				keyLabel={t('fields.orderNumber')}
				opened={uploadOpen}
				close={uploadActions.close}
				onUpload={uploadRecurringOrdersExcelFile}
				onSave={saveRecurringOrders}
				onDelete={deleteRecurringOrders}
				onConfirmed={handleUploadConfirmed}
			/>
		</TableLayout>
	);
}
