import { keepPreviousData, queryOptions } from '@tanstack/react-query';

import {
	getBudgetDetailsItem,
	getBudgetSummary,
	getProductPurchaseDetailItems,
} from '../api/budget';

export const budgetKeys = {
	all: ['budget'],
	summary: (singleStoreCustomerCode: string, fiscalYear: number) => [
		'budget',
		singleStoreCustomerCode,
		fiscalYear,
	],
	detail: (singleStoreCustomerCode: string, fiscalYear: number, periodNumber: number) => [
		'budget',
		singleStoreCustomerCode,
		fiscalYear,
		periodNumber,
	],
	category: (
		singleStoreCustomerCode: string,
		fiscalYear: number,
		periodNumber: number,
		categoryId: number,
	) => ['budget', singleStoreCustomerCode, fiscalYear, periodNumber, categoryId],
} as const;

export const budgetQueryOptions = {
	budgetSummary: (singleStoreCustomerCode: string, fiscalYear: number) =>
		queryOptions({
			queryKey: budgetKeys.summary(singleStoreCustomerCode, fiscalYear),
			queryFn: () => getBudgetSummary(singleStoreCustomerCode, fiscalYear),
			staleTime: Infinity,
			throwOnError: true,
		}),
	budgetDetails: (singleStoreCustomerCode: string, fiscalYear: number, periodNumber: number) =>
		queryOptions({
			queryKey: budgetKeys.detail(singleStoreCustomerCode, fiscalYear, periodNumber),
			queryFn: () => getBudgetDetailsItem(singleStoreCustomerCode, fiscalYear, periodNumber),
			staleTime: 5 * 60 * 1000,
			placeholderData: keepPreviousData,
		}),
	productPurchaseDetail: (
		singleStoreCustomerCode: string,
		fiscalYear: number,
		periodNumber: number,
		categoryId: number,
	) =>
		queryOptions({
			queryKey: budgetKeys.category(
				singleStoreCustomerCode,
				fiscalYear,
				periodNumber,
				categoryId,
			),
			queryFn: () =>
				getProductPurchaseDetailItems(
					singleStoreCustomerCode,
					fiscalYear,
					periodNumber,
					categoryId,
				),
			staleTime: Infinity,
			throwOnError: true,
		}),
};
