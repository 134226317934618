import { queryOptions } from '@tanstack/react-query';

import {
	get,
	getAll,
	getCurrentFiscalPeriod,
	getNewNewFiscalYear,
} from '@apple/features/calendar/api';

export const calendarKeys = {
	all: ['fiscal-calendar'],
	list: ['fiscal-calendar', 'list'],
	fiscalYear: (fiscalYear: number) => ['fiscal-calendar', fiscalYear],
	add: ['fiscal-calendar', 'add'],
	current: ['fiscal-calendar', 'current'],
} as const;

export const calendarQueryOptions = {
	newFiscalYear: queryOptions({
		queryKey: calendarKeys.add,
		queryFn: () => getNewNewFiscalYear(),
		staleTime: Infinity,
		throwOnError: true,
	}),
	list: queryOptions({
		queryKey: calendarKeys.list,
		queryFn: () => getAll(),
		staleTime: Infinity,
	}),
	fiscalYear: (fiscalYear: number) =>
		queryOptions({
			queryKey: calendarKeys.fiscalYear(fiscalYear),
			queryFn: () => get(fiscalYear),
			staleTime: Infinity,
			throwOnError: true,
		}),
	current: queryOptions({
		queryKey: calendarKeys.current,
		queryFn: () => getCurrentFiscalPeriod(),
		staleTime: Infinity,
	}),
};
