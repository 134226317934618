import { getEnv } from '@apple/utils/config/env';

import type { RoleName } from '../models/roles';
import type { Permission, ProfileDto } from '../models/user';

const env = getEnv();

export type AuthOptions = AuthRequirements<{
	returnUrl?: string;
}>;

export type AuthRequirements<T> = T &
	Partial<{
		requireAuth: boolean;
		requireRole: RoleName;
		requireRoles: RoleName[];
		requirePermission: Permission;
		requirePermissions: Permission[];
	}>;

export function isAdmin(profile: ProfileDto | null | undefined) {
	if (!profile) {
		return false;
	}

	return profile.roles.includes(env.APPLE_ADMIN_ROLE);
}

export function hasRoles(profile: ProfileDto | null | undefined, roles: RoleName[]) {
	if (!profile) {
		return false;
	}
	return isAdmin(profile) || roles.every(role => profile.roles.includes(role));
}

export function hasPermissions(profile: ProfileDto | null | undefined, permissions: Permission[]) {
	if (!profile) {
		return false;
	}

	return (
		hasRoles(profile, [env.APPLE_ADMIN_ROLE]) ||
		permissions.every(permission => profile.permissions.includes(permission))
	);
}

export function isAuthorized(
	profile: ProfileDto | null | undefined,
	requirements: AuthRequirements<unknown>,
): boolean {
	if (requirements.requireAuth && (profile === null || profile === undefined)) {
		return false;
	}

	let authorized = true;

	if (requirements.requireRole) {
		authorized = authorized && hasRoles(profile, [requirements.requireRole]);
	}

	if (requirements.requireRoles) {
		authorized = authorized && hasRoles(profile, requirements.requireRoles);
	}

	if (requirements.requirePermission) {
		authorized = authorized && hasPermissions(profile, [requirements.requirePermission]);
	}

	if (requirements.requirePermissions) {
		authorized = authorized && hasPermissions(profile, requirements.requirePermissions);
	}

	return authorized;
}

export function filterByPermissions<T>(
	profile: ProfileDto | null | undefined,
	items: AuthRequirements<T>[],
	removeAuthRequirementsProps = true,
): T[] {
	const filtered = items.filter(item => isAuthorized(profile, item));
	if (!removeAuthRequirementsProps) {
		return filtered;
	}

	return filtered.map(item => {
		delete item.requireAuth;
		delete item.requireRole;
		delete item.requireRoles;
		delete item.requireRoles;
		delete item.requirePermission;
		delete item.requirePermissions;
		return item;
	});
}
