import { Card, Drawer, Group, Loader, SimpleGrid, Stack, Text, Title } from '@mantine/core';
import { useDidUpdate, useDisclosure } from '@mantine/hooks';
import { useQueries, useQuery } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';
import { getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { getFacetedMinMaxValues, getFacetedUniqueValues } from '@tanstack/table-core';
import dayjs from 'dayjs';
import camelCase from 'lodash-es/camelCase';
import type { SortingState } from '@tanstack/table-core';

import { DownloadIcon } from '@apple/assets/icons';
import { userRolesQueryOptions } from '@apple/features/auth/queries/roles';
import { getUserActivityExportUri } from '@apple/features/user-retail/api/activity';
import {
	activityLogEntityTypeSchema,
	eventTypeSchemas,
} from '@apple/features/user-retail/models/activity';
import { activityLogQueries } from '@apple/features/user-retail/queries/activity';
import { useTranslation } from '@apple/lib/i18next';
import { createFilterOnlyColumn, DataTable, useTableState } from '@apple/ui/data-table';
import { ToolbarButton } from '@apple/ui/data-table/controls/Toolbar/toolbar-button';
import { TableLayout } from '@apple/ui/layouts';
import { List, ListItem } from '@apple/ui/list';
import { DisclosureText } from '@apple/ui/typogrophy/components/disclosure-text';
import { useFileDownload } from '@apple/utils/files/hooks/useFileDownload';
import { FormattedDate } from '@apple/utils/globalization';
import type {
	ActivityLogFilters,
	ActivityLogListing,
} from '@apple/features/user-retail/models/activity';

export const Route = createFileRoute('/_authed/_admin/reports/activity-log')({
	component: UserActivityReportRoute,
});

const defaultFilters: ActivityLogFiltersExtended = {
	dateRange: '60',
};
const defaultSorting: SortingState = [{ id: 'dateCreated', desc: true }];

interface ActivityLogFiltersExtended extends ActivityLogFilters {
	dateRange: '30' | '60' | '90' | 'custom';
}

function UserActivityReportRoute() {
	const { t } = useTranslation('user');
	const search = Route.useSearch();
	const navigate = Route.useNavigate();
	const tableState = useTableState<ActivityLogListing, ActivityLogFiltersExtended>({
		search,
		navigate,
		defaultFilters,
		defaultSorting,
		fieldMap: [],
	});
	const excelTemplate = useFileDownload({
		method: 'post',
		url: getUserActivityExportUri(),
		data: tableState.currentFilterData,
		fallbackFilename: `activity-log-${dayjs().format('YYYY-MM-DD')}.xlsx`,
		errorTitle: t('common:error.title'),
		errorMessage: t('common:error.fileDownload'),
	});

	const [activityLogQuery, rolesQuery] = useQueries({
		queries: [
			activityLogQueries.search(tableState.state.pagination, tableState.currentFilterData),
			userRolesQueryOptions,
		],
	});

	useDidUpdate(() => {
		// Reset the event filter when the entity filter changes
		tableState.onColumnFiltersChange(curr => curr.filter(x => x.id !== 'event'));
	}, [tableState.currentFilterData.entity]);

	const table = useReactTable<ActivityLogListing>({
		data: activityLogQuery.data.items,
		rowCount: activityLogQuery.data.count,
		...tableState,
		enableFilters: true,
		enableColumnFilters: true,
		enableSorting: true,
		manualFiltering: true,
		manualSorting: true,
		manualPagination: true,
		getCoreRowModel: getCoreRowModel(),
		getFacetedMinMaxValues: getFacetedMinMaxValues<ActivityLogListing>(),
		getFacetedUniqueValues: (table, columnId) => {
			switch (columnId) {
				case 'entity':
					return () => new Map(activityLogEntityTypeSchema._def.values.map(x => [x, 1]));
				case 'event':
					return () =>
						!tableState.currentFilterData.entity
							? new Map()
							: new Map(
									eventTypeSchemas[
										tableState.currentFilterData.entity
									]._def.values.map(x => [x, 1]),
								);
				case 'role':
					return () => new Map(rolesQuery.data.map(x => [x.name, 1]));
				case 'dateRange':
					return () =>
						new Map([
							['30', 1],
							['60', 1],
							['90', 1],
							['custom', 1],
						]);
				default:
					return getFacetedUniqueValues<ActivityLogListing>()(table, columnId);
			}
		},
		columnResizeMode: 'onChange',
		columns: [
			{
				accessorKey: 'dateCreated',
				header: t('activity.fields.created'),
				enableColumnFilter: false,
				minSize: 300,
				cell: ({ row }) => <FormattedDate value={row.original.dateCreated} showTime />,
			},
			{
				accessorKey: 'updatedByName',
				header: t('activity.fields.user'),
				enableColumnFilter: false,
				size: 50,
				cell: ({ row }) => row.original.updatedByName ?? t('activity.columns.user.system'),
			},
			{
				accessorKey: 'updatedByRole',
				header: t('activity.fields.role'),
				enableColumnFilter: false,
				size: 200,
			},
			{
				accessorKey: 'entityType',
				header: t('activity.fields.entity'),
				enableColumnFilter: false,
				size: 100,
			},
			{
				id: 'activity',
				header: t('activity.fields.event'),
				size: 250,
				enableColumnFilter: false,
				cell: ({ row }) => <ActivityDetails activityLog={row.original} />,
			},
			createFilterOnlyColumn<ActivityLogListing, ActivityLogFiltersExtended>({
				group: t('activity.filterGroups.details'),
				label: t('activity.fields.dateRange'),
				field: 'dateRange',
				variant: 'select',
				getFilterDisplayValue: value =>
					value === 'custom'
						? t('controls:dataTable.filters.options.dateRange.custom')
						: t('controls:dataTable.filters.options.dateRange.lastNumberOfDays', {
								days: value,
							}),
			}),
			createFilterOnlyColumn<ActivityLogListing, ActivityLogFiltersExtended>({
				group: t('activity.filterGroups.details'),
				label: t('activity.fields.startDate'),
				field: 'startDate',
				variant: 'date',
				hidden: tableState.currentFilterData.dateRange !== 'custom',
			}),
			createFilterOnlyColumn<ActivityLogListing, ActivityLogFiltersExtended>({
				group: t('activity.filterGroups.details'),
				label: t('activity.fields.endDate'),
				field: 'endDate',
				variant: 'date',
				hidden: tableState.currentFilterData.dateRange !== 'custom',
			}),
			createFilterOnlyColumn<ActivityLogListing, ActivityLogFiltersExtended>({
				group: t('activity.filterGroups.details'),
				label: t('activity.fields.role'),
				field: 'role',
				variant: 'select',
				getFilterDisplayValue: String,
			}),
			createFilterOnlyColumn<ActivityLogListing, ActivityLogFiltersExtended>({
				group: t('activity.filterGroups.details'),
				label: t('activity.fields.usernameOrEmail'),
				field: 'userId',
				variant: 'text',
			}),
			createFilterOnlyColumn<ActivityLogListing, ActivityLogFiltersExtended>({
				group: t('activity.filterGroups.details'),
				label: t('activity.fields.entity'),
				field: 'entity',
				variant: 'select',
				getFilterDisplayValue: value =>
					t(`activity.types.entity.${camelCase(String(value))}`),
			}),
			createFilterOnlyColumn<ActivityLogListing, ActivityLogFiltersExtended>({
				group: t('activity.filterGroups.details'),
				label: t('activity.fields.event'),
				field: 'event',
				variant: 'select',
				hidden: !tableState.currentFilterData.entity,
				getFilterDisplayValue: value =>
					t(
						`activity.types.event.${camelCase(tableState.currentFilterData.entity)}.${camelCase(String(value))}`,
					),
			}),
		],
	});

	return (
		<TableLayout
			title={t('activity.title')}
			table={table}
			toolbarButtons={[
				<ToolbarButton
					key='download'
					tooltip={t('activity.toolbar.export')}
					icon={DownloadIcon}
					loading={excelTemplate.downloading}
					onClick={() => void excelTemplate.download()}
				/>,
			]}
		>
			<DataTable table={table} variant='apple-table' loading={activityLogQuery.isFetching} />
		</TableLayout>
	);
}

/**
 * Renders activity description if updates === 1.
 * Otherwise shows an ExpandingText that, when expanded,
 * fetches and displays the top 5 entries. If there are more than 5,
 * a link is shown to view all entries in a Drawer.
 */
export function ActivityDetails({ activityLog }: { activityLog: ActivityLogListing }) {
	const { correlationId, entityType, updates, description } = activityLog;
	const { t } = useTranslation('user');
	const [drawerOpened, drawerActions] = useDisclosure(false);

	const entriesQuery = useQuery({
		...activityLogQueries.entries(correlationId, entityType, 9999),
		enabled: drawerOpened, // Only fetch all if the drawer is open
	});

	// If there's only one update, just show the description directly
	if (updates <= 1) {
		return (
			<Text size='sm' c='gray'>
				{description}
			</Text>
		);
	}

	return (
		<>
			<DisclosureText
				size='sm'
				label={t('activity.columns.details.updates', { count: updates })}
				onClick={drawerActions.open}
			/>

			<Drawer
				opened={drawerOpened}
				onClose={() => drawerActions.close()}
				title={t('activity.columns.details.title')}
				padding='md'
				size='xl'
				position='right'
				offset={24}
				radius='lg'
				overlayProps={{ blur: 0 }}
			>
				{entriesQuery.isLoading && <Loader size='sm' mt='xs' />}
				{!entriesQuery.isLoading && entriesQuery.isError && (
					<Text size='sm' c='red'>
						{t('common:error.generic')}
					</Text>
				)}
				<Stack gap='md'>
					<SimpleGrid cols={2} spacing='md'>
						<Card variant='with-heading' flex={1}>
							<Card.Section>
								<Title order={4}>{t('activity.fields.entity')}</Title>
							</Card.Section>

							<Card.Section>
								<Text>{activityLog.entityType}</Text>
							</Card.Section>
						</Card>

						<Card variant='with-heading' flex={1}>
							<Card.Section>
								<Title order={4}>{t('activity.fields.event')}</Title>
							</Card.Section>

							<Card.Section>
								<Text>{activityLog.eventType}</Text>
							</Card.Section>
						</Card>

						<Card variant='with-heading' flex={1}>
							<Card.Section>
								<Title order={4}>{t('activity.fields.user')}</Title>
							</Card.Section>

							<Card.Section>
								<Text>
									{activityLog.updatedByName ?? t('activity.columns.user.system')}
								</Text>
							</Card.Section>
						</Card>

						<Card variant='with-heading' flex={1}>
							<Card.Section>
								<Title order={4}>{t('activity.fields.created')}</Title>
							</Card.Section>

							<Card.Section>
								<FormattedDate showTime humanize value={activityLog.dateCreated} />
							</Card.Section>
						</Card>
					</SimpleGrid>
					{!entriesQuery.isLoading && !entriesQuery.isError && (
						<List title={t('activity.columns.details.changes')}>
							{entriesQuery.data.map((entry, idx) => (
								<ListItem key={idx} label={entry.description} allowWrap />
							))}
						</List>
					)}
				</Stack>
			</Drawer>
		</>
	);
}
