import { useCallback } from 'react';
import { Text } from '@mantine/core';
import { modals } from '@mantine/modals';
import { useSuspenseQuery } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';

import { requireAuth } from '@apple/features/auth';
import {
	MarketForm,
	marketIdSchema,
	marketQueries,
	useMarketManagement,
} from '@apple/features/market';
import { useTranslation } from '@apple/lib/i18next';
import { useRouterHistory } from '@apple/lib/tanstack-router/hooks/use-router-history';
import { DetailsLayout } from '@apple/ui/layouts';
import { countryCodeSchema } from '@apple/utils/globalization';
import type { MarketId } from '@apple/features/market';

export const Route = createFileRoute('/_authed/_admin/manage/markets_/$marketId')({
	parseParams: ({ marketId }) => ({
		marketId: marketIdSchema.parse(Number(marketId)),
	}),
	beforeLoad: ({ context, location, params: { marketId }, cause }) => {
		requireAuth(
			{ context, location, cause },
			{
				requirePermission: 'Manzanita.Security.Features.CustomerGroupManagement.Write',
			},
		);

		return {
			getMarketQueryOptions: marketQueries.getMarket(marketId),
		};
	},
	loader: ({ context: { queryClient, getMarketQueryOptions } }) => {
		void queryClient.prefetchQuery(getMarketQueryOptions);
	},
	wrapInSuspense: true,
	component: MarketRoute,
});

function MarketRoute() {
	const navigate = Route.useNavigate();
	const history = useRouterHistory();
	const { t } = useTranslation('market');
	const { getMarketQueryOptions } = Route.useRouteContext();
	const marketQuery = useSuspenseQuery(getMarketQueryOptions);

	const { mutate, mutateAsync } = useMarketManagement();

	const handleDeleteMarket = useCallback(
		(marketId: MarketId) => {
			modals.openConfirmModal({
				title: t('delete.title'),
				labels: {
					confirm: t('common:buttons.confirm'),
					cancel: t('common:buttons.cancel'),
				},
				onConfirm: () =>
					mutate(
						{ action: 'delete', marketId },
						{
							onSuccess: () => {
								history.canGoBack()
									? history.back()
									: void navigate({ to: '/manage/markets' });
							},
						},
					),
				children: <Text>{t('delete.message')}</Text>,
			});
		},
		[t, mutate, history, navigate],
	);

	return (
		<DetailsLayout title={t('title.edit')}>
			<MarketForm
				isEdit={true}
				onSaveMarket={async market => {
					await mutateAsync(
						{
							action: 'edit',
							market: {
								...market,
								countryCode: countryCodeSchema.parse(market.countryCode),
							},
						},
						{
							onSuccess: () =>
								history.canGoBack()
									? history.back()
									: void navigate({ to: '/manage/markets' }),
						},
					);
				}}
				market={marketQuery.data}
				onDeleteMarket={handleDeleteMarket}
			/>
		</DetailsLayout>
	);
}
