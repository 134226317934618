import { z } from 'zod';

import {
	emptyOrNullableLocalDateTimeSchema,
	localDateTimeSchema,
} from '@apple/utils/globalization/models';

/**AppleRetail.Web.Models.FiscalCalendarYear */
export interface FiscalCalendarYear {
	fiscalYear: number;
	startDate: Date | null | string;
	endDate: Date | null | string;
}

/** AppleRetail.Web.Models.FiscalCalendar */
export interface FiscalCalendar {
	fiscalYear: number;
	endDateOfPreviousYear?: string | Date | null;
	startDateOfNextYear?: string | Date | null;
	fiscalPeriods: FiscalCalendarPeriod[];
	startPeriodElapsed: boolean;
}

/** Manzanita.DataModels.FiscalPeriod */
export interface FiscalPeriod {
	fiscalYear: number;
	periodNumber: number;
	periodName: string | null;
	startDate: '' | string | Date | null;
	endDate: '' | Date | string | null;
	quarter: number;
}
/** AppleRetail.Web.Models.FiscalCalendarPeriod */
export interface FiscalCalendarPeriod extends FiscalPeriod {
	previousDate?: string | Date | null;
	quarterName: string;
	isEditable: boolean;
}

/** Manzanita.DataModels.FiscalPeriod */
export const fiscalPeriodSchema = z.object({
	fiscalYear: z.number(),
	periodNumber: z.number(),
	periodName: z.string().nullable(),
	startDate: emptyOrNullableLocalDateTimeSchema,
	endDate: emptyOrNullableLocalDateTimeSchema,
	quarter: z.number(),
}) satisfies z.ZodType<FiscalPeriod>;

/**AppleRetail.Web.Models.FiscalCalendarYear */
export const fiscalCalendarYearSchema = z.object({
	fiscalYear: z.number(),
	startDate: localDateTimeSchema,
	endDate: localDateTimeSchema,
}) satisfies z.ZodType<FiscalCalendarYear>;

/** AppleRetail.Web.Models.FiscalCalendarPeriod */
export const fiscalCalendarPeriodSchema = z.object({
	fiscalYear: z.number(),
	periodNumber: z.number(),
	periodName: z.string().nullable(),
	startDate: emptyOrNullableLocalDateTimeSchema,
	endDate: emptyOrNullableLocalDateTimeSchema,
	previousDate: emptyOrNullableLocalDateTimeSchema.optional(),
	quarter: z.number(),
	quarterName: z.string(),
	isEditable: z.boolean(),
}) satisfies z.ZodType<FiscalCalendarPeriod>;

/** AppleRetail.Web.Models.FiscalCalendar */
export const fiscalCalendarSchema = z.object({
	fiscalYear: z.number(),
	endDateOfPreviousYear: emptyOrNullableLocalDateTimeSchema,
	startDateOfNextYear: emptyOrNullableLocalDateTimeSchema,
	fiscalPeriods: z.array(fiscalCalendarPeriodSchema),
	startPeriodElapsed: z.boolean(),
}) satisfies z.ZodType<FiscalCalendar>;
