import { useSuspenseQuery } from '@tanstack/react-query';
import axios from 'axios';

import { storeStateDetailsSchema } from '@apple/features/store/models/budget';

export function useRetailStore(storeCode: string) {
	const storeQuery = useSuspenseQuery({
		queryKey: ['store', storeCode],
		queryFn: async () => {
			const response = await axios.get<unknown>(`/api/stores/${storeCode}/state`);
			return storeStateDetailsSchema.parse(response.data);
		},
		staleTime: Infinity,
	});

	return {
		storeQuery,
	};
}
