import { useQuery } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';
import {
	getCoreRowModel,
	getFacetedMinMaxValues,
	getFacetedRowModel,
	getFacetedUniqueValues,
	getFilteredRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	useReactTable,
} from '@tanstack/react-table';

import { AddIcon } from '@apple/assets/icons';
import { requireAuth } from '@apple/features/auth';
import { marketQueries } from '@apple/features/market';
import { useTranslation } from '@apple/lib/i18next';
import { DataTable } from '@apple/ui/data-table';
import { ToolbarButton } from '@apple/ui/data-table/controls/Toolbar';
import { TableLayout } from '@apple/ui/layouts';
import { Link } from '@apple/ui/link';
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from '@apple/utils/pagination';

export const Route = createFileRoute('/_authed/_admin/manage/markets')({
	beforeLoad: args => {
		requireAuth(args, {
			requirePermission: 'Manzanita.Security.Features.CustomerGroupManagement.Read',
		});

		return {
			getMarketsQueryOptions: marketQueries.getMarkets(),
		};
	},
	loader: ({ context: { queryClient, getMarketsQueryOptions } }) => {
		void queryClient.prefetchQuery(getMarketsQueryOptions);
	},
	component: MarketManagementRoute,
});

function MarketManagementRoute() {
	const { t } = useTranslation('market');
	const navigate = Route.useNavigate();
	const { getMarketsQueryOptions } = Route.useRouteContext();
	const marketsQuery = useQuery(getMarketsQueryOptions);

	const table = useReactTable({
		data: marketsQuery.data,
		getCoreRowModel: getCoreRowModel(),
		getFacetedMinMaxValues: getFacetedMinMaxValues(),
		getFacetedUniqueValues: getFacetedUniqueValues(),
		getFacetedRowModel: getFacetedRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getSortedRowModel: getSortedRowModel(),
		enableFilters: true,
		enableColumnFilters: true,
		enableSorting: true,
		columnResizeMode: 'onChange',
		initialState: {
			pagination: {
				pageSize: DEFAULT_PAGE_SIZE,
				pageIndex: DEFAULT_PAGE_INDEX,
			},
			sorting: [
				{
					id: 'name',
					desc: true,
				},
			],
		},
		columns: [
			{
				accessorKey: 'name',
				header: t('labels.name'),
				filter: {
					group: t('filterGroups.details'),
				},
				size: 250,
				cell: ({ row }) => (
					<Link
						to='/manage/markets/$marketId'
						params={{ marketId: row.original.id }}
						title={row.original.name}
						children={row.original.name}
					/>
				),
			},
			{
				accessorKey: 'code',
				header: t('labels.marketCode'),
				filter: {
					group: t('filterGroups.details'),
				},
			},
		],
	});

	return (
		<TableLayout
			title={t('title.list')}
			table={table}
			toolbarButtons={[
				<ToolbarButton
					key='add'
					tooltip={t('buttons.add')}
					icon={AddIcon}
					onClick={() =>
						void navigate({
							to: '/manage/markets/add',
						})
					}
				/>,
			]}
		>
			<DataTable table={table} loading={marketsQuery.isFetching} />
		</TableLayout>
	);
}
