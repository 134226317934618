import { useMemo, useState } from 'react';
import { useQuery, useSuspenseQuery } from '@tanstack/react-query';

import { calendarQueryOptions } from '@apple/features/calendar/queries';
import { budgetQueryOptions } from '@apple/features/store/queries/budget';
import type { FiscalCalendarPeriod } from '@apple/features/calendar/models';
import type { BudgetItem } from '@apple/features/store/components/BudgetSummary';
import type { BudgetSummaryItem } from '@apple/features/store/models/budget';

function getBudgetData(
	budgetSummary: BudgetSummaryItem[],
	fiscalPeriods: FiscalCalendarPeriod[],
): BudgetItem[] {
	return budgetSummary.map(value => ({
		...value,
		fiscalPeriod: fiscalPeriods.find(y => y.periodNumber === value.periodNumber),
	}));
}

export function useBudget(storeCode: string) {
	const currentPeriodQuery = useSuspenseQuery(calendarQueryOptions.current);
	const currentFiscalPeriod = currentPeriodQuery.data;
	const [selectedFiscalYear, setSelectedFiscalYear] = useState<string | null>(
		currentFiscalPeriod.fiscalYear.toString(),
	);
	const [selectedPeriodNumber, setSelectedPeriodNumber] = useState<string | null>(
		currentFiscalPeriod.periodNumber.toString(),
	);

	const fiscalYear = useMemo(() => Number(selectedFiscalYear), [selectedFiscalYear]);

	const budgetSummaryQuery = useQuery(
		budgetQueryOptions.budgetSummary(storeCode ?? '', fiscalYear),
	);
	const fiscalYearQuery = useQuery(calendarQueryOptions.fiscalYear(fiscalYear));

	const budgetData = useMemo(() => {
		return !budgetSummaryQuery.isFetching &&
			!fiscalYearQuery.isFetching &&
			budgetSummaryQuery.data &&
			fiscalYearQuery.data
			? getBudgetData(budgetSummaryQuery.data ?? [], fiscalYearQuery.data.fiscalPeriods)
			: [];
	}, [
		budgetSummaryQuery.data,
		budgetSummaryQuery.isFetching,
		fiscalYearQuery.data,
		fiscalYearQuery.isFetching,
	]);

	const hasData = budgetData.length > 0;
	return {
		currentFiscalPeriod,
		selectedFiscalYear,
		setSelectedFiscalYear,
		selectedPeriodNumber,
		setSelectedPeriodNumber,
		budgetData,
		hasData,
	};
}
