import axios from 'axios';

import { StoreEventStatusId } from '@apple/features/store-events/models/shared';
import type { StoreId } from '@apple/features/store/models';
import { ServerValidationError } from '@apple/utils/api';
import type { Guid } from '@apple/utils/primitive';
import {
	type StoreEventModel,
	type StoreEventUpdateModel,
	storeEventModelSchema,
	storeEventUpdateEnumModelSchema,
	storesByEventStatusResponseSchema,
} from '../models/management';

/** @see AppleRetail.Web.Features.StoreEvents.StoreEventManagementController.GetById */
export async function getStoreEvent(
	storeEventId: Guid,
	signal?: AbortSignal,
): Promise<StoreEventModel> {
	const response = await axios.get<unknown>(`/api/management/store-events/${storeEventId}`, {
		signal,
	});
	return storeEventModelSchema.parse(response.data);
}

/** @see AppleRetail.Web.Features.StoreEvents.StoreEventManagementController.Post */
export async function createStoreEvent(
	storeId: StoreId,
	details: StoreEventUpdateModel,
	signal?: AbortSignal,
): Promise<StoreEventModel> {
	const parsedDetails = storeEventUpdateEnumModelSchema.parse(details);
	const response = await axios.post<unknown>(
		`/api/management/store-events/v2/${storeId}`,
		parsedDetails,
		{
			signal,
		},
	);

	if (response.status === 409) {
		throw new ServerValidationError<StoreEventUpdateModel>(
			new Error('Duplicate Store Events'),
			{
				title: 'Duplicate store events',
				type: 'tag:apple.arvato.com:validation',
				detail: 'A store event already exists for the selected store',
				status: response.status,
				instance: response.config.url,
				formErrors: ['A store event already exists for the selected store'],
			},
		);
	}

	return storeEventModelSchema.parse(response.data);
}

/** @see AppleRetail.Web.Features.StoreEvents.StoreEventManagementController.Save */
export async function saveStoreEvent(storeEventId: string, signal?: AbortSignal): Promise<void> {
	await axios.post(`/api/store-events/${storeEventId}/save`, {
		signal,
	});
}

/** @see AppleRetail.Web.Features.StoreEvents.StoreEventManagementController.Put */
export async function updateStoreEvent(
	storeEventId: Guid,
	details: StoreEventUpdateModel,
	signal?: AbortSignal,
): Promise<void> {
	const parsedDetails = storeEventUpdateEnumModelSchema.parse(details);
	await axios.put(`/api/management/store-events/${storeEventId}`, parsedDetails, {
		signal,
	});
}

/** @see AppleRetail.Web.Features.StoreEvents.StoreEventManagementController.Delete */
export async function cancelStoreEvent(storeEventId: string, signal?: AbortSignal): Promise<void> {
	await axios.delete(`/api/management/store-events/${storeEventId}`, {
		signal,
	});
}

/** @see AppleRetail.Web.Areas.StoreEvents.ApiControllers.StoreEventCompletionApiController.Complete */
export async function completeStoreEvent(storeEventId: Guid, signal?: AbortSignal): Promise<void> {
	await axios.post(`/api/store-events/${storeEventId}/complete`, {
		signal,
	});
}

/** @see AppleRetail.Web.Areas.StoreEvents.ApiControllers.StoreEventCompletionApiController.Submit */
export async function submitStoreEvent(storeEventId: Guid, signal?: AbortSignal): Promise<void> {
	await axios.post<void>(`/api/store-events/${storeEventId}/submit`, {
		signal,
	});
}

/** @see AppleRetail.Web.Areas.StoreEvents.ApiControllers.StoreEventCompletionApiController.Archive */
export async function archiveStoreEvent(storeEventId: Guid, signal?: AbortSignal): Promise<void> {
	await axios.post(`/api/store-events/${storeEventId}/archive`, {
		signal,
	});
}

/** @see AppleRetail.Web.Areas.StoreEvents.ApiControllers.StoreEventCompletionApiController.Archive */
export async function getStoreIdsForExistingEvents(signal?: AbortSignal): Promise<StoreId[]> {
	const { data } = await axios.get<unknown>('/api/store-events/stores/by-status', {
		params: {
			StoreEventStates: [
				StoreEventStatusId.NewEvent,
				StoreEventStatusId.Saved,
				StoreEventStatusId.ReadyForSubmission,
				StoreEventStatusId.SubmissionPending,
				StoreEventStatusId.Submitted,
				StoreEventStatusId.Open,
				StoreEventStatusId.Complete,
				StoreEventStatusId.EarlyManagementKitPicked,
				StoreEventStatusId.EarlyManagementKitShipped,
				StoreEventStatusId.EarlyManagementKitDelivered,
				StoreEventStatusId.FullKitPicked,
				StoreEventStatusId.FullKitShipped,
			],
		},
		signal,
	});

	return storesByEventStatusResponseSchema.parse(data).storeIds;
}
