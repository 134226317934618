import { notifications } from '@mantine/notifications';
import { useMutation } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';
import { getLogger } from 'loglevel';

import { ErrorIcon, Success } from '@apple/assets/icons';
import { requireAuth } from '@apple/features/auth';
import { addOrder } from '@apple/features/order-retail/api/recurring';
import { RecurringOrderForm } from '@apple/features/order-retail/components/recurring/RecurringOrderForm';
import { recurringOrderQueryKeys } from '@apple/features/order-retail/queries/recurring';
import { useTranslation } from '@apple/lib/i18next';
import { DetailsLayout } from '@apple/ui/layouts';
import { ServerValidationError } from '@apple/utils/api/errors';
import type {
	RecurringOrder,
	RecurringOrderRequest,
} from '@apple/features/order-retail/models/recurring';

const log = getLogger('manage-recurring-order');

export const Route = createFileRoute('/_authed/orders/recurring_/add')({
	beforeLoad: ({ ...args }) => {
		requireAuth(args, {
			requirePermission: 'AppleRetail.Security.Features.RecurringOrdersManagement',
		});
	},
	wrapInSuspense: true,
	component: RecurringOrderRoute,
});

function RecurringOrderRoute() {
	const { t } = useTranslation('order-recurring');
	const { queryClient } = Route.useRouteContext();
	const navigate = Route.useNavigate();

	async function handleSuccessfulChange(message: string) {
		await queryClient.invalidateQueries({
			queryKey: recurringOrderQueryKeys.all,
		});

		notifications.show({
			message: message,
			icon: <Success />,
			color: 'green',
			autoClose: 5000,
		});
		await navigate({
			to: '/orders/recurring',
		});
	}
	function showErrorNotification(error: Error) {
		if (!(error instanceof ServerValidationError)) {
			log.error(error);
			notifications.show({
				message: t('common:error.generic'),
				icon: <ErrorIcon />,
				color: 'red',
				autoClose: 5000,
			});
		}
	}

	const saveRecurringOrderMutation = useMutation({
		mutationFn: async (request: RecurringOrderRequest) => {
			await addOrder({
				...request,
				poSpecialistEmailsJson: request.poSpecialistEmails,
			} as RecurringOrder);
		},
		onSuccess: async () => {
			await handleSuccessfulChange(t('addOrder.success'));
		},
		onError: showErrorNotification,
	});

	return (
		<DetailsLayout title={t('addOrder.title')}>
			<RecurringOrderForm
				isEdit={false}
				isAdmin={true}
				saveRecurringOrderMutation={saveRecurringOrderMutation}
			/>
		</DetailsLayout>
	);
}
