import { z } from 'zod';

/** @see AppleRetail.StoreEvents.DataModels.StoreEventStatus */
export enum StoreEventStatusId {
	NewEvent = 1,
	Saved = 2,
	ReadyForSubmission = 3,
	SubmissionPending = 4,
	Submitted = 5,
	Open = 6,
	Canceled = 7,
	Complete = 8,
	EarlyManagementKitPicked = 9,
	EarlyManagementKitShipped = 10,
	EarlyManagementKitDelivered = 11,
	FullKitPicked = 12,
	FullKitShipped = 13,
	FullKitDelivered = 14,
	Archived = 15,
}

/** @see AppleRetail.StoreEvents.DataModels.StoreEventStatus */
export type StoreEventStatus = z.infer<typeof storeEventStatusSchema>;

/** @see AppleRetail.StoreEvents.DataModels.StoreEventType */
export enum StoreEventTypeId {
	NewStore = 0,
	Upgrade = 1,
	Relocation = 2,
	Eagle = 3,
}

/** @see AppleRetail.StoreEvents.DataModels.StoreEventType */
export type StoreEventType = z.infer<typeof storeEventTypeSchema>;

/** @see AppleRetail.StoreEvents.DataModels.StoreEventStatus */
export const storeEventStatusIdSchema = z.nativeEnum(StoreEventStatusId);

/** @see AppleRetail.StoreEvents.DataModels.StoreEventStatus */
export const storeEventStatusSchema = z.enum([
	'NewEvent',
	'Saved',
	'ReadyForSubmission',
	'SubmissionPending',
	'Submitted',
	'Open',
	'Canceled',
	'Complete',
	'EarlyManagementKitPicked',
	'EarlyManagementKitShipped',
	'EarlyManagementKitDelivered',
	'FullKitPicked',
	'FullKitShipped',
	'FullKitDelivered',
	'Archived',
]);

/** @see AppleRetail.StoreEvents.DataModels.StoreEventType */
export const storeEventTypeIdSchema = z.nativeEnum(StoreEventTypeId);

/** @see AppleRetail.StoreEvents.DataModels.StoreEventType */
export const storeEventTypeSchema = z.enum(['NewStore', 'Upgrade', 'Relocation', 'Eagle']);

/** Convert from the string name value to the int enum value */
export const storeEventStatusToIdSchema = storeEventStatusSchema
	.transform(typeName => StoreEventStatusId[typeName])
	.pipe(storeEventStatusIdSchema);

/** Convert from the string name value to the int enum value */
export const storeEventTypeToIdSchema = storeEventTypeSchema
	.transform(typeName => StoreEventTypeId[typeName])
	.pipe(storeEventTypeIdSchema);
