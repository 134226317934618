import { Paper, Stack, Title } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import { useReactTable } from '@tanstack/react-table';
import {
	getCoreRowModel,
	getFacetedMinMaxValues,
	getFacetedRowModel,
	getFilteredRowModel,
	getPaginationRowModel,
	getSortedRowModel,
} from '@tanstack/table-core';

import { useTranslation } from '@apple/lib/i18next';
import { DataTable } from '@apple/ui/data-table';

import { budgetQueryOptions } from '../queries/budget';

export interface PurchaseSummaryTableProps {
	singleStoreCustomerCode: string;
	fiscalYear: number;
	periodNumber: number;
	categoryId: number;
}

export function PurchaseSummaryTable({
	singleStoreCustomerCode,
	fiscalYear,
	periodNumber,
	categoryId,
}: PurchaseSummaryTableProps) {
	const { t } = useTranslation('store');

	const purchaseSummaryQuery = useQuery(
		budgetQueryOptions.productPurchaseDetail(
			singleStoreCustomerCode,
			fiscalYear,
			periodNumber,
			categoryId,
		),
	);

	const table = useReactTable({
		data: purchaseSummaryQuery.data ?? [],
		getCoreRowModel: getCoreRowModel(),
		getFacetedMinMaxValues: getFacetedMinMaxValues(),
		getFacetedRowModel: getFacetedRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getSortedRowModel: getSortedRowModel(),
		enableSorting: true,
		columnResizeMode: 'onChange',
		enableFilters: false,
		enableColumnFilters: false,
		manualFiltering: false,
		manualSorting: false,

		columns: [
			{
				accessorKey: 'partNumber',
				header: t('budgets.purchaseSummary.headers.partNumber'),
			},
			{
				accessorKey: 'description',
				header: t('budgets.purchaseSummary.headers.description'),
			},
			{
				accessorKey: 'quantity',
				header: t('budgets.purchaseSummary.headers.quantity'),
			},
			{
				accessorKey: 'formattedTotalCost',
				header: t('budgets.purchaseSummary.headers.cost'),
			},
		],
	});

	function renderEmpty() {
		return (
			<Stack align='center'>
				<Title order={3}>{t('common:pagination:empty')}</Title>
			</Stack>
		);
	}

	return (
		<Paper withBorder>
			<DataTable
				hidePageSize
				table={table}
				variant='apple-table'
				renderEmpty={renderEmpty}
				loading={purchaseSummaryQuery.isFetching || purchaseSummaryQuery.isLoading}
			/>
		</Paper>
	);
}
