import { useCallback } from 'react';
import { Anchor, Text } from '@mantine/core';
import { modals } from '@mantine/modals';
import { notifications } from '@mantine/notifications';
import { useMutation, useQueries } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';
import {
	getCoreRowModel,
	getFacetedMinMaxValues,
	getFacetedRowModel,
	getFacetedUniqueValues,
	getFilteredRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	useReactTable,
} from '@tanstack/react-table';
import type { SortingState } from '@tanstack/react-table';

import { AddIcon, ErrorIcon, Success } from '@apple/assets/icons';
import { requireAuth } from '@apple/features/auth';
import { removeFulfillmentRule } from '@apple/features/fulfillment/api';
import { EntityType, RuleType } from '@apple/features/fulfillment/models';
import { fulfillmentQueryKeys, fulfillmentQueryOptions } from '@apple/features/fulfillment/queries';
import { plantQueryOptions } from '@apple/features/plants/queries/queries';
import { useTranslation } from '@apple/lib/i18next';
import { queryClient } from '@apple/retail/router';
import { DataTable, useTableState } from '@apple/ui/data-table';
import { ToolbarButton } from '@apple/ui/data-table/controls/Toolbar';
import { TableLayout } from '@apple/ui/layouts';
import type { FulfillmentRule, FulfillmentRuleFilters } from '@apple/features/fulfillment/models';

const defaultFilters: FulfillmentRuleFilters = {};
const defaultSorting: SortingState = [{ id: 'configuration', desc: false }];

export const Route = createFileRoute('/_authed/fulfillment')({
	beforeLoad: args => {
		requireAuth(args, {
			requirePermissions: [
				'Manzanita.Security.Features.FulfillmentRuleManagement.Read',
				'Manzanita.Security.Features.FulfillmentRuleManagement.Write',
			],
		});
	},
	component: FulfillmentRulesPageRoute,
});

function FulfillmentRulesPageRoute() {
	const { t } = useTranslation('fulfillment');
	const navigate = Route.useNavigate();
	const search = Route.useSearch();
	const [fulfillmentQuery, plantsQuery] = useQueries({
		queries: [fulfillmentQueryOptions.fulfillmentResults, plantQueryOptions.plants],
	});

	const tableState = useTableState<FulfillmentRule, FulfillmentRuleFilters>({
		search,
		navigate,
		defaultFilters: defaultFilters,
		defaultSorting: defaultSorting,
		fieldMap: [],
	});

	const removeFulfillmentRuleMutation = useMutation({
		mutationFn: (fulfillmentRule: FulfillmentRule) => removeFulfillmentRule(fulfillmentRule),
		onSuccess: async () => {
			await handleSuccessfulChange(t('removeSuccessMessage'));
		},
		onError: () =>
			notifications.show({
				message: t('common:error.generic'),
				icon: <ErrorIcon />,
				color: 'red',
				autoClose: 5000,
			}),
	});

	const handleSuccessfulChange = useCallback(async (message: string) => {
		await queryClient.invalidateQueries({
			queryKey: fulfillmentQueryKeys.all,
		});

		notifications.show({
			message: message,
			icon: <Success />,
			color: 'green',
			autoClose: 5000,
		});
	}, []);

	const handleRemoveFulfillmentRule = useCallback(
		(rule: FulfillmentRule) => {
			modals.openConfirmModal({
				title: t('removeTitle'),
				labels: {
					confirm: t('common:buttons.confirm'),
					cancel: t('common:buttons.cancel'),
				},
				onConfirm: () =>
					removeFulfillmentRuleMutation.mutate({
						...rule,
						comment: rule.entityType === EntityType.Product ? 'N/A' : null,
					}),
				children: <Text>{t('removeMessage')}</Text>,
			});
		},
		[removeFulfillmentRuleMutation, t],
	);

	const table = useReactTable<FulfillmentRule>({
		data: fulfillmentQuery.data ?? [],
		...tableState,
		getCoreRowModel: getCoreRowModel(),
		getFacetedMinMaxValues: getFacetedMinMaxValues(),
		getFacetedUniqueValues: (table, columnId) => {
			switch (columnId) {
				case 'ruleType':
					return () => new Map([RuleType.Override, RuleType.Fallback].map(x => [x, 1]));
				default:
					return getFacetedUniqueValues<FulfillmentRule>()(table, columnId);
			}
		},
		getFacetedRowModel: getFacetedRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getSortedRowModel: getSortedRowModel(),
		enableFilters: true,
		enableColumnFilters: true,
		enableSorting: true,
		columnResizeMode: 'onChange',
		columns: [
			{
				accessorKey: 'ruleType',
				header: t('headers.ruleType'),
				filter: {
					group: t('filters.groups.title'),
					variant: 'select',
					getFilterDisplayValue: value => t(`ruleType.${value as string}`),
				},
				filterFn: (row, _columnId, filterValue) => {
					if (!filterValue) return true;

					if (Number(filterValue as string) === row.original.ruleType?.valueOf())
						return true;

					return false;
				},
				cell: ({ row }) => <Text>{row.original.ruleTypeName}</Text>,
			},
			{
				accessorKey: 'destinationCountry',
				enableColumnFilter: false,
				header: t('headers.destinationCountry'),
			},
			{
				accessorKey: 'defaultWarehouseCode',
				enableColumnFilter: false,
				header: t('headers.defaultWarehouseCode'),
				cell: ({ row }) => (
					<Text>{`${plantsQuery.data?.find(x => x.warehouseCode === row.original.defaultWarehouseCode)?.name} (${row.original.defaultWarehouseCode})`}</Text>
				),
			},
			{
				accessorKey: 'entityType',
				enableColumnFilter: false,
				header: t('headers.configurationType'),
				cell: ({ row }) => <Text>{t(`entityType.${row.original.entityType}`)}</Text>,
			},
			{
				accessorKey: 'configuration',
				header: t('headers.configuration'),
				filter: {
					group: t('filters.groups.title'),
				},
			},
			{
				accessorKey: 'warehouseCode',
				enableColumnFilter: false,
				header: t('headers.warehouseCode'),
				cell: ({ row }) => (
					<Text>{`${plantsQuery.data?.find(x => x.warehouseCode === row.original.warehouseCode)?.name} (${row.original.warehouseCode})`}</Text>
				),
			},
			{
				accessorKey: 'remove',
				enableColumnFilter: false,
				header: t('headers.remove'),
				cell: ({ row }) => (
					<Anchor onClick={() => handleRemoveFulfillmentRule(row.original)}>
						{t('buttons.remove')}
					</Anchor>
				),
			},
		],
	});

	return (
		<TableLayout
			table={table}
			title={t('title')}
			toolbarButtons={
				fulfillmentQuery.isFetching || !fulfillmentQuery.data
					? []
					: [
							<ToolbarButton
								key='add'
								tooltip={t('buttons.addTooltip')}
								icon={AddIcon}
								onClick={() => {
									void navigate({
										to: '/fulfillment/add',
									});
								}}
							/>,
						]
			}
		>
			<DataTable table={table} loading={fulfillmentQuery.isFetching} />
		</TableLayout>
	);
}
