import { notifications } from '@mantine/notifications';
import { useMutation, useSuspenseQueries } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';
import { getLogger } from 'loglevel';

import { ErrorIcon, Success } from '@apple/assets/icons';
import { requireAuth } from '@apple/features/auth';
import { saveProduct } from '@apple/features/product-retail/api/management';
import { ProductForm } from '@apple/features/product-retail/components/ProductManagement/ProductForm';
import { PRODUCTS_ODATA_QUERY_KEY } from '@apple/features/product-retail/queries/odata';
import { productQueryKeys, productQueryOptions } from '@apple/features/product/queries/management';
import { propagateChanges } from '@apple/features/store-events/api/change-propagation';
import { useTranslation } from '@apple/lib/i18next';
import { DetailsLayout } from '@apple/ui/layouts';
import { ServerValidationError } from '@apple/utils/api';
import type { ProductFormData } from '@apple/features/product-retail/models/management';

const log = getLogger('manage-product');

export const Route = createFileRoute('/_authed/_admin/manage/products_/add')({
	beforeLoad: args => {
		requireAuth(args, {
			requirePermission: 'Manzanita.Security.Features.ProductManagement.Write',
		});
		return {
			keywordsQueryOptions: productQueryOptions.keywords,
			productLinesQueryOptions: productQueryOptions.categories,
		};
	},
	loader: ({ context: { queryClient, keywordsQueryOptions, productLinesQueryOptions } }) => {
		void queryClient.prefetchQuery(keywordsQueryOptions);
		void queryClient.prefetchQuery(productLinesQueryOptions);
	},
	wrapInSuspense: true,
	component: AddProductRoute,
});

function AddProductRoute() {
	const { t } = useTranslation('product');
	const { productLinesQueryOptions, keywordsQueryOptions, queryClient } = Route.useRouteContext();

	const [productCategoriesQuery, keywordsQuery] = useSuspenseQueries({
		queries: [productLinesQueryOptions, keywordsQueryOptions],
	});

	const navigate = Route.useNavigate();

	function showErrorNotification(error: Error) {
		if (!(error instanceof ServerValidationError)) {
			log.error(error);
			notifications.show({
				message: t('common:error.generic'),
				icon: <ErrorIcon />,
				color: 'red',
				autoClose: 5000,
			});
		}
	}

	const addProductMutation = useMutation({
		mutationFn: async (product: ProductFormData) => await saveProduct(product, 'N/A'),
		onSuccess: async (_, product) => {
			if (product.eventRulePropagations && product.eventRulePropagations.length > 0) {
				await propagateChanges(product.eventRulePropagations, 'N/A');
			}
			void queryClient.invalidateQueries({
				queryKey: productQueryKeys.unassignedSkus,
				exact: true,
			});
			void queryClient.invalidateQueries({
				queryKey: [PRODUCTS_ODATA_QUERY_KEY],
			});

			notifications.show({
				message: t('notifications.created'),
				icon: <Success />,
				color: 'green',
				autoClose: 5000,
			});
			await navigate({
				to: '/manage/products',
			});
		},
		onError: showErrorNotification,
	});

	return (
		<DetailsLayout title={t('titles.addProduct')}>
			<ProductForm
				productCategoriesQuery={productCategoriesQuery}
				keywordsQuery={keywordsQuery}
				saveProductMutation={addProductMutation}
			/>
		</DetailsLayout>
	);
}
