import axios from 'axios';

import {
	budgetDetailsItemSchema,
	budgetSummaryItemSchema,
	productPurchaseDetailItemSchema,
} from '../models/budget';
import type {
	BudgetDetailsItem,
	BudgetSummaryItem,
	ProductPurchaseDetailItem,
} from '../models/budget';

/** AppleRetail.Web.Features.Budgets.BudgetReportDetailsController*/
export async function getBudgetSummary(
	singleStoreCustomerCode: string,
	fiscalYear: number,
): Promise<BudgetSummaryItem[]> {
	const response = await axios.get<unknown>(
		`/api/budget-report/${singleStoreCustomerCode}/${fiscalYear}`,
	);
	return budgetSummaryItemSchema.array().parse(response.data);
}

/** AppleRetail.Web.Features.Budgets.BudgetReportDetailsController*/
export async function getBudgetDetailsItem(
	singleStoreCustomerCode: string,
	fiscalYear: number,
	periodNumber: number,
): Promise<BudgetDetailsItem[]> {
	const response = await axios.get<unknown>(
		`/api/budget-report/${singleStoreCustomerCode}/${fiscalYear}/${periodNumber}`,
	);
	return budgetDetailsItemSchema.array().parse(response.data);
}

/** AppleRetail.Web.Features.Budgets.BudgetReportDetailsController*/
export async function getProductPurchaseDetailItems(
	singleStoreCustomerCode: string,
	fiscalYear: number,
	periodNumber: number,
	categoryId: number,
): Promise<ProductPurchaseDetailItem[]> {
	const response = await axios.get<unknown>(
		`/api/budget-report/${singleStoreCustomerCode}/${fiscalYear}/${periodNumber}/${categoryId}`,
	);
	return productPurchaseDetailItemSchema.array().parse(response.data);
}

export function getBudgetSummaryExportUrl(singleStoreCustomerCode: string, fiscalYear: number) {
	return `api/budget-report/${singleStoreCustomerCode}/${fiscalYear}/export`;
}

export function getBudgetDetailExportUrl(
	singleStoreCustomerCode: string,
	fiscalYear: number,
	periodNumber: number,
) {
	return `api/budget-report/${singleStoreCustomerCode}/${fiscalYear}/export/${periodNumber}`;
}
