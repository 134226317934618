import {
	storeEventAuditLogEntryModelSchema,
	type StoreEventAuditLogEntryModel,
} from '../models/listing';
import { paginatedListDtoSchema } from '@apple/utils/pagination';
import type { Guid } from '@apple/utils/primitive';
import axios from 'axios';

/**
 * @see AppleRetail.Web.Areas.StoreEvents.ApiControllers.StoreEventAuditLogApiController
 * */

/** Gets paginated audit logs for the specified store event. */
export async function getStoreEventAudits(
	storeEventId: Guid,
	signal: AbortSignal,
): Promise<StoreEventAuditLogEntryModel[]> {
	const response = await axios.get<unknown>(`/api/store-events/${storeEventId}/audit`, {
		signal,
	});

	return paginatedListDtoSchema(storeEventAuditLogEntryModelSchema).parse(response.data).items;
}
