import { notifications } from '@mantine/notifications';
import { useMutation, useQuery } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';

import { ErrorIcon, Success } from '@apple/assets/icons';
import { requireAuth } from '@apple/features/auth';
import { addFiscalCalendar } from '@apple/features/calendar/api';
import { FiscalCalendarForm } from '@apple/features/calendar/components/FiscalCalendarForm';
import { calendarKeys, calendarQueryOptions } from '@apple/features/calendar/queries';
import { useTranslation } from '@apple/lib/i18next';
import { DetailsLayout } from '@apple/ui/layouts';
import { ServerValidationError } from '@apple/utils/api/errors';
import type { FiscalCalendar } from '@apple/features/calendar/models';

export const Route = createFileRoute('/_authed/_admin/manage/calendars_/add')({
	beforeLoad: args => {
		requireAuth(args, {
			requirePermission: 'AppleRetail.Security.Features.CalendarManagement',
		});
		return {
			newFiscalYearQueryOptions: calendarQueryOptions.newFiscalYear,
		};
	},
	loader: ({ context: { queryClient, newFiscalYearQueryOptions } }) =>
		void queryClient.prefetchQuery(newFiscalYearQueryOptions),
	component: CalendarManagementRoute,
});

function CalendarManagementRoute() {
	const { queryClient } = Route.useRouteContext();
	const { t } = useTranslation('calendar');
	const { newFiscalYearQueryOptions } = Route.useRouteContext();
	const newFiscalYearQuery = useQuery(newFiscalYearQueryOptions);
	const navigate = Route.useNavigate();

	const addCalendar = useMutation({
		mutationFn: async (fiscalCalendar: FiscalCalendar) =>
			addFiscalCalendar(fiscalCalendar.fiscalPeriods),
		onSuccess: async () => {
			notifications.show({
				color: 'green',
				message: t('save.success'),
				icon: <Success />,
			});
			await queryClient.invalidateQueries({
				queryKey: calendarKeys.all,
			});
			await navigate({
				to: '/manage/calendars',
			});
		},
		onError: (error: Error) => {
			if (!(error instanceof ServerValidationError)) {
				notifications.show({
					message: t('common:error.generic'),
					icon: <ErrorIcon />,
					color: 'red',
					autoClose: 5000,
				});
			}
		},
	});

	return (
		!newFiscalYearQuery.isFetching &&
		newFiscalYearQuery.data && (
			<DetailsLayout title={t('title.add')}>
				<FiscalCalendarForm
					calendar={newFiscalYearQuery.data}
					onSave={addCalendar.mutateAsync}
				/>
			</DetailsLayout>
		)
	);
}
