import { useMemo } from 'react';
import { groupBy, sortBy } from 'lodash-es';
import type { Header, RowData } from '@tanstack/table-core';

import { List, ListItem } from '@apple/ui/list';

import { FilterInput } from './filters';

type Props<Data extends RowData> = {
	headers: Header<Data, unknown>[];
};

export function FiltersList<Data extends RowData>({ headers = [] }: Props<Data>) {
	const groupedHeaders = useMemo(
		() =>
			groupBy(
				sortBy(
					headers.filter(x => x.column.getCanFilter()),
					x => x.column.columnDef.filter?.sortOrder,
				),
				x => x.column.columnDef.filter?.group ?? '',
			),
		[headers],
	);
	const filterLists = useMemo(
		() =>
			Object.entries(groupedHeaders).map(([grouping, headers]) => (
				<List key={grouping} title={grouping}>
					{headers.map(header => (
						<ListItem
							key={header.id}
							label={header.column.columnDef.filter?.label ?? getHeaderLabel(header)}
							htmlFor={header.id}
						>
							<FilterInput
								header={header}
								label={
									header.column.columnDef.filter?.label ?? getHeaderLabel(header)
								}
							/>
						</ListItem>
					))}
				</List>
			)),
		[groupedHeaders],
	);

	return <>{filterLists}</>;
}

function getHeaderLabel<TData extends RowData, TValue>(header: Header<TData, TValue>) {
	return (
		(typeof header.column.columnDef.header === 'function'
			? (header.column.columnDef.header(header.getContext()) as string)
			: header.column.columnDef.header) ?? header.column.id
	);
}
