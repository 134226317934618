import { useCallback } from 'react';
import { Divider, Group, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useQueries } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';
import { useReactTable } from '@tanstack/react-table';
import {
	getCoreRowModel,
	getExpandedRowModel,
	getFacetedRowModel,
	getFacetedUniqueValues,
	getFilteredRowModel,
	getSortedRowModel,
} from '@tanstack/table-core';

import { AddIcon, DownloadIcon, ExportIcon, Upload } from '@apple/assets/icons';
import { requireAuth } from '@apple/features/auth';
import { marketQueries } from '@apple/features/market';
import {
	getBudgetReportUrl,
	saveBudgets,
	uploadStoreBulkTemplate,
} from '@apple/features/store/api/management';
import { getStoreDataTableQueryOptions } from '@apple/features/store/queries/odata';
import { useTranslation } from '@apple/lib/i18next';
import { DataTable, useTableDownload, useTableState } from '@apple/ui/data-table';
import { ToolbarButton } from '@apple/ui/data-table/controls/Toolbar';
import { TableLayout } from '@apple/ui/layouts';
import { Link } from '@apple/ui/link';
import { useFileDownload } from '@apple/utils/files';
import { BulkUploadDialog } from '@apple/utils/files/components/BulkUploadDialog';
import { FormattedBoolean, globalizationQueryOptions } from '@apple/utils/globalization';
import type { StoreFilters, StoreOdataListing } from '@apple/features/store/models/listing';

export const Route = createFileRoute('/_authed/_admin/manage/stores')({
	beforeLoad: args =>
		requireAuth(args, {
			requirePermission: 'Manzanita.Security.Features.CustomerManagement',
		}),
	component: StoreManagementRoute,
});

const defaultFilters: StoreFilters = {};

function StoreManagementRoute() {
	const { t } = useTranslation('manage');
	const navigate = Route.useNavigate();
	const search = Route.useSearch();
	const [uploadOpen, uploadActions] = useDisclosure(false);
	const excelTemplate = useFileDownload({
		method: 'get',
		url: '/api/management/stores/bulk/excel/generate',
		fallbackFilename: 'stores.xlsx',
		errorTitle: t('common:error.title'),
		errorMessage: t('common:error.fileDownload'),
	});

	const tableState = useTableState<StoreOdataListing, StoreFilters>({
		search,
		navigate,
		defaultFilters: defaultFilters,
		defaultSorting: [{ id: 'storeNumber', desc: false }],
		fieldMap: [],
	});

	const [storesQuery, countriesQuery, markets] = useQueries({
		queries: [
			getStoreDataTableQueryOptions({
				filters: tableState.currentFilterData,
				pagination: tableState.state.pagination,
				commonFilters: {},
			}),
			globalizationQueryOptions.countries,
			marketQueries.getMarkets(),
		],
	});

	const table = useReactTable<StoreOdataListing>({
		data: storesQuery.data.rows,
		rowCount: storesQuery.data.totalRowCount,
		...tableState,
		enableFilters: true,
		enableColumnFilters: true,
		enableSorting: true,
		manualFiltering: true,
		manualSorting: true,
		manualPagination: true,
		getCoreRowModel: getCoreRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getExpandedRowModel: getExpandedRowModel(),
		getFacetedRowModel: getFacetedRowModel(),
		getFacetedUniqueValues: (table, columnId) => {
			switch (columnId) {
				case 'countryCode':
					return () => new Map(countriesQuery.data?.map(x => [x.Key, 1]));
				case 'customerGroupName':
					return () => new Map(markets.data?.map(x => [x.name, 1]));
				case 'status':
					return () => new Map([undefined, 'Active', 'Inactive'].map(x => [x, 1]));
				default:
					return getFacetedUniqueValues<StoreOdataListing>()(table, columnId);
			}
		},
		columnResizeMode: 'onChange',
		columns: [
			{
				id: 'name',
				accessorKey: 'name',
				header: t('stores.fields.storeName'),
				filter: {
					group: t('stores.filterGroups.details'),
					label: t('stores.filters.storeName'),
				},
			},
			{
				id: 'thirdPartyId',
				accessorKey: 'thirdPartyId',
				header: t('stores.fields.storeId'),
				filter: {
					group: t('stores.filterGroups.details'),
					label: t('stores.filters.storeId'),
				},
				cell: ({ row }) => (
					<Link
						to='/manage/stores/$storeCode'
						params={{
							storeCode: row.original.code,
						}}
					>
						<Text size='sm'>{row.original.thirdPartyId}</Text>
					</Link>
				),
			},
			{
				id: 'customerGroupName',
				accessorKey: 'customerGroupName',
				header: t('stores.fields.market'),
				filter: {
					group: t('stores.filterGroups.details'),
					label: t('stores.filters.market'),
					variant: 'select',
					getFilterDisplayValue: value =>
						markets.data?.find(x => x.id === Number(value))?.name ?? String(value),
				},
			},
			{
				id: 'code',
				accessorKey: 'code',
				header: t('stores.fields.code'),
				filter: {
					group: t('stores.filterGroups.details'),
					label: t('stores.filters.code'),
				},
			},
			{
				id: 'status',
				accessorKey: 'status',
				header: t('stores.fields.status'),
				cell: ({ row }) => (
					<Group>
						<FormattedBoolean value={row.original.status === 'Active'} useIcon />
						<Text>
							{row.original.status === 'Active'
								? t('stores.fields.active')
								: t('stores.fields.inactive')}
						</Text>
					</Group>
				),
				filter: {
					group: t('stores.filterGroups.details'),
					label: t('stores.filters.status'),
					variant: 'segmented-switch',
					getFilterDisplayValue: x => {
						switch (x) {
							case 'Active':
								return t('stores.fields.active');
							case 'Inactive':
								return t('stores.fields.inactive');
							default:
								return t('common:label.all');
						}
					},
				},
			},
			{
				id: 'costCenter',
				accessorKey: 'costCenter',
				header: t('stores.fields.costCenter'),
				enableColumnFilter: false,
			},
			{
				id: 'recipientName',
				accessorKey: 'recipientName',
				header: t('address:labels.recipient1'),
				enableColumnFilter: false,
			},
			{
				id: 'streetAddress1',
				accessorKey: 'streetAddress1',
				header: t('address:labels.addressLine1'),
				filter: {
					group: t('stores.filterGroups.details'),
					label: t('address:labels.addressLine1'),
				},
			},
			{
				id: 'streetAddress2',
				accessorKey: 'streetAddress2',
				header: t('address:labels.addressLine2'),
				filter: {
					group: t('stores.filterGroups.details'),
					label: t('address:labels.addressLine2'),
				},
			},
			{
				id: 'addressLine3',
				accessorKey: 'streetAddress3',
				header: t('address:labels.addressLine3'),
				enableColumnFilter: false,
			},
			{
				id: 'stateOrProvince',
				accessorKey: 'stateOrProvince',
				header: t('address:labels.stateOrProvince'),
				filter: {
					group: t('stores.filterGroups.details'),
					label: t('address:labels.stateOrProvince'),
				},
			},
			{
				id: 'city',
				accessorKey: 'city',
				header: t('address:labels.city'),
				filter: {
					group: t('stores.filterGroups.details'),
					label: t('address:labels.city'),
				},
			},
			{
				id: 'postalCode',
				accessorKey: 'postalCode',
				header: t('address:labels.postalCode'),
				filter: {
					group: t('stores.filterGroups.details'),
					label: t('address:labels.postalCode'),
				},
			},
			{
				id: 'countryCode',
				accessorKey: 'countryCode',
				header: t('address:labels.countryCode'),
				filter: {
					group: t('stores.filterGroups.details'),
					label: t('address:labels.countryCode'),
					variant: 'select',
					getFilterDisplayValue: value =>
						countriesQuery.data?.find(x => x.Key === value)?.Value ?? String(value),
				},
			},
		],
	});

	const tableDownload = useTableDownload({
		table,
		queryOptionsFn: getStoreDataTableQueryOptions,
	});

	const handleExport = useCallback(() => {
		void tableDownload.download({
			filters: tableState.currentFilterData,
			fileName: 'Stores',
		});
	}, [tableDownload, tableState.currentFilterData]);

	const budgetReportExcelTemplate = useFileDownload({
		method: 'post',
		url: getBudgetReportUrl(),
		fallbackFilename: 'storeBudget.xlsx',
		errorTitle: t('common:error.title'),
		errorMessage: t('common:error.fileDownload'),
	});

	return (
		<TableLayout
			title={t('stores.title')}
			table={table}
			toolbarButtons={[
				<ToolbarButton
					key='download-budget-template'
					tooltip={t('stores.buttons.download.tooltip')}
					icon={DownloadIcon}
					loading={excelTemplate.downloading}
					onClick={() => void excelTemplate.download()}
				/>,
				<ToolbarButton
					key='upload'
					tooltip={t('stores.buttons.upload.tooltip')}
					icon={Upload}
					disabled={uploadOpen}
					onClick={uploadActions.open}
				/>,
				<Divider key='divider-1' orientation='vertical' mx='xs' />,
				<ToolbarButton
					key='export'
					loading={tableDownload.downloading}
					tooltip={t('stores.buttons.export.tooltip')}
					icon={ExportIcon}
					onClick={handleExport}
				/>,
				<Divider key='divider-2' orientation='vertical' mx='xs' />,
				<ToolbarButton
					key='download-budget-report'
					tooltip={t('stores.buttons.downloadBudget.tooltip')}
					icon={DownloadIcon}
					loading={budgetReportExcelTemplate.downloading}
					onClick={() => void budgetReportExcelTemplate.download()}
				/>,
				<ToolbarButton
					key='add'
					tooltip={t('stores.buttons.add.tooltip')}
					icon={AddIcon}
					onClick={() =>
						void navigate({
							to: '/manage/stores/add',
						})
					}
				/>,
			]}
		>
			<DataTable table={table} minWidth='3000px' loading={storesQuery.isFetching} />
			<BulkUploadDialog
				opened={uploadOpen}
				close={uploadActions.close}
				onUpload={uploadStoreBulkTemplate}
				onSave={saveBudgets}
			/>
		</TableLayout>
	);
}
