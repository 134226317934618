import { z } from 'zod';

import { AssociationType, associationTypeSchema } from '@apple/features/associations/models';
import { marketIdSchema } from '@apple/features/market';
import {
	countryStoreEventRuleSchema,
	globalStoreEventRuleSchema,
	marketStoreEventRuleSchema,
	plantStoreEventRuleSchema,
} from '@apple/features/product-retail/models/store-events';
import type {
	CountryStoreEventRule,
	GlobalStoreEventRule,
	MarketStoreEventRule,
	PlantStoreEventRule,
	StoreEventProductRule,
} from '@apple/features/product-retail/models/store-events';
import { productKeySchema } from '@apple/features/product/models/product';
import type { ProductKey } from '@apple/features/product/models/product';
import { countryCodeSchema } from '@apple/utils/globalization';
import { type StoreEventCategorySize, storeEventCategorySizeSchema } from './categories';
import { type StoreEventType, storeEventTypeSchema } from './shared';

/**
 * @see Manzanita.DataModels.ActionType
 */
export enum Action {
	Add = 1,
	Update = 2,
	Remove = 3,
}

/**
 * @see AppleRetail.Web.Areas.StoreEvents.Models.StoreEventProductAssociationModel
 */
interface StoreEventProductAssociation<
	T extends AssociationType,
	K extends null | string | number,
> {
	categoryId: string;
	categorySize: StoreEventCategorySize;
	eventType: StoreEventType;
	quantity: number;
	accessTypeId: T;
	accessConfiguration: K;
}

interface AllAssociation extends StoreEventProductAssociation<AssociationType.All, null> {}
interface CountryAssociation
	extends StoreEventProductAssociation<AssociationType.Country, string> {}
interface PlantAssociation extends StoreEventProductAssociation<AssociationType.Plant, number> {}
interface MarketAssociation
	extends StoreEventProductAssociation<AssociationType.CustomerGroup, number> {}

type Association = AllAssociation | CountryAssociation | PlantAssociation | MarketAssociation;

/**
 * @see AppleRetail.Web.Features.StoreEvents.StoreEventProductChangePropagationController.PropagationActionModel
 */
export type PropagationActionRequest = {
	itemId: ProductKey;
	action: Action;
	association: Association;
};

interface BasePropagation<T> {
	propagationAction: T;
	storeEventId: string;
	storeEventItemId: string | null;
	quantityMultiplier: number;
}

/**
 * @see AppleRetail.Web.Features.StoreEvents.StoreEventProductChangePropagationController.PropagationModel
 * Used to propagate the item rule changes to the store event
 */
export interface PropagationRequest extends BasePropagation<PropagationActionRequest> {}

interface BaseCandidate {
	storeName: string;
	thirdPartyStoreId: string;
	quantity: number | null;
	isLocked: boolean;
	isApplicable: boolean;
}

/**
 * @see AppleRetail.Web.Features.StoreEvents.StoreEventProductChangePropagationController.PropagationCandidateModel
 */
export interface PropagationCandidateModel
	extends BasePropagation<PropagationActionRequest>,
		BaseCandidate {}

/**
 * @see Manzanita.DataModels.ActionType
 */
export const actionSchema = z.nativeEnum(Action);

/**
 * @see AppleRetail.Web.Areas.StoreEvents.Models.StoreEventProductAssociationModel
 */
const storeEventAssociationSchema = z.object({
	categoryId: z.string(),
	categorySize: storeEventCategorySizeSchema,
	eventType: storeEventTypeSchema,
	quantity: z.number(),
	accessTypeId: associationTypeSchema,
});

const allAssociationSchema = storeEventAssociationSchema.extend({
	accessTypeId: z.literal(AssociationType.All),
	accessConfiguration: z.null(),
});
const countryAssociationSchema = storeEventAssociationSchema.extend({
	accessTypeId: z.literal(AssociationType.Country),
	accessConfiguration: countryCodeSchema,
});
const plantAssociationSchema = storeEventAssociationSchema.extend({
	accessTypeId: z.literal(AssociationType.Plant),
	accessConfiguration: z.number(),
});
const marketAssociationSchema = storeEventAssociationSchema.extend({
	accessTypeId: z.literal(AssociationType.CustomerGroup),
	accessConfiguration: marketIdSchema,
});

const associationSchema = z.union([
	allAssociationSchema,
	countryAssociationSchema,
	plantAssociationSchema,
	marketAssociationSchema,
]);

/**
 * @see AppleRetail.Web.Features.StoreEvents.StoreEventProductChangePropagationController.PropagationActionModel
 */
export const propagationActionRequestSchema = z.object({
	itemId: productKeySchema,
	action: actionSchema,
	association: associationSchema,
}) satisfies z.ZodType<PropagationActionRequest>;

function createPropagationSchema<T extends z.ZodTypeAny>(actionSchema: T) {
	return z.object({
		propagationAction: actionSchema,
		storeEventId: z.string(),
		storeEventItemId: z.string().nullable(),
		quantityMultiplier: z.number(),
	});
}

/**
 * @see AppleRetail.Web.Features.StoreEvents.StoreEventProductChangePropagationController.PropagationModel
 * Used to propagate the item rule changes to the store event
 */
export const propagationRequestSchema = createPropagationSchema(
	propagationActionRequestSchema,
) satisfies z.ZodType<PropagationRequest>;

const baseCandidateSchema = z.object({
	storeName: z.string(),
	thirdPartyStoreId: z.string(),
	quantity: z.number().nullable(),
	isLocked: z.boolean(),
	isApplicable: z.boolean(),
});

/**
 * @see AppleRetail.Web.Features.StoreEvents.StoreEventProductChangePropagationController.PropagationCandidateModel
 */
export const propagationCandidateModelSchema = createPropagationSchema(
	propagationActionRequestSchema,
).and(baseCandidateSchema) satisfies z.ZodType<PropagationCandidateModel>;

/**
 * The models below represent store event rules based on the collections in the product entity's
 * store event configuration property.
 */
type RuleWithAssociationType =
	| {
			associationType: 'global';
			association: GlobalStoreEventRule;
	  }
	| {
			associationType: 'plant';
			association: PlantStoreEventRule;
	  }
	| {
			associationType: 'country';
			association: CountryStoreEventRule;
	  }
	| {
			associationType: 'market';
			association: MarketStoreEventRule;
	  };

export type PropagationAction = { itemId: ProductKey; action: Action } & RuleWithAssociationType;

export interface Propagation extends BasePropagation<PropagationAction> {}

export interface PropagationCandidate extends Propagation, BaseCandidate {}

const ruleWithAssociationTypeSchema = z.union([
	z.object({
		associationType: z.literal('global'),
		association: globalStoreEventRuleSchema,
	}),
	z.object({
		associationType: z.literal('plant'),
		association: plantStoreEventRuleSchema,
	}),
	z.object({
		associationType: z.literal('country'),
		association: countryStoreEventRuleSchema,
	}),
	z.object({
		associationType: z.literal('market'),
		association: marketStoreEventRuleSchema,
	}),
]);

export const propagationActionSchema = z
	.object({
		itemId: productKeySchema,
		action: actionSchema,
	})
	.and(ruleWithAssociationTypeSchema) satisfies z.ZodType<PropagationAction>;

export const propagationSchema = createPropagationSchema(
	propagationActionSchema,
) satisfies z.ZodType<Propagation>;
export const propagationCandidateSchema = createPropagationSchema(propagationActionSchema).and(
	baseCandidateSchema,
) satisfies z.ZodType<PropagationCandidate>;

// Converts a PropagationCandidateModel to a PropagationCandidate - the associations are transformed
// to store event product rules e.g. types in the store event configuration property on the product.
export const requestCandidatesToProductRuleSchema =
	propagationCandidateModelSchema.transform<PropagationCandidate>(x => {
		return {
			...x,
			propagationAction: {
				...x.propagationAction,
				...associationToRuleWithTypeSchema(x.propagationAction.itemId).parse(
					x.propagationAction.association,
				),
			},
		};
	});

export const propagationActionToPropagationActionRequestSchema =
	propagationActionSchema.transform<PropagationActionRequest>(x => {
		const association = {
			categoryId: x.association.storeEventCategoryId,
			categorySize: x.association.categorySize ?? 'All',
			eventType: x.association.eventType as StoreEventType,
			quantity: x.association.defaultQuantity,
		};

		switch (x.associationType) {
			case 'global':
				return {
					action: x.action,
					itemId: x.itemId,
					association: {
						...association,
						accessTypeId: AssociationType.All,
						accessConfiguration: null,
					} satisfies AllAssociation,
				};
			case 'plant':
				return {
					action: x.action,
					itemId: x.itemId,
					association: {
						...association,
						accessTypeId: AssociationType.Plant,
						accessConfiguration: x.association.warehouseLocationId,
					} satisfies PlantAssociation,
				};
			case 'country':
				return {
					action: x.action,
					itemId: x.itemId,
					association: {
						...association,
						accessTypeId: AssociationType.Country,
						accessConfiguration: x.association.countryCode,
					} satisfies CountryAssociation,
				};
			case 'market':
				return {
					action: x.action,
					itemId: x.itemId,
					association: {
						...association,
						accessTypeId: AssociationType.CustomerGroup,
						accessConfiguration: x.association.customerGroupId,
					} satisfies MarketAssociation,
				};
		}
	});

export const propagationToPropagationRequestSchema =
	propagationSchema.transform<PropagationRequest>(x => {
		return {
			propagationAction: propagationActionToPropagationActionRequestSchema.parse(
				x.propagationAction,
			),
			storeEventId: x.storeEventId,
			storeEventItemId: x.storeEventItemId,
			quantityMultiplier: x.quantityMultiplier,
		};
	});

const associationToRuleWithTypeSchema = (itemId: ProductKey) =>
	associationSchema.transform<RuleWithAssociationType>(x => {
		const rule = {
			itemId: itemId,
			storeEventCategoryId: x.categoryId,
			categorySize: x.categorySize,
			eventType: x.eventType,
			defaultQuantity: x.quantity,
		} satisfies StoreEventProductRule;

		switch (x.accessTypeId) {
			case AssociationType.All:
				return {
					associationType: 'global',
					association: rule,
				};
			case AssociationType.Plant:
				return {
					associationType: 'plant',
					association: {
						...rule,
						warehouseLocationId: x.accessConfiguration,
					},
				};
			case AssociationType.Country:
				return {
					associationType: 'country',
					association: {
						...rule,
						countryCode: x.accessConfiguration,
					},
				};
			case AssociationType.CustomerGroup:
				return {
					associationType: 'market',
					association: {
						...rule,
						customerGroupId: x.accessConfiguration,
					},
				};
		}
	});
