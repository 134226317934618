import { createFileRoute, useNavigate } from '@tanstack/react-router';
import { getLogger } from 'loglevel';

import { ShippingStep } from '@apple/features/cart-retail/components/steps/shipping';

const log = getLogger('cart');

export const Route = createFileRoute('/_authed/cart/shipping')({
	component: RouteComponent,
});

function RouteComponent() {
	const navigate = useNavigate();
	return (
		<ShippingStep
			onCartSubmitted={result => {
				log.debug('Cart submitted', result);
				void navigate({
					to: '/cart/confirmation/$salesOrderNumber',
					params: { salesOrderNumber: result.salesOrderNumber },
				});
			}}
		/>
	);
}
