import { forwardRef } from 'react';
import { Group, Text } from '@mantine/core';
import type { TextProps } from '@mantine/core';

import { Expand } from '@apple/assets/icons';

type Props = {
	label: string | null;
	size: TextProps['size'];
	testId?: string;
	onClick?: () => void;
};

export const DisclosureText = forwardRef<HTMLDivElement, Props>(
	({ label, size, testId, onClick }, ref) => {
		return (
			<Group
				ref={ref}
				align='center'
				justify='flex-start'
				wrap='nowrap'
				gap={0}
				style={{ cursor: 'pointer' }}
				onClick={onClick}
			>
				<Expand size={'sm'} c='gray' data-testid={testId} />
				<Text size={size} span lineClamp={1}>
					{label}
				</Text>
			</Group>
		);
	},
);
DisclosureText.displayName = 'DisclosureText';
