import { Text } from '@mantine/core';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useReactTable } from '@tanstack/react-table';
import { getCoreRowModel, getPaginationRowModel, getSortedRowModel } from '@tanstack/table-core';

import { useTranslation } from '@apple/lib/i18next';
import { DataTable, useTableDownload } from '@apple/ui/data-table';
import { FormattedDate } from '@apple/utils/globalization';
import { notifications } from '@mantine/notifications';
import type { Guid } from '@apple/utils/primitive';
import { ErrorIcon, ExportIcon } from '@apple/assets/icons';
import { ToolbarButton } from '@apple/ui/data-table/controls/Toolbar';
import dayjs from 'dayjs';
import { TableLayout } from '@apple/ui/layouts/TableLayout';

import { storeEventQueries } from '../queries';
import type { StoreEventAuditLogEntryModel } from '../models/listing';

export type StoreEventAuditLogProps = {
	storeEventId: Guid;
};

export function StoreEventAuditLog({ storeEventId }: StoreEventAuditLogProps) {
	const { t } = useTranslation(['store-event', 'controls']);

	const storeEventAuditsQuery = useQuery(storeEventQueries.audit(storeEventId));

	const table = useReactTable<StoreEventAuditLogEntryModel>({
		data: storeEventAuditsQuery.data,
		getCoreRowModel: getCoreRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		getSortedRowModel: getSortedRowModel(),
		enableSorting: true,
		columnResizeMode: 'onChange',
		initialState: {
			sorting: [
				{
					id: 'timestamp',
					desc: true,
				},
			],
			pagination: { pageSize: 15 },
		},
		columns: [
			{
				id: 'timestamp',
				accessorKey: 'timestamp',
				header: t('audit.fields.date'),
				size: 20,
				cell: ({ row }) => <FormattedDate value={row.original.timestamp} showTime />,
			},
			{
				id: 'categoryName',
				accessorKey: 'categoryName',
				header: t('audit.fields.category'),
				cell: ({ row }) => <Text>{row.original.categoryName}</Text>,
			},
			{
				id: 'eventText',
				accessorKey: 'eventText',
				header: t('audit.fields.event'),
				cell: ({ row }) => <Text>{row.original.eventText}</Text>,
			},
			{
				id: 'username',
				accessorKey: 'username',
				header: t('audit.fields.user'),
				size: 20,
				cell: ({ row }) => <Text>{row.original.username}</Text>,
			},
		],
	});

	const tableDownload = useTableDownload({
		table,
		queryOptionsFn: _ => storeEventQueries.audit(storeEventId),
	});

	const handleExport = useMutation({
		mutationFn: async () =>
			await tableDownload.download({
				filters: {},
				fileName: `EventAuditLog_${dayjs().format('YYYY-MM-DD')}.xlsx`,
			}),
		onError: () => {
			notifications.show({
				message: t('common:error.generic'),
				icon: <ErrorIcon />,
				color: 'red',
				autoClose: 5000,
			});
		},
	});

	return (
		<TableLayout
			title={t('audit.title')}
			table={table}
			toolbarButtons={[
				<ToolbarButton
					key='export'
					loading={tableDownload.downloading}
					tooltip={t('controls:dataTable.exportResults')}
					icon={ExportIcon}
					onClick={() => handleExport.mutate()}
				/>,
			]}
		>
			<DataTable table={table} loading={storeEventAuditsQuery.isFetching} />
		</TableLayout>
	);
}
