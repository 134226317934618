import { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { utils, writeFile } from 'xlsx';
import type { DefinedInitialDataOptions, FetchQueryOptions } from '@tanstack/react-query';
import type { RowData, Table } from '@tanstack/table-core';

import { DEFAULT_PAGE_INDEX } from '@apple/utils/pagination';
import type { DataTableQueryOptions, FilterData } from '@apple/ui/data-table';
import type { PageState } from '@apple/utils/pagination';

export function useTableDownload<
	TData extends RowData,
	TFilters extends FilterData<TFilters>,
	TQueryData = PageState<TData> | TData[],
>({
	table,
	queryOptionsFn,
}: {
	table: Table<TData>;
	queryOptionsFn: (
		options: DataTableQueryOptions<TFilters>,
	) => // biome-ignore lint/suspicious/noExplicitAny: generics
		| FetchQueryOptions<TQueryData, Error, TQueryData, any[]>
		// biome-ignore lint/suspicious/noExplicitAny: generics
		| DefinedInitialDataOptions<TQueryData, Error, TQueryData, any[]>;
}) {
	const [downloading, setDownloading] = useState(false);
	const queryClient = useQueryClient();

	async function download({
		filters,
		fileName,
		sheetName,
		rowLimit = 50000,
	}: {
		filters: TFilters;
		fileName: string;
		sheetName?: string;
		rowLimit?: number;
	}) {
		const options = queryOptionsFn({
			filters,
			pagination: { pageIndex: DEFAULT_PAGE_INDEX, pageSize: rowLimit },
		});

		try {
			setDownloading(true);
			// Fetch data using the provided query options
			const data = await queryClient.fetchQuery(options);

			// If data has a 'rows' property, use it. Otherwise, assume data is an array.
			const dataRows =
				data && typeof data === 'object' && 'rows' in data
					? (data as unknown as PageState<TData>).rows
					: (data as TData[]);

			// Prepare headers and rows for the export
			const headers = table
				.getLeafHeaders()
				.filter(header => typeof header.column.columnDef.header === 'string');
			const headerTitles = headers.map(header => header.column.columnDef.header);
			const rows = dataRows.map(row =>
				headers.map((header, i) =>
					header.column.accessorFn ? header.column.accessorFn(row, i) : undefined,
				),
			);

			// Combine headers and rows, then initiate file download
			const workbook = utils.book_new();
			const worksheet = utils.aoa_to_sheet([headerTitles, ...rows]);
			utils.book_append_sheet(workbook, worksheet, sheetName);
			writeFile(workbook, `${fileName}.xlsx`, {});
		} finally {
			setDownloading(false);
		}
	}

	return { download, downloading };
}
