import { Container, Stepper, rem } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { Outlet, createFileRoute, useMatches } from '@tanstack/react-router';
import { useMemo } from 'react';

import { Success } from '@apple/assets/icons';
import { useTranslation } from '@apple/lib/i18next';

export const Route = createFileRoute('/_authed/store-events/_steps')({
	beforeLoad: args => {
		return {
			readOnly: !args.context.auth.hasPermission(
				'AppleRetail.Security.Features.StoreEventManagement.Write',
			),
		};
	},
	component: RouteComponent,
});

function RouteComponent() {
	const matches = useMatches();
	const { t } = useTranslation('store-event');
	const vertical = useMediaQuery('(max-width: lg)');
	const currentStepIndex = useMemo(() => {
		const lastMatch = matches.at(-1);
		switch (lastMatch?.fullPath) {
			case '/store-events/$storeEventId/details':
				return 0;
			case '/store-events/$storeEventId/categories':
				return 1;
			case '/store-events/$storeEventId/products':
				return 2;
			default:
				return 0;
		}
	}, [matches]);

	return (
		<Container fluid p='md'>
			<Stepper
				size='sm'
				completedIcon={<Success c='white' />}
				active={currentStepIndex}
				// onStepClick={onGotoStep}
				allowNextStepsSelect={false}
				orientation={vertical ? 'vertical' : 'horizontal'}
				styles={{
					steps: {
						paddingLeft: rem(32),
						paddingRight: rem(32),
						paddingTop: rem(32),
					},
				}}
			>
				<Stepper.Step label={t('details.title')} description={t('details.description')}>
					<Outlet />
				</Stepper.Step>

				<Stepper.Step
					label={t('categories.title')}
					description={t('categories.description')}
				>
					<Outlet />
				</Stepper.Step>

				<Stepper.Step label={t('products.title')} description={t('products.description')}>
					<Outlet />
				</Stepper.Step>

				<Stepper.Completed>
					<Outlet />
				</Stepper.Completed>
			</Stepper>
		</Container>
	);
}
