import { useMemo } from 'react';

import {
	ActivityLogIcon,
	AnnouncementOutlineIcon,
	BackorderedProductsIcon,
	BudgetIcon,
	CalendarIcon,
	Category,
	DashboardIcon,
	DistroOrdersIcon,
	EmailIcon,
	Faq,
	FulfillmentConfigurationIcon,
	StoreEventsIcon,
	OrderSearchIcon,
	Plant,
	Products,
	Programs,
	RecurringOrderIcon,
	ShoppingBagOutline,
	Stores,
	Success,
	SupportIcon,
	Users,
} from '@apple/assets/icons';
import { useAuthContext } from '@apple/features/auth-retail/hooks/useAuthContext';
import { useTranslation } from '@apple/lib/i18next';
import type { MenuItem, MenuItemGroup } from '@apple/ui/layouts/components/menu-item';

export function useAppleRetailNavMenu() {
	const { t } = useTranslation('common');
	const { filterByPermissions, profile } = useAuthContext();

	const menuItems = useMemo(() => {
		return filterByPermissions<MenuItemGroup>([
			{
				requireAuth: true,
				items: filterByPermissions<MenuItem>([
					// FIXME: Merge the two dashboard routes into one
					{
						label: t('mainMenu.dashboard'),
						icon: DashboardIcon,
						to: '/',
						requireAuth: true,
					},
					{
						label: t('mainMenu.shop'),
						icon: ShoppingBagOutline,
						to: '/cart',
						requireAuth: true,
					},
					{
						label: t('mainMenu.orders'),
						icon: OrderSearchIcon,
						to: '/orders/search',
						requireAuth: true,
					},
					{
						icon: StoreEventsIcon,
						label: t('mainMenu.eventManagement'),
						to: '/store-events',
						requirePermission:
							'AppleRetail.Security.Features.StoreEventManagement.Read',
					},
					{
						icon: DistroOrdersIcon,
						label: t('mainMenu.distroOrders.label'),
						to: '/distro/history',
						requirePermission: 'Manzanita.Security.Features.DistroOrderHistory',
					},
					{
						label: t('mainMenu.recurringOrders'),
						icon: RecurringOrderIcon,
						to: '/orders/recurring',
						requireAuth: true,
					},
					{
						label: t('mainMenu.fulfillmentConfiguration'),
						icon: FulfillmentConfigurationIcon,
						to: '/fulfillment',
						requirePermissions: [
							'Manzanita.Security.Features.FulfillmentRuleManagement.Read',
							'Manzanita.Security.Features.FulfillmentRuleManagement.Write',
						],
					},
					{
						label: t('mainMenu.help'),
						icon: SupportIcon,
						to: '/help',
						requireAuth: true,
					},
				]),
			},

			{
				label: t('mainMenu.approvals.label'),
				requireAuth: true,
				items: filterByPermissions<MenuItem>([
					{
						icon: Success,
						label: t('mainMenu.approvals.orderApprovals'),
						to: '/approvals/orders',
						requirePermission: 'Manzanita.Security.Features.OrderManagement.Read',
					},
				]),
			},
			{
				label: t('mainMenu.manage.label'),
				requirePermission: 'Manzanita.Security.Features.Snapshot',
				items: filterByPermissions<MenuItem>([
					{
						icon: Products,
						label: t('mainMenu.manage.products'),
						to: '/manage/products',
						requirePermission: 'Manzanita.Security.Features.ProductManagement.Read',
					},
					{
						icon: Category,
						label: t('mainMenu.manage.categories'),
						to: '/manage/categories',
						requirePermission:
							'Manzanita.Security.Features.ProductCategoryManagement.Read',
					},
					{
						icon: Users,
						label: t('mainMenu.manage.users'),
						to: '/manage/users',
						requirePermission: 'Manzanita.Security.Features.UserManagement.Read',
					},
					{
						icon: Stores,
						label: t('mainMenu.manage.stores'),
						to: '/manage/stores',
						requirePermission: 'Manzanita.Security.Features.CustomerManagement',
					},
					{
						icon: Programs,
						label: t('mainMenu.manage.markets'),
						to: '/manage/markets',
						requirePermission:
							'Manzanita.Security.Features.CustomerGroupManagement.Read',
					},
					{
						icon: Plant,
						label: t('mainMenu.manage.plants'),
						to: '/manage/plants',
						requirePermission: 'Manzanita.Security.Features.PlantManagement.Read',
					},
					{
						icon: CalendarIcon,
						label: t('mainMenu.manage.calendars'),
						to: '/manage/calendars',
						// requirePermission: 'TBD',
					},
				]),
			},
			{
				label: t('mainMenu.content.label'),
				requirePermission: 'Manzanita.Security.Features.ContentManagement',
				items: filterByPermissions<MenuItem>([
					{
						icon: Faq,
						label: t('mainMenu.content.faqs'),
						to: '/content/faq',
					},
					{
						icon: AnnouncementOutlineIcon,
						label: t('mainMenu.content.announcements'),
						to: '/content/announcements',
					},
				]),
			},
			{
				label: t('mainMenu.reports.label'),
				items: filterByPermissions<MenuItem>([
					{
						icon: ActivityLogIcon,
						label: t('mainMenu.reports.activityLog'),
						to: '/reports/activity-log',
						requirePermission: 'AppleRetail.Security.Features.ActivityLog',
					},
					{
						icon: BackorderedProductsIcon,
						label: t('mainMenu.reports.backorderedProducts'),
						to: '/reports/backorders',
						requireAuth: true,
					},
					{
						icon: EmailIcon,
						label: t('mainMenu.reports.emails'),
						to: '/reports/emails',
						requirePermission: 'Manzanita.Security.Features.Reports',
					},
					{
						icon: Products,
						label: t('mainMenu.manage.products'),
						to: '/reports/products',
						requirePermission: 'Manzanita.Security.Features.Reports',
					},
					{
						icon: Users,
						label: t('mainMenu.manage.users'),
						to: '/reports/users',
						requirePermission: 'Manzanita.Security.Features.Reports',
					},
					{
						icon: SupportIcon,
						label: t('mainMenu.reports.supportRequests'),
						to: '/reports/supportRequests',
						requirePermission: 'Manzanita.Security.Features.Reports',
					},
					{
						label: t('mainMenu.reports.storeBudget'),
						icon: BudgetIcon,
						to: '/store/budget',
						requireAuth: true,
						hidden: !profile?.singleStoreAccess,
					},
					{
						icon: BudgetIcon,
						label: t('mainMenu.reports.storeBudgets'),
						to: '/reports/budgets',
						requirePermission: 'Manzanita.Security.Features.Reports',
					},
				]),
			},
		]);
	}, [filterByPermissions, profile?.singleStoreAccess, t]);

	return { menuItems };
}
