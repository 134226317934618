import { useTranslation } from '@apple/lib/i18next';
import { Text } from '@mantine/core';
import camelCase from 'lodash-es/camelCase';

import type { StoreEventStatus } from '../models/shared';

type Props = {
	value: StoreEventStatus;
};

export function StoreEventStatusText({ value }: Props) {
	const { t } = useTranslation('store-event');

	return <Text>{t(`eventStatus.${camelCase(String(value))}`)}</Text>;
}
