import axios from 'axios';

import type { PaginatedListDto } from '@apple/utils/pagination/models';

import type { StoreEventCategory, StoreEventCategoryListingRequest } from '../models/categories';

/**
 * @see AppleRetail.Web.Areas.StoreEvents.ApiControllers.StoreEventCategoryApiController
 * */

/** Gets all of the store event categories. */
export async function getAll(signal: AbortSignal): Promise<StoreEventCategory[]> {
	const response = await getCategories(signal);
	return response.items;
}

/** Gets a paginated list of store event categories based on the supplied filters.*/
export async function getCategories(
	signal: AbortSignal,
	request: Partial<StoreEventCategoryListingRequest> = {},
): Promise<PaginatedListDto<StoreEventCategory>> {
	const response = await axios.get<PaginatedListDto<StoreEventCategory>>(
		'/api/store-event-catagories',
		{
			params: request,
			signal,
		},
	);

	return response.data;
}

export async function getCategoriesAffectedByFilter(
	signal: AbortSignal,
	request: StoreEventCategoryListingRequest,
): Promise<string[]> {
	const response = await axios.get<string[]>('/api/store-event-catagories/affected', {
		params: request,
		signal,
	});
	return response.data;
}
