import { extend } from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import timeZone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

const strictThresholds = [
	{ l: 's', r: 1 },
	{ l: 'm', r: 1 },
	{ l: 'mm', r: 59, d: 'minute' },
	{ l: 'h', r: 1 },
	{ l: 'hh', r: 23, d: 'hour' },
	{ l: 'd', r: 1 },
	{ l: 'dd', r: 29, d: 'day' },
	{ l: 'M', r: 1 },
	{ l: 'MM', r: 11, d: 'month' },
	{ l: 'y', r: 1 },
	{ l: 'yy', d: 'year' },
];

export function configDayjs() {
	extend(advancedFormat);
	extend(isSameOrAfter);
	extend(isSameOrBefore);
	extend(localizedFormat);
	extend(relativeTime, { thresholds: strictThresholds, rounding: Math.floor });
	extend(timeZone);
	extend(utc);
}
