import { Text } from '@mantine/core';
import camelCase from 'lodash-es/camelCase';

import { useTranslation } from '@apple/lib/i18next';

import type { StoreEventType } from '../models/shared';

type Props = {
	value: StoreEventType;
};

export function StoreEventTypeText({ value }: Props) {
	const { t } = useTranslation('store-event');

	return <Text>{t(`eventType.${camelCase(String(value))}`)}</Text>;
}
