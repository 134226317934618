import { useCallback } from 'react';
import { Box, Container, Group, Stack, Title } from '@mantine/core';
import { createFileRoute, redirect } from '@tanstack/react-router';
import { z } from 'zod';

import { ResetPasswordForm, validateToken } from '@apple/features/auth';
import { useTranslation } from '@apple/lib/i18next';
import { getEnv } from '@apple/utils/config/env';

const { APPLE_APP_TITLE } = getEnv();

export const Route = createFileRoute('/(public)/reset-password_/form')({
	component: ResetPasswordRoute,
	validateSearch: z.object({ token: z.string().optional() }),
	beforeLoad: ({ search }) => {
		if (!search.token) {
			throw redirect({ to: '/forgot-password', replace: true });
		}
		return { token: search.token };
	},
	loader: async ({ context: { token } }) => {
		const data = await validateToken(token);

		return { data: data };
	},
});

function ResetPasswordRoute() {
	const { t } = useTranslation('auth');
	const { data } = Route.useLoaderData();
	const navigate = Route.useNavigate();

	const navigateToMain = useCallback(() => {
		void navigate({ to: '/' });
	}, [navigate]);

	const navigateToResetPassword = useCallback(() => {
		void navigate({ to: '/forgot-password' });
	}, [navigate]);

	return (
		<Container size='xl' my={40}>
			<Group justify='center'>
				<Stack justify='space-between'>
					<Group justify='center'>
						<Title ta='center' size='h2' mt='xs'>
							{t('heading.resetPassword', { siteName: APPLE_APP_TITLE })}
						</Title>
					</Group>
					<Box p={20} mt='sm'>
						<ResetPasswordForm
							data={data}
							token={data.token}
							navigateToResetPassword={navigateToResetPassword}
							navigateToMain={navigateToMain}
						/>
					</Box>
				</Stack>
			</Group>
		</Container>
	);
}
