import { notifications } from '@mantine/notifications';
import { useMutation, useSuspenseQuery } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';
import { getLogger } from 'loglevel';

import { ErrorIcon, Success } from '@apple/assets/icons';
import { useAuthContext } from '@apple/features/auth-retail/hooks/useAuthContext';
import { deleteOrder, saveOrder } from '@apple/features/order-retail/api/recurring';
import { RecurringOrderForm } from '@apple/features/order-retail/components/recurring/RecurringOrderForm';
import {
	recurringOrderQueries,
	recurringOrderQueryKeys,
} from '@apple/features/order-retail/queries/recurring';
import { storeQueries } from '@apple/features/store/queries';
import { useTranslation } from '@apple/lib/i18next';
import { DetailsLayout } from '@apple/ui/layouts';
import { ServerValidationError } from '@apple/utils/api/errors';
import type {
	RecurringOrder,
	RecurringOrderRequest,
} from '@apple/features/order-retail/models/recurring';

const log = getLogger('manage-recurring-order');

export const Route = createFileRoute('/_authed/orders/recurring_/$id')({
	parseParams: ({ id }) => ({
		id: id,
	}),
	wrapInSuspense: true,
	component: RecurringOrderRoute,
});

function RecurringOrderRoute() {
	const { t } = useTranslation('order-recurring');
	const { id } = Route.useParams();
	const { queryClient } = Route.useRouteContext();
	const auth = useAuthContext();
	const isAdmin = auth.hasPermission('AppleRetail.Security.Features.RecurringOrdersManagement');
	const recurringOrderDetailQuery = useSuspenseQuery(recurringOrderQueries.recurringOrder(id));
	const storeDetailQuery = useSuspenseQuery(
		storeQueries.store(recurringOrderDetailQuery.data.storeCode),
	);
	const navigate = Route.useNavigate();

	async function handleSuccessfulChange(message: string) {
		await queryClient.invalidateQueries({
			queryKey: recurringOrderQueryKeys.all,
		});

		notifications.show({
			message: message,
			icon: <Success />,
			color: 'green',
			autoClose: 5000,
		});
		await navigate({
			to: '/orders/recurring',
		});
	}
	function showErrorNotification(error: Error) {
		if (!(error instanceof ServerValidationError)) {
			log.error(error);
			notifications.show({
				message: t('common:error.generic'),
				icon: <ErrorIcon />,
				color: 'red',
				autoClose: 5000,
			});
		}
	}

	const saveRecurringOrderMutation = useMutation({
		mutationFn: async (request: RecurringOrderRequest) => {
			await saveOrder({
				...request,
				poSpecialistEmailsJson: request.poSpecialistEmails,
			} as RecurringOrder);
		},
		onSuccess: async () => {
			await handleSuccessfulChange(t('editOrder.success'));
		},
		onError: showErrorNotification,
	});

	const deleteRecurringOrderMutation = useMutation({
		mutationFn: deleteOrder,
		onSuccess: async () => {
			await handleSuccessfulChange(t('deleteOrder.success'));
		},
		onError: showErrorNotification,
	});

	return (
		<DetailsLayout title={t('editOrder.title')}>
			<RecurringOrderForm
				isAdmin={isAdmin}
				isEdit={true}
				recurringOrder={recurringOrderDetailQuery.data}
				store={storeDetailQuery.data}
				saveRecurringOrderMutation={saveRecurringOrderMutation}
				deleteRecurringOrderMutation={deleteRecurringOrderMutation}
			/>
		</DetailsLayout>
	);
}
