import { Button, Group, Text } from '@mantine/core';
import { modals } from '@mantine/modals';
import { notifications } from '@mantine/notifications';
import { useMutation, useQueries } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';
import { useReactTable } from '@tanstack/react-table';
import {
	getCoreRowModel,
	getExpandedRowModel,
	getFacetedRowModel,
	getFacetedUniqueValues,
	getFilteredRowModel,
	getSortedRowModel,
} from '@tanstack/table-core';
import camelCase from 'lodash-es/camelCase';
import { getLogger } from 'loglevel';
import { useCallback } from 'react';

import {
	ActivityLogIcon,
	AddIcon,
	ArchiveIcon,
	ClearIcon,
	ErrorIcon,
	Success,
} from '@apple/assets/icons';
import { plantQueryOptions } from '@apple/features/plants/queries/queries';
import { archiveStoreEvent, cancelStoreEvent } from '@apple/features/store-events/api/management';
import { StoreEventAuditLog } from '@apple/features/store-events/components/StoreEventAuditLog';
import { StoreEventStatusText } from '@apple/features/store-events/components/event-status';
import { StoreEventTypeText } from '@apple/features/store-events/components/event-type';
import type {
	StoreEventFilters,
	StoreEventListingModel,
} from '@apple/features/store-events/models/listing';
import {
	storeEventStatusSchema,
	storeEventTypeSchema,
} from '@apple/features/store-events/models/shared';
import { storeEventQueryKeys } from '@apple/features/store-events/queries';
import { storeEventsOdataQueryOptions } from '@apple/features/store-events/queries/odata';
import { useTranslation } from '@apple/lib/i18next';
import { DataTable, createFilterOnlyColumn, useTableState } from '@apple/ui/data-table';
import { ToolbarButton } from '@apple/ui/data-table/controls/Toolbar/toolbar-button';
import { TableLayout } from '@apple/ui/layouts';
import { Link } from '@apple/ui/link';
import { ServerValidationError } from '@apple/utils/api/errors';
import type { Guid } from '@apple/utils/primitive';

const log = getLogger('store-events');
const defaultFilters: StoreEventFilters = {};

export const Route = createFileRoute('/_authed/store-events/')({
	component: StoreEventsManagementRoute,
});

export function StoreEventsManagementRoute() {
	const { t } = useTranslation('store-event');
	const { queryClient, auth } = Route.useRouteContext();
	const navigate = Route.useNavigate();
	const search = Route.useSearch();
	const tableState = useTableState<StoreEventListingModel, StoreEventFilters>({
		search,
		navigate,
		defaultFilters: defaultFilters,
		defaultSorting: [{ id: 'fullKitDeliveryDate', desc: false }],
		fieldMap: [],
	});

	const [storeEventSearchQuery, plantsQuery] = useQueries({
		queries: [
			storeEventsOdataQueryOptions({
				pagination: tableState.state.pagination,
				filters: tableState.currentFilterData,
			}),
			plantQueryOptions.plants,
		],
	});

	async function handleSuccessfulChange(message: string) {
		await queryClient.invalidateQueries({
			queryKey: storeEventQueryKeys.all,
		});

		notifications.show({
			message: message,
			icon: <Success />,
			color: 'green',
			autoClose: 5000,
		});
	}
	function showErrorNotification(error: Error) {
		if (!(error instanceof ServerValidationError)) {
			log.error(error);
			notifications.show({
				message: t('common:error.generic'),
				icon: <ErrorIcon />,
				color: 'red',
				autoClose: 5000,
			});
		}
	}

	function handleShowLog(storeEventId: Guid) {
		modals.open({
			children: <StoreEventAuditLog storeEventId={storeEventId} />,
			size: '95%',
		});
	}

	const cancelStoreEventMutation = useMutation({
		mutationFn: cancelStoreEvent,
		onSuccess: async () => {
			await handleSuccessfulChange(t('storeEvents.cancelModal.success'));
		},
		onError: showErrorNotification,
	});

	const archiveStoreEventMutation = useMutation({
		mutationFn: archiveStoreEvent,
		onSuccess: async () => {
			await handleSuccessfulChange(t('storeEvents.archiveModal.success'));
		},
		onError: showErrorNotification,
	});

	const handleArchiveClick = useCallback(
		(storeEventId: string) => {
			if (!archiveStoreEventMutation) {
				return;
			}
			modals.openConfirmModal({
				title: t('storeEvents.archiveModal.title'),
				children: <Text size='sm'>{t('storeEvents.archiveModal.message')}</Text>,
				labels: {
					confirm: t('common:buttons.confirm'),
					cancel: t('common:buttons.cancel'),
				},
				onConfirm: () => {
					archiveStoreEventMutation.mutate(storeEventId);
				},
			});
		},
		[archiveStoreEventMutation, t],
	);

	const handleCancelClick = useCallback(
		(storeEventId: string) => {
			if (!cancelStoreEventMutation) {
				return;
			}
			modals.openConfirmModal({
				title: t('storeEvents.cancelModal.title'),
				children: <Text size='sm'>{t('storeEvents.cancelModal.message')}</Text>,
				labels: {
					confirm: t('common:buttons.confirm'),
					cancel: t('common:buttons.cancel'),
				},
				onConfirm: () => {
					cancelStoreEventMutation.mutate(storeEventId);
				},
			});
		},
		[cancelStoreEventMutation, t],
	);

	const table = useReactTable<StoreEventListingModel>({
		data: storeEventSearchQuery.data.rows,
		rowCount: storeEventSearchQuery.data.totalRowCount,
		...tableState,
		enableFilters: true,
		enableColumnFilters: true,
		enableSorting: true,
		manualFiltering: true,
		manualSorting: true,
		manualPagination: true,
		getCoreRowModel: getCoreRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getExpandedRowModel: getExpandedRowModel(),
		getFacetedRowModel: getFacetedRowModel(),
		getFacetedUniqueValues: (table, columnId) => {
			switch (columnId) {
				case 'warehouseCode':
					return () => new Map(plantsQuery.data?.map(x => [x.warehouseCode, 1]));
				case 'type':
					return () => new Map(storeEventTypeSchema._def.values.map(x => [x, 1]));
				case 'status':
					return () => new Map(storeEventStatusSchema._def.values.map(x => [x, 1]));
				default:
					return getFacetedUniqueValues<StoreEventListingModel>()(table, columnId);
			}
		},
		columnResizeMode: 'onChange',
		columns: [
			createFilterOnlyColumn<StoreEventListingModel, StoreEventFilters>({
				group: t('storeEvents.filterGroups.details'),
				label: t('storeEvents.filters.plant'),
				field: 'warehouseCode',
				variant: 'select',
				getFilterDisplayValue: value =>
					plantsQuery.data?.find(x => x.warehouseCode === value)?.name ?? String(value),
			}),
			{
				id: 'storeName',
				enableColumnFilter: false,
				enableSorting: false,
				accessorKey: 'storeName',
				header: t('storeEvents.fields.storeName'),
				size: 50,
				filter: {
					group: t('storeEvents.filterGroups.details'),
					variant: 'select',
				},
				cell: ({ row }) => (
					<Link
						to='/store-events/$storeEventId/details'
						params={{ storeEventId: row.original.id }}
					>
						<Text size='sm'>{row.original.storeName}</Text>
					</Link>
				),
			},
			{
				accessorKey: 'thirdPartyCustomerId',
				enableColumnFilter: true,
				enableSorting: false,
				header: t('storeEvents.fields.storeId'),
				size: 50,
				filter: {
					group: t('storeEvents.filterGroups.details'),
					variant: 'text',
				},
			},
			{
				accessorKey: 'type',
				enableSorting: false,
				header: t('storeEvents.fields.type'),
				size: 50,
				cell: ({ row }) =>
					row.original.type && <StoreEventTypeText value={row.original.type} />,
				enableColumnFilter: true,
				filter: {
					group: t('storeEvents.filterGroups.details'),
					variant: 'select',
					getFilterDisplayValue: value => t(`eventType.${camelCase(value as string)}`),
				},
			},
			{
				accessorKey: 'status',
				enableSorting: false,
				header: t('storeEvents.fields.status'),
				size: 50,
				cell: ({ row }) => <StoreEventStatusText value={row.original.status} />,
				enableColumnFilter: true,
				filter: {
					group: t('storeEvents.filterGroups.details'),
					variant: 'select',
					getFilterDisplayValue: value => t(`eventStatus.${camelCase(value as string)}`),
				},
			},
			{
				accessorKey: 'lastEditDate',
				enableSorting: false,
				header: t('storeEvents.fields.lastEditDate'),
				size: 30,
				cell: ({ row }) => <Text>{row.original.updatedDate}</Text>,
				enableColumnFilter: false,
			},
			{
				accessorKey: 'fullKitShipDate',
				enableSorting: false,
				header: t('storeEvents.fields.shipDate'),
				size: 30,
				enableColumnFilter: false,
			},
			{
				accessorKey: 'fullKitDeliveryDate',
				enableSorting: false,
				header: t('storeEvents.fields.offloadDate'),
				size: 30,
				cell: ({ row }) => <Text>{row.original.fullKitDeliveryDate}</Text>,
				enableColumnFilter: false,
			},
			{
				enableSorting: false,
				header: t('storeEvents.fields.action'),
				size: 20,
				cell: ({ row }) => {
					const { id, hasAuditLogEntries } = row.original;
					const hasWritePermission = auth.hasPermission(
						'AppleRetail.Security.Features.StoreEventManagement.Write',
					);

					return (
						<Group wrap='nowrap'>
							{hasWritePermission && row.original.status === 'FullKitDelivered' && (
								<Button
									variant='transparent'
									onClick={() => handleArchiveClick(id)}
								>
									<ArchiveIcon />
								</Button>
							)}
							{hasAuditLogEntries && (
								<Button variant='transparent' onClick={() => handleShowLog(id)}>
									<ActivityLogIcon />
								</Button>
							)}
							{hasWritePermission &&
								(row.original.status === 'NewEvent' ||
									row.original.status === 'Saved' ||
									row.original.status === 'Complete') && (
									<Button
										variant='transparent'
										onClick={() => handleCancelClick(id)}
									>
										<ClearIcon c='red' />
									</Button>
								)}

							{/* // TODO: Uncomment when shipment report is available
							{row.original.status === StoreEventStatus.FullKitDelivered && (
								<Button
									variant='transparent'
									onClick={() => handleShowReportClick(id)}
								>
									<icons.ChartReportIcon />
								</Button>
							)} */}
						</Group>
					);
				},
				enableColumnFilter: false,
			},
			createFilterOnlyColumn<StoreEventListingModel, StoreEventFilters>({
				group: t('storeEvents.filterGroups.details'),
				label: t('storeEvents.filters.hasLockedItems'),
				field: 'hasLongPendingLockedItems',
				variant: 'switch',
			}),
		],
	});

	return (
		<TableLayout
			title={t('storeEvents.title')}
			table={table}
			toolbarButtons={
				auth.hasPermission('AppleRetail.Security.Features.StoreEventManagement.Write')
					? [
							<ToolbarButton
								key='add'
								tooltip={t('common:buttons.create')}
								icon={AddIcon}
								onClick={() => {
									void navigate({ to: '/store-events/new' });
								}}
							/>,
						]
					: undefined
			}
		>
			<DataTable table={table} loading={storeEventSearchQuery.isFetching} />
		</TableLayout>
	);
}
