import { createFileRoute } from '@tanstack/react-router';

import { QuantitySelectionStep } from '@apple/features/cart-retail/components/steps/quantities';

export const Route = createFileRoute('/_authed/cart/quantities')({
	component: RouteComponent,
});

function RouteComponent() {
	return <QuantitySelectionStep />;
}
