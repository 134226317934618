import { useEffect, useMemo } from 'react';
import { Group, Select, Stack, Title } from '@mantine/core';

import { getBudgetSummaryExportUrl } from '@apple/features/store/api/budget';
import { useTranslation } from '@apple/lib/i18next';
import { ToolbarExportButton } from '@apple/utils/files';

import { useBudget } from '../hooks/useBudget';
import { BudgetSummary } from './BudgetSummary';

export interface StoreBudgetReportProps {
	storeCode: string;
	storeName: string;
}

export function StoreBudgetReport({ storeCode, storeName }: StoreBudgetReportProps) {
	const { t } = useTranslation('store');
	const {
		currentFiscalPeriod,
		selectedFiscalYear,
		setSelectedFiscalYear,
		selectedPeriodNumber,
		setSelectedPeriodNumber,
		budgetData,
		hasData,
	} = useBudget(storeCode);

	const fiscalYearOptions = [
		{
			value: currentFiscalPeriod.fiscalYear.toString(),
			label: t('budgets.storeBudgetReport.fiscalYearOption', {
				fiscalYear: currentFiscalPeriod.fiscalYear.toString(),
			}),
		},
		{
			value: (Number(currentFiscalPeriod.fiscalYear) - 1).toString(),
			label: t('budgets.storeBudgetReport.fiscalYearOption', {
				fiscalYear: (Number(currentFiscalPeriod.fiscalYear) - 1).toString(),
			}),
		},
	];

	const periodOptions = useMemo(() => {
		return hasData
			? budgetData.map(x => ({
					value: x.periodNumber.toString(),
					label: x.fiscalPeriod?.periodName ?? '',
				}))
			: [{ value: '', label: t('common:pagination:empty') }];
	}, [hasData, budgetData, t]);

	useEffect(() => {
		if (currentFiscalPeriod.fiscalYear == Number(selectedFiscalYear)) {
			setSelectedPeriodNumber(currentFiscalPeriod.periodNumber.toString());
			return;
		}
		setSelectedPeriodNumber(periodOptions[0]?.value ?? '');
	}, [currentFiscalPeriod, periodOptions, selectedFiscalYear, setSelectedPeriodNumber]);

	return (
		<Stack mt='xs'>
			<Title order={3}>{storeName}</Title>
			<Group justify='space-between' wrap='nowrap'>
				<Group align='flex-start' justify='initial'>
					<Select
						allowDeselect={false}
						value={selectedFiscalYear}
						data={fiscalYearOptions}
						onChange={value => setSelectedFiscalYear(value)}
					/>
					<Select
						value={selectedPeriodNumber}
						data={periodOptions}
						onChange={value => setSelectedPeriodNumber(value)}
					/>
				</Group>
				<Group px='lg' py='xs'>
					<ToolbarExportButton
						data-testid='download-budget-detail'
						key='budget-details'
						tooltip={t('budgets.budgetSummary.tooltip')}
						exportUrl={getBudgetSummaryExportUrl(storeCode, Number(selectedFiscalYear))}
						fallbackFilename='BudgetTransactionReport.xlsx'
						filters={budgetData}
					/>
				</Group>
			</Group>
			<BudgetSummary
				fiscalYear={Number(selectedFiscalYear)}
				periodNumber={Number(selectedPeriodNumber)}
				singleStoreCustomerCode={storeCode}
				budgetItem={budgetData.find(
					x =>
						x.fiscalPeriod?.fiscalYear === Number(selectedFiscalYear) &&
						x.fiscalPeriod.periodNumber === Number(selectedPeriodNumber),
				)}
			/>
		</Stack>
	);
}
