import { keepPreviousData, queryOptions } from '@tanstack/react-query';

import { getProfile } from '../api/authentication';

export const profileQueryOptions = (enabled = true) =>
	queryOptions({
		queryKey: ['profile'],
		queryFn: () => getProfile(),
		refetchOnMount: true,
		// Prevents page flicker when navigating since this is used in `beforeLoad`
		placeholderData: keepPreviousData,
		enabled: enabled,
	});
