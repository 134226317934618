import { Text } from '@mantine/core';

import { DefaultPieChart } from '@apple/ui/charts/DefaultPieChart';

import type { BudgetDetailsItem } from '../models/budget';

export interface BudgetDetailsTableProps {
	budgetDetailData: BudgetDetailsItem[];
	isLoading: boolean;
	isFetching: boolean;
}

export function BudgetPieChart({
	budgetDetailData,
	isLoading,
	isFetching,
}: BudgetDetailsTableProps) {
	const pieChartData =
		!isLoading && !isFetching
			? budgetDetailData.map((x, _index) => ({
					name: x.categoryName,
					value: x.percentOfTotalBudget,
					tooltip: <Text>{`${x.amountSpent} - ${x.formattedPercentOfTotalBudget}`}</Text>,
				}))
			: [];

	return (
		<DefaultPieChart
			withTooltip
			tooltipDataSource='segment'
			size={350}
			data={pieChartData}
			withLabels
			labelsType='percent'
			labelsPosition='inside'
		/>
	);
}
