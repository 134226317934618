import { PieChart as MantinePieChart } from '@mantine/charts';
import { Paper, Text, useMantineTheme } from '@mantine/core';
import { Legend, ResponsiveContainer, Tooltip } from 'recharts';
import type { PieChartProps } from '@mantine/charts';
import type { ReactNode } from 'react';
import type { PieProps, TooltipProps } from 'recharts';
import type { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';

interface BaseChartData {
	name: string;
	value: number;
	tooltip?: ReactNode;
}

interface DefaultPieChartProps<T extends BaseChartData> extends Omit<PieChartProps, 'data'> {
	data: T[];
}

const CustomTooltip = ({ active, payload }: TooltipProps<ValueType, NameType>) => {
	if (!active || !payload || payload.length === 0) return null;

	const data = payload[0]?.payload as BaseChartData;
	return (
		<Paper shadow='md' p='xs' radius='md' withBorder>
			<Text component='div' size='sm' fw={500}>
				{data?.name}
			</Text>
			<Text component='div' size='sm' c='dimmed'>
				{data.tooltip ?? data.value}
			</Text>
		</Paper>
	);
};

export function DefaultPieChart<T extends BaseChartData>({
	data,
	withTooltip,
	...props
}: DefaultPieChartProps<T>) {
	const theme = useMantineTheme();

	const baseColors = [
		theme.colors.blue,
		theme.colors.green,
		theme.colors.cyan,
		theme.colors.indigo,
		theme.colors.teal,
		theme.colors.orange,
		theme.colors.pink,
		theme.colors.red,
		theme.colors.yellow,
	];
	const shades = [3, 4, 5, 6, 7];
	const pieChartColors = shades.flatMap(shade => baseColors.map(color => color[shade]));

	const formattedData = data.map((item, index) => ({
		...item,
		color:
			pieChartColors[index % pieChartColors.length] ||
			'var(--mantine-primary-color-contrast)',
	}));

	const pieChartprops: PieProps = {
		dataKey: 'value',
		isAnimationActive: true,
		animationDuration: 1000,
	};
	return (
		<ResponsiveContainer width='100%' height={450}>
			<MantinePieChart data={formattedData} {...props} pieProps={pieChartprops}>
				<Legend />
				{withTooltip && <Tooltip content={<CustomTooltip />} />}
			</MantinePieChart>
		</ResponsiveContainer>
	);
}
