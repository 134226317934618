import { createFileRoute, redirect } from '@tanstack/react-router';
import log from 'loglevel';
import { z } from 'zod';

import { LoginPage } from '@apple/features/auth/pages/LoginPage';
import { useTranslation } from '@apple/lib/i18next';
import { Link } from '@apple/ui/link';
import { getEnv } from '@apple/utils/config/env';

const { APPLE_APP_TITLE } = getEnv();

export const Route = createFileRoute('/(public)/login')({
	validateSearch: z.object({
		returnUrl: z.string().optional(),
	}),
	beforeLoad: ({ context, search }) => {
		// If the user is already authenticated, redirect them instead of loading the login page
		if (context.auth.authenticated) {
			const returnUrl = search.returnUrl ?? '/';
			log.debug('Already authenticated, redirecting to return URL:', returnUrl, { context });
			throw redirect({
				to: returnUrl,
			});
		}
	},
	component: Login,
});

function Login() {
	const returnUrl = Route.useSearch({ select: x => x.returnUrl });
	const { auth } = Route.useRouteContext();
	const { t } = useTranslation('auth');

	const renderActionLinks = () => (
		<Link to='/forgot-password' size='sm' children={t('links.forgotPassword')} />
	);

	return (
		<LoginPage
			appName={APPLE_APP_TITLE}
			returnUrl={returnUrl}
			onLogin={auth.login}
			renderActionLinks={renderActionLinks}
		/>
	);
}
