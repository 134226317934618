import { ZodIssueCode, z } from 'zod';

import { storeEventCategorySizeSchema } from '@apple/features/store-events/models/categories';
import type { StoreEventCategorySize } from '@apple/features/store-events/models/categories';
import { storeEventTypeSchema } from '@apple/features/store-events/models/shared';
import type { StoreEventType } from '@apple/features/store-events/models/shared';
import { countryCodeSchema, dateTimeSchema } from '@apple/utils/globalization/models';
import type { CountryCode } from '@apple/utils/globalization/models';

export type StoreEventRuleType = 'global' | 'plant' | 'country' | 'market';

export interface StoreEventProductRule {
	itemId: string;
	storeEventCategoryId: string;
	categorySize: StoreEventCategorySize | null;
	eventType: StoreEventType;
	defaultQuantity: number;
	updatedDate?: string;
}

/**
 * @see AppleRetail.Data.StoreEvents.StoreEventCategoryProductGlobalAssociation
 */
export interface GlobalStoreEventRule extends StoreEventProductRule {}

/**
 * @see AppleRetail.Data.StoreEvents.StoreEventCategoryProductPlantAssociation
 */
export interface PlantStoreEventRule extends StoreEventProductRule {
	warehouseLocationId: number;
}

/**
 * @see AppleRetail.Data.StoreEvents.StoreEventCategoryProductCountryAssociation
 */
export interface CountryStoreEventRule extends StoreEventProductRule {
	countryCode: CountryCode;
}

/**
 * @see AppleRetail.Data.StoreEvents.StoreEventCategoryProductCustomerGroupAssociation
 */
export interface MarketStoreEventRule extends StoreEventProductRule {
	customerGroupId: number;
}

/**
 * @see AppleRetail.Data.StoreEvents.StoreEventProductConfiguration
 */
export interface StoreEventProductConfiguration {
	itemId: string;
	isEnabled: boolean;
	updatedDate?: Date | null;
	globalAssociations: GlobalStoreEventRule[];
	plantAssociations: PlantStoreEventRule[];
	countryAssociations: CountryStoreEventRule[];
	customerGroupAssociations: MarketStoreEventRule[];
}

/**
 * Used in the form to create store event rules for a product
 */
export interface StoreEventRule {
	eventType: StoreEventType;
	category: string;
	categorySizeRequired: boolean;
	categorySize: StoreEventCategorySize | null;
	accessType: StoreEventRuleType;
	configuration: string | null;
	quantity: number;
}

export const storeEventRuleTypeSchema = z.enum([
	'global',
	'plant',
	'country',
	'market',
]) satisfies z.ZodType<StoreEventRuleType>;

export const storeEventProductRuleSchema = z.object({
	itemId: z.string(),
	storeEventCategoryId: z.string(),
	categorySize: storeEventCategorySizeSchema.nullable(),
	eventType: storeEventTypeSchema,
	defaultQuantity: z.number(),
	updatedDate: dateTimeSchema.nullable().optional(),
});

/**
 * @see AppleRetail.Data.StoreEvents.StoreEventCategoryProductGlobalAssociation
 */
export const globalStoreEventRuleSchema =
	storeEventProductRuleSchema as z.ZodType<GlobalStoreEventRule>;

/**
 * @see AppleRetail.Data.StoreEvents.StoreEventCategoryProductPlantAssociation
 */
export const plantStoreEventRuleSchema = storeEventProductRuleSchema.extend({
	warehouseLocationId: z.number(),
}) as z.ZodType<PlantStoreEventRule>;

/**
 * @see AppleRetail.Data.StoreEvents.StoreEventCategoryProductCountryAssociation
 */
export const countryStoreEventRuleSchema = storeEventProductRuleSchema.extend({
	countryCode: countryCodeSchema,
}) as z.ZodType<CountryStoreEventRule>;

/**
 * @see AppleRetail.Data.StoreEvents.StoreEventCategoryProductCustomerGroupAssociation
 */
export const marketStoreEventRuleSchema = storeEventProductRuleSchema.extend({
	customerGroupId: z.number(),
}) as z.ZodType<MarketStoreEventRule>;

/**
 * @see AppleRetail.Data.StoreEvents.StoreEventProductConfiguration
 */
export const storeEventProductConfigurationSchema = z.object({
	itemId: z.string(),
	isEnabled: z.boolean(),
	updatedDate: dateTimeSchema.nullable().optional(),
	globalAssociations: z.array(globalStoreEventRuleSchema),
	plantAssociations: z.array(plantStoreEventRuleSchema),
	countryAssociations: z.array(countryStoreEventRuleSchema),
	customerGroupAssociations: z.array(marketStoreEventRuleSchema),
}) as z.ZodType<StoreEventProductConfiguration>;

/**
 * Used in the form to create store event rules for a product
 */
export const storeEventRuleSchema = (categorySizeMessage: string, configurationMessage: string) =>
	z
		.object({
			eventType: storeEventTypeSchema,
			category: z.string().min(1),
			categorySizeRequired: z.boolean(),
			categorySize: storeEventCategorySizeSchema.nullable(),
			accessType: storeEventRuleTypeSchema,
			configuration: z.string().nullable(),
			quantity: z.coerce.number().min(1),
		})
		.superRefine((data, ctx) => {
			if (data.categorySizeRequired && (data.categorySize ?? '') === '') {
				ctx.addIssue({
					code: ZodIssueCode.custom,
					message: categorySizeMessage,
					path: ['categorySize'],
				});
			}
			if (data.accessType !== 'global' && (data.configuration ?? '') === '') {
				ctx.addIssue({
					code: ZodIssueCode.custom,
					message: configurationMessage,
					path: ['configuration'],
				});
			}
		}) satisfies z.ZodType<StoreEventRule>;

/** Determines if two rules are equivalent. */
export function compareFormToRule<T extends StoreEventProductRule>(
	a: T,
	b: StoreEventRule,
	configField?: keyof T,
): boolean {
	return (
		a.storeEventCategoryId === b.category &&
		a.categorySize === (b.categorySize ?? 'All') &&
		a.eventType === b.eventType &&
		(configField === undefined || a[configField]?.toString() === (b.configuration as unknown))
	);
}

export function compareRules<T extends StoreEventProductRule>(
	a: T,
	b: T,
	configField?: keyof T,
): boolean {
	return (
		a.storeEventCategoryId === b.storeEventCategoryId &&
		a.categorySize === b.categorySize &&
		a.eventType === b.eventType &&
		(configField === undefined || a[configField]?.toString() === (b[configField] as unknown))
	);
}

export function compareRulesByType(a: StoreEventProductRule, b: StoreEventProductRule): boolean {
	if (isPlantRule(a) && isPlantRule(b)) {
		return compareRules(a, b, 'warehouseLocationId');
	}
	if (isCountryRule(a) && isCountryRule(b)) {
		return compareRules(a, b, 'countryCode');
	}
	if (isMarketRule(a) && isMarketRule(b)) {
		return compareRules(a, b, 'customerGroupId');
	}
	return compareRules(a, b);
}

export function isPlantRule(rule: StoreEventProductRule): rule is PlantStoreEventRule {
	return plantStoreEventRuleSchema.safeParse(rule).success;
}

export function isCountryRule(rule: StoreEventProductRule): rule is CountryStoreEventRule {
	return countryStoreEventRuleSchema.safeParse(rule).success;
}

export function isMarketRule(rule: StoreEventProductRule): rule is MarketStoreEventRule {
	return marketStoreEventRuleSchema.safeParse(rule).success;
}

export function isGlobalRule(rule: StoreEventProductRule): rule is GlobalStoreEventRule {
	return (
		!isPlantRule(rule) &&
		!isCountryRule(rule) &&
		!isMarketRule(rule) &&
		globalStoreEventRuleSchema.safeParse(rule).success
	);
}
