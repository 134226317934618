import { Container, Title } from '@mantine/core';
import { createFileRoute, redirect } from '@tanstack/react-router';

import { requireAuth } from '@apple/features/auth';
import { StoreBudgetReport } from '@apple/features/store/components/StoreBudgetReport';
import { useRetailStore } from '@apple/features/store/hooks/useRetailStore';
import { useTranslation } from '@apple/lib/i18next';

export const Route = createFileRoute('/_authed/store/budget')({
	beforeLoad: args => {
		if (!args.context.auth.profile?.singleStoreAccess) {
			throw redirect({
				to: '/not-found',
			});
		}
		requireAuth(args);
	},
	component: BudgetReportRoute,
});

export function BudgetReportRoute() {
	const { t } = useTranslation('store');

	const { auth } = Route.useRouteContext();
	const store = useRetailStore(auth.profile?.singleStoreCustomerCode ?? '');
	const storeData = store.storeQuery.data;

	return (
		<Container fluid mt='md'>
			<Title order={1}>{t('budgets.storeBudgetReport.title')}</Title>
			<StoreBudgetReport
				storeCode={storeData.arvatoCustomerCode}
				storeName={storeData.storeName}
			/>
		</Container>
	);
}
