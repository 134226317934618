import { createFileRoute } from '@tanstack/react-router';

import { requireAuth } from '@apple/features/auth';
import { FulfillmentForm } from '@apple/features/fulfillment/components/FulfillmentForm';
import { useTranslation } from '@apple/lib/i18next';
import { DetailsLayout } from '@apple/ui/layouts';

export const Route = createFileRoute('/_authed/fulfillment_/add')({
	beforeLoad: ({ ...args }) => {
		requireAuth(args, {
			requirePermission: 'Manzanita.Security.Features.FulfillmentRuleManagement.Write',
		});
	},
	wrapInSuspense: true,
	component: FulfillmentRulesPageRoute,
});

function FulfillmentRulesPageRoute() {
	const { t } = useTranslation('fulfillment');
	return (
		<DetailsLayout title={t('title')}>
			<FulfillmentForm />
		</DetailsLayout>
	);
}
