import { z } from 'zod';

import { dateTimeSchema } from '@apple/utils/globalization';

/** @see AppleRetail.Web.Features.Events.AppleRetailActivityLogSearchController.SearchFilter */
export interface ActivityLogFilters {
	startDate?: Date | string | null;
	endDate?: Date | string | null;
	/** Even though this property is called userId, it doesn't filter by the user's ID. It filters by email address or username. */
	userId?: string;
	role?: string;
	entity?: ActivityLogEntityType | '';
	event?: string;
}

/** @see AppleRetail.Web.Features.Events.AppleRetailActivityLogSearchController.EntityType */
export type ActivityLogEntityType =
	| 'User'
	| 'Product'
	| 'StoreEvent'
	| 'Store'
	| 'Order'
	| 'Budget'
	| 'FulfillmentRule';

/** @see AppleRetail.Web.Features.Events.AppleRetailActivityLogSearchController.ActivityLogListItem */
export interface ActivityLogListing {
	correlationId: string;
	entityType: ActivityLogEntityType;
	eventType: string;
	updatedBy: number;
	description: string;
	dateCreated: Date | string;
	updatedByName: string | null;
	updatedByRole: string | null;
	updates: number;
}

export const activityLogEntityTypeSchema = z.enum([
	'User',
	'Product',
	'StoreEvent',
	'Store',
	'Order',
	'Budget',
	'FulfillmentRule',
]) satisfies z.ZodType<ActivityLogEntityType>;

export const eventTypeSchemas = {
	User: z.enum([
		'LoggedIn',
		'Created',
		'Updated',
		'Unlocked',
		'Deleted',
		'LoggedOut',
		'Locked',
		'ResetPasswordRequest',
		'PasswordChanged',
		'AccessChanged',
	]),
	Product: z.enum(['Created', 'Updated', 'Deleted']),
	Store: z.enum(['Created', 'Updated', 'Deleted']),
	Order: z.enum(['Created', 'Updated', 'Approved', 'Rejected', 'Shipped', 'LineAdded']),
	Budget: z.enum(['BudgetCreated', 'BudgetAllocatedUpdate', 'BudgetDeleted']),
	StoreEvent: z.enum([
		'StoreEventCategorySelectionChanged',
		'StoreEventCompleted',
		'StoreEventCreated',
		'StoreEventDetailChanged',
		'StoreEventItemChanged',
		'StoreEventSaved',
	]),
	FulfillmentRule: z.enum(['Created', 'Deleted']),
	// biome-ignore lint/suspicious/noExplicitAny: generics
} as const satisfies Record<ActivityLogEntityType, z.ZodEnum<any>>;

export const activityLogFilterSchema = z
	.object({
		startDate: dateTimeSchema.nullable(),
		endDate: dateTimeSchema.nullable(),
		userId: z.string(),
		role: z.string(),
		entity: z.union([activityLogEntityTypeSchema, z.literal('')]),
		event: z.string(),
	})
	.partial() satisfies z.ZodType<ActivityLogFilters>;

export const activityLogListingSchema = z.object({
	correlationId: z.string(),
	entityType: activityLogEntityTypeSchema,
	eventType: z.string(),
	updatedBy: z.number(),
	description: z.string(),
	dateCreated: dateTimeSchema,
	updatedByName: z
		.string()
		.nullable()
		// Some of the data in the database is an empty string, which should be treated as null
		.transform(value => (value === '' ? null : value)),
	updatedByRole: z.string().nullable(),
	updates: z.number(),
}) satisfies z.ZodType<ActivityLogListing>;
