import { useCallback } from 'react';
import { Box, Button, Card, Group, rem, Stack, Text } from '@mantine/core';
import { modals } from '@mantine/modals';
import { useNavigate } from '@tanstack/react-router';
import { getLogger } from 'loglevel';

import { icons } from '@apple/assets';
import { useCartIdContext } from '@apple/features/cart/contexts/cart-id';
import { useTranslation } from '@apple/lib/i18next';
import { MenuPopover } from '@apple/ui/shell/components/MenuPopover';
import { useLogger } from '@apple/utils/logging/useLogger';

import { useCartItemCount } from '../hooks/useCartItemCount';
import { useResetCart } from '../hooks/useResetCart';

const log = getLogger('cart-menu');

export function CartMenu() {
	const { t } = useTranslation('shop');
	const navigate = useNavigate();
	const cartId = useCartIdContext();
	const resetCart = useResetCart();
	const cartItemCount = useCartItemCount();

	useLogger({
		name: 'CartMenu',
		props: [
			{
				cartId,
				cartItemCount,
			},
		],
		log,
	});

	const handleClearCart = useCallback(() => {
		if (cartItemCount.isSuccess && cartItemCount.data === 0) {
			resetCart.mutate(undefined, {
				onSuccess: () => void navigate({ to: '/cart' }),
			});
			return;
		}

		modals.openConfirmModal({
			modalId: 'clear-cart',
			title: t('cart.clearModal.title'),
			children: <Text>{t('cart.clearModal.description')}</Text>,
			labels: {
				confirm: t('cart.clearModal.continue'),
				cancel: t('common:buttons.cancel'),
			},
			onConfirm: () =>
				resetCart.mutate(undefined, {
					onSuccess: () => void navigate({ to: '/cart' }),
				}),
		});
	}, [t, navigate, cartItemCount.data, cartItemCount.isSuccess, resetCart]);

	const handleViewCart = () => {
		return void navigate({ to: '/cart/$step', params: { step: 'shipping' } });
	};

	return (
		<MenuPopover
			icon={icons.ShoppingBag}
			iconTestId='cart-menu'
			loading={cartItemCount.isLoading}
			tooltipLabel={t('cart.labels.cart')}
			indicatorCount={cartItemCount.data}
			indicatorDisabled={cartItemCount.data <= 0}
		>
			<Card.Section
				m={0}
				p='md'
				style={{
					borderRadius: `${rem(24)} ${rem(24)} 0 0`,
				}}
			>
				<Group justify='space-between'>
					<Text fw='bold'>{t('cart.labels.cart')}</Text>
					<Button
						fw='bold'
						onClick={handleClearCart}
						variant='transparent'
						c='red'
						disabled={cartItemCount.data === 0}
					>
						{t('cart.buttons.clearCart')}
					</Button>
				</Group>
			</Card.Section>

			<Card.Section m='xs'>
				<Group justify='center' mt='xs'>
					{cartItemCount.data > 0 ? (
						<Stack>
							<Box>
								<Text>
									{t('cart.labels.itemsCountMessage', {
										itemsCount: cartItemCount.data,
									})}
								</Text>
							</Box>
							<Button w={'14em'} onClick={handleViewCart}>
								{t('cart.buttons.viewCart')}
							</Button>
						</Stack>
					) : (
						<Box mt='xs'>
							<Text>{t('cart.labels.noItemsMessage')}</Text>
						</Box>
					)}
				</Group>
			</Card.Section>
		</MenuPopover>
	);
}
