import { Text } from '@mantine/core';
import { keepPreviousData, queryOptions, useQuery } from '@tanstack/react-query';
import { createFileRoute, Link } from '@tanstack/react-router';
import {
	getCoreRowModel,
	getFacetedMinMaxValues,
	getFacetedRowModel,
	getFacetedUniqueValues,
	getFilteredRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	useReactTable,
} from '@tanstack/react-table';
import type { SortingState } from '@tanstack/react-table';

import { icons } from '@apple/assets';
import { requireAuth } from '@apple/features/auth';
import { getAll } from '@apple/features/calendar/api';
import { useTranslation } from '@apple/lib/i18next';
import { DataTable, useTableState } from '@apple/ui/data-table';
import { ToolbarButton } from '@apple/ui/data-table/controls/Toolbar';
import { TableLayout } from '@apple/ui/layouts';
import { FormattedDate } from '@apple/utils/globalization/index';
import type { FiscalCalendarYear } from '@apple/features/calendar/models';

export const Route = createFileRoute('/_authed/_admin/manage/calendars')({
	beforeLoad: args => {
		requireAuth(args, {
			requirePermission: 'Manzanita.Security.Features.Snapshot',
		});
		return {
			calendarQueryOptions: queryOptions({
				queryKey: ['fiscal-calendar'],
				queryFn: () => getAll(),
				placeholderData: keepPreviousData,
				throwOnError: true,
				initialData: [],
			}),
		};
	},
	loader: ({ context: { queryClient, calendarQueryOptions } }) =>
		void queryClient.prefetchQuery(calendarQueryOptions),
	component: CalendarPageRoute,
});

const defaultSorting: SortingState = [{ id: 'fiscalYear', desc: false }];

function CalendarPageRoute() {
	const { t } = useTranslation('manage');
	const navigate = Route.useNavigate();
	const search = Route.useSearch();
	const { auth } = Route.useRouteContext();
	const { calendarQueryOptions } = Route.useRouteContext();
	const calendarQuery = useQuery(calendarQueryOptions);

	const tableState = useTableState<FiscalCalendarYear, unknown>({
		search,
		navigate,
		defaultFilters: {},
		defaultSorting: defaultSorting,
		fieldMap: [],
	});

	const table = useReactTable<FiscalCalendarYear>({
		data: calendarQuery.data,
		...tableState,
		getCoreRowModel: getCoreRowModel(),
		getFacetedMinMaxValues: getFacetedMinMaxValues(),
		getFacetedUniqueValues: getFacetedUniqueValues(),
		getFacetedRowModel: getFacetedRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getSortedRowModel: getSortedRowModel(),
		enableFilters: false,
		enableColumnFilters: false,
		manualFiltering: false,
		enableSorting: false,
		columnResizeMode: 'onChange',
		columns: [
			{
				accessorKey: 'fiscalYear',
				header: t('calendars.headers.fiscalYear'),
				size: 180,
				cell: ({ row }) =>
					auth.hasPermission('AppleRetail.Security.Features.CalendarManagement') ? (
						<Link
							to='/manage/calendars/$fiscalYear'
							params={{ fiscalYear: row.original.fiscalYear }}
							children={row.original.fiscalYear}
						/>
					) : (
						<Text>{row.original.fiscalYear}</Text>
					),
			},
			{
				accessorKey: 'startDate',
				header: t('calendars.headers.startDate'),
				size: 300,
				cell: ({ row }) => <FormattedDate value={row.original.startDate} />,
			},
			{
				accessorKey: 'endDate',
				header: t('calendars.headers.endDate'),
				size: 300,
				cell: ({ row }) => <FormattedDate value={row.original.endDate} />,
			},
		],
	});

	return (
		<TableLayout
			table={table}
			title={t('calendars.title')}
			toolbarButtons={[
				auth.hasPermission('AppleRetail.Security.Features.CalendarManagement') && (
					<ToolbarButton
						key='add'
						tooltip={t('calendars.add.title')}
						icon={icons.AddIcon}
						onClick={() =>
							void navigate({
								to: '/manage/calendars/add',
								viewTransition: false,
							})
						}
					/>
				),
			]}
		>
			<DataTable table={table} loading={calendarQuery.isFetching} />
		</TableLayout>
	);
}
