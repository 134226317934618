import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';

import { ConfirmationStep } from '@apple/features/cart-retail/components/steps/confirmation';

const schema = z.object({
	salesOrderNumber: z.string(),
});

export const Route = createFileRoute('/_authed/cart/confirmation/$salesOrderNumber')({
	params: {
		parse: x => schema.parse(x),
		stringify: ({ salesOrderNumber }) => ({ salesOrderNumber }),
	},
	component: RouteComponent,
});

function RouteComponent() {
	const { salesOrderNumber } = Route.useParams();
	return <ConfirmationStep salesOrderNumber={salesOrderNumber} />;
}
