import { requireAuth } from '@apple/features/auth';
import { StepLayout } from '@apple/features/cart/layouts/step';
import { createStoreEvent } from '@apple/features/store-events/api/management';
import { StoreEventDetails } from '@apple/features/store-events/components/details';
import {
	DEFAULT_STORE_EVENT_UPDATE_MODEL,
	type StoreEventUpdateModel,
	storeEventUpdateModelSchema,
} from '@apple/features/store-events/models/management';
import { storeEventQueries, storeEventQueryKeys } from '@apple/features/store-events/queries';
import type { StoreListingModel } from '@apple/features/store/models';
import { useTranslation } from '@apple/lib/i18next';
import { BlockNavigation } from '@apple/ui/form/components/BlockNavigation';
import { ServerValidatedZodForm } from '@apple/ui/form/components/ValidatedForm/ServerValidatedZodForm';
import { useMutation, useSuspenseQuery } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';
import { useState } from 'react';

export const Route = createFileRoute('/_authed/store-events/_steps/new')({
	beforeLoad: args =>
		requireAuth(args, {
			requirePermission: 'AppleRetail.Security.Features.StoreEventManagement.Write',
		}),
	component: RouteComponent,
});

function RouteComponent() {
	const { t } = useTranslation();
	const navigate = Route.useNavigate();
	const { queryClient } = Route.useRouteContext();
	const [selectedStore, setSelectedStore] = useState<StoreListingModel>();

	const storeIdsForExistingQuery = useSuspenseQuery(
		storeEventQueries.getStoreIdsForExistingEvents(),
	);

	const createMutation = useMutation({
		mutationFn: async (value: StoreEventUpdateModel) => {
			if (!selectedStore) {
				throw new Error('Store is required');
			}

			const storeEvent = await createStoreEvent(selectedStore.thirdPartyId, value);

			await Promise.all([
				queryClient.invalidateQueries({
					queryKey: storeEventQueryKeys.list(),
				}),
				queryClient.invalidateQueries({
					queryKey: storeEventQueryKeys.getStoreIdsForExistingEvents(),
				}),
			]);

			return storeEvent;
		},
		onSuccess: ({ id }) => {
			navigate({
				to: '/store-events/$storeEventId/categories',
				params: {
					storeEventId: id,
				},
			});
		},
	});

	return (
		<ServerValidatedZodForm
			name={'store-event-create'}
			validationType='EditEventDetails'
			schema={storeEventUpdateModelSchema}
			initialValues={DEFAULT_STORE_EVENT_UPDATE_MODEL}
			onValidSubmit={async value => {
				await createMutation.mutateAsync(value);
			}}
			onAdditionalValidation={() => {
				if (!selectedStore) {
					return 'Store is required';
				}
			}}
			ignoreModelErrorParent
		>
			{({ form, submit, isSubmitting }) => (
				<StepLayout
					mutationErrors={[createMutation.error]}
					buttonLeft={{
						disabled: true,
					}}
					buttonRight={{
						text: t('common:buttons.create'),
						color: 'green',
						loading: createMutation.isPending || isSubmitting,
						disabled:
							createMutation.isPending || isSubmitting || selectedStore === undefined,
						onClick: () => {
							submit();
						},
					}}
				>
					<BlockNavigation
						condition={
							// Stop navigation when the form is dirty and not saved
							form.isDirty() &&
							!(createMutation.isPending || createMutation.isSuccess || isSubmitting)
						}
					/>
					<StoreEventDetails
						form={form}
						storeId={selectedStore?.thirdPartyId}
						onStoreAssigned={setSelectedStore}
						excludeStoreIds={storeIdsForExistingQuery.data}
						storeUtcOffsetMinutes={selectedStore?.utcOffsetMinutes ?? 0}
					/>
				</StepLayout>
			)}
		</ServerValidatedZodForm>
	);
}
