import { Button, Code, Stack } from '@mantine/core';
import { isEqual } from 'lodash-es';
import type { Table } from '@tanstack/table-core';

import { FiltersList } from '@apple/ui/data-table/controls/filters-list';

export function TableFilters<TRow>({
	table,
	clearLabel,
	debug,
}: {
	table: Table<TRow>;
	clearLabel: string;
	debug: boolean;
}) {
	const isDirty = !isEqual(table.getState().columnFilters, table.initialState.columnFilters);
	return (
		<Stack h='100%' p={0} m={0} gap={0}>
			<Stack px='xs'>
				<FiltersList headers={table.getFlatHeaders()} />
				<Button disabled={!isDirty} onClick={() => table.resetColumnFilters()}>
					{clearLabel}
				</Button>
				{debug && <Code block>{JSON.stringify(table.getState(), null, 2)}</Code>}
			</Stack>
		</Stack>
	);
}
