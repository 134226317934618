import { createFileRoute, redirect } from '@tanstack/react-router';

export const Route = createFileRoute('/_authed/store-events/_steps/$storeEventId/')({
	beforeLoad: _args => {
		throw redirect({
			to: '/store-events/$storeEventId/details',
			params: {
				storeEventId: _args.params.storeEventId,
			},
		});
	},
});
