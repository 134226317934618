import { Grid, Group, Paper, Stack, Text, Title } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';

import { useTranslation } from '@apple/lib/i18next';
import { FormattedDate } from '@apple/utils/globalization';
import type { FiscalCalendarPeriod } from '@apple/features/calendar/models';

import { budgetQueryOptions } from '../queries/budget';
import { BudgetDetailsTable } from './BudgetDetailsTable';
import { BudgetPieChart } from './BudgetPieChart';

export interface BudgetDetailsProps {
	fiscalYear: number;
	singleStoreCustomerCode: string;
	periodNumber: number;
	fiscalCalendarPeriod?: FiscalCalendarPeriod;
}

export function BudgetDetails({
	fiscalYear,
	singleStoreCustomerCode,
	periodNumber,
	fiscalCalendarPeriod,
}: BudgetDetailsProps) {
	const { t } = useTranslation('common');

	const budgetDetailsQuery = useQuery(
		budgetQueryOptions.budgetDetails(singleStoreCustomerCode, fiscalYear, periodNumber),
	);

	return budgetDetailsQuery.data && (budgetDetailsQuery.data?.length ?? 0) > 0 ? (
		<Stack m={0} gap={1}>
			<Group>
				<Title order={3}> {fiscalCalendarPeriod?.periodName}</Title>
				<Group gap={1}>
					<FormattedDate value={fiscalCalendarPeriod?.startDate} />
					<Text mx={4}>-</Text>
					<FormattedDate value={fiscalCalendarPeriod?.endDate} />
				</Group>
			</Group>

			<Grid>
				<Grid.Col span={{ base: 12, lg: 6 }}>
					<Paper
						mt={'lg'}
						p={'xl'}
						withBorder={(budgetDetailsQuery.data?.length ?? 0) > 0}
					>
						<BudgetPieChart
							budgetDetailData={budgetDetailsQuery.data ?? []}
							isFetching={budgetDetailsQuery.isFetching}
							isLoading={budgetDetailsQuery.isLoading}
						/>
					</Paper>
				</Grid.Col>
				<Grid.Col span={{ base: 12, lg: 6 }}>
					{periodNumber && (budgetDetailsQuery.data?.length ?? 0) > 0 && (
						<Paper mt={'lg'} withBorder={(budgetDetailsQuery.data?.length ?? 0) > 0}>
							<BudgetDetailsTable
								periodNumber={periodNumber}
								singleStoreCustomerCode={singleStoreCustomerCode}
								fiscalYear={fiscalYear}
								budgetDetailData={budgetDetailsQuery.data ?? []}
								isFetching={budgetDetailsQuery.isFetching}
								isLoading={budgetDetailsQuery.isLoading}
							/>
						</Paper>
					)}
				</Grid.Col>
			</Grid>
		</Stack>
	) : (
		<Stack align='center' justify='center'>
			<Title order={3}>{t('pagination.empty')}</Title>
		</Stack>
	);
}
