import { useMemo } from 'react';
import { Text } from '@mantine/core';
import { useQueries } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';
import {
	getCoreRowModel,
	getFacetedMinMaxValues,
	getFacetedUniqueValues,
	useReactTable,
} from '@tanstack/react-table';
import dayjs from 'dayjs';
import { camelCase } from 'lodash-es';
import { z } from 'zod';
import type { SortingState } from '@tanstack/react-table';

import { AddressDisplay } from '@apple/features/address/components/address-display';
import { marketQueries } from '@apple/features/market';
import { getOrderSearchExportUrl } from '@apple/features/order-retail/api/search';
import { orderTypeSchema } from '@apple/features/order-retail/models/type';
import { orderQueries } from '@apple/features/order-retail/queries/order';
import { OrderStatusDisplay } from '@apple/features/order/components/status';
import { orderStatusFilterSchema } from '@apple/features/order/models/status';
import { plantQueryOptions } from '@apple/features/plants/queries/queries';
import { storeQueries } from '@apple/features/store/queries';
import { useTranslation } from '@apple/lib/i18next';
import { createFilterOnlyColumn, DataTable, useTableState } from '@apple/ui/data-table';
import { TableLayout } from '@apple/ui/layouts';
import { Link } from '@apple/ui/link';
import { PopoverText } from '@apple/ui/typogrophy/components/popover-text';
import { ToolbarExportButton } from '@apple/utils/files';
import { FormattedDate, globalizationQueryOptions } from '@apple/utils/globalization';
import type { OrderListing, OrderSearchFilters } from '@apple/features/order-retail/models/search';

const orderSearchParamsSchema = z.object({
	userId: z.string().optional(),
	/** Sales or Purchase Order Number  */
	orderNumber: z.string().optional(),
});
const defaultSorting: SortingState = [{ id: 'orderData', desc: true }];

export const Route = createFileRoute('/_authed/orders/search')({
	// TODO: Create a minimal repro for the route not loading due to this schema...
	// validateSearch: createDataTableSearchSchema<OrderSearchParams>(orderSearchParamsSchema),
	validateSearch: orderSearchParamsSchema,
	beforeLoad: () => {
		return {
			plantQueryOptions,
			countriesWithCulturesQueryOptions: globalizationQueryOptions.countriesWithCultures(),
		};
	},
	loaderDeps: ({ search }) => search,
	loader: ({
		context: { queryClient, plantQueryOptions, countriesWithCulturesQueryOptions },
	}) => {
		void queryClient.prefetchQuery(plantQueryOptions.plants);
		void queryClient.prefetchQuery(countriesWithCulturesQueryOptions);
	},
	component: OrderSearchRoute,
});

const defaultFilters: OrderSearchFilters = {
	orderDateRangeInDays: 60,
	startDate: dayjs().subtract(60, 'days').startOf('day').toDate(),
};

function OrderSearchRoute() {
	const { t } = useTranslation('order');
	const search = Route.useSearch();
	const navigate = Route.useNavigate();
	const { auth } = Route.useRouteContext();

	const tableState = useTableState<OrderListing, OrderSearchFilters>({
		search,
		navigate,
		defaultFilters,
		searchFilters: [
			...(search.orderNumber ? [{ id: 'orderNumber', value: search.orderNumber }] : []),
			...(search.userId ? [{ id: 'userId', value: search.userId }] : []),
		],
		defaultSorting,
		fieldMap: [],
	});

	const { plantQueryOptions, countriesWithCulturesQueryOptions } = Route.useRouteContext();
	const [plantsQuery, countriesQuery, marketsQuery, storesQuery, orderSearchQuery] = useQueries({
		queries: [
			plantQueryOptions.plants,
			countriesWithCulturesQueryOptions,
			marketQueries.getMarkets(),
			storeQueries.accessible(),
			orderQueries.search(tableState.state.pagination, tableState.currentFilterData),
		],
	});

	const plantCodes = useMemo(
		() => plantsQuery.data?.map(x => x.warehouseCode) ?? [],
		[plantsQuery.data],
	);

	const countryCodes = useMemo(
		() => countriesQuery.data?.map(x => x.code) ?? [],
		[countriesQuery.data],
	);

	const table = useReactTable({
		data: orderSearchQuery.data.items,
		rowCount: orderSearchQuery.data.totalCount,
		...tableState,
		enableFilters: true,
		enableColumnFilters: true,
		enableSorting: true,
		manualFiltering: true,
		manualSorting: true,
		manualPagination: true,
		getCoreRowModel: getCoreRowModel(),
		getFacetedMinMaxValues: getFacetedMinMaxValues(),
		getFacetedUniqueValues: (table, columnId) => {
			switch (columnId) {
				case 'warehouseCode':
					return () => new Map(plantCodes.map(x => [x, 1]));
				case 'orderStatus':
					return () => new Map(orderStatusFilterSchema._def.values.map(x => [x, 1]));
				case 'orderReason':
					return () =>
						new Map(
							orderTypeSchema._def.options
								.flatMap(x => x._def.values)
								.map(x => [x, 1]),
						);
				case 'countryCode':
					return () => new Map(countryCodes.map(x => [x, 1]));
				case 'customerGroupId':
					return () => new Map(marketsQuery.data.map(x => [x.id, 1]));
				case 'customerIds':
					return () => new Map(storesQuery.data.map(x => [x.id, 1]));
				case 'orderDateRangeInDays':
					return () =>
						new Map([
							['30', 1],
							['60', 1],
							['90', 1],
							['custom', 1],
						]);
				default:
					return getFacetedUniqueValues<OrderListing>()(table, columnId);
			}
		},
		columnResizeMode: 'onChange',
		columns: [
			createFilterOnlyColumn<OrderListing, OrderSearchFilters>({
				group: t('filters.groups.labels.allFilters'),
				label: t('filters.labels.store'),
				field: 'customerIds',
				variant: 'multi-select',
				getFilterDisplayValue: value =>
					storesQuery.data?.find(x => x.id === value)?.displayString ?? String(value),
			}),
			createFilterOnlyColumn<OrderListing, OrderSearchFilters>({
				group: t('filters.groups.labels.allFilters'),
				label: t('filters.labels.orderType'),
				field: 'orderReason',
				variant: 'select',
				getFilterDisplayValue: value => t(`reason.${camelCase(String(value))}`),
			}),
			{
				header: t('searchResults.table.columns.soNumber'),
				accessorKey: 'salesOrderNumber',
				// `orderNumber` is used to filter both `purchaseOrderNumber` and `salesOrderNumber`
				enableColumnFilter: false,
			},
			{
				id: 'orderNumber',
				accessorKey: 'purchaseOrderNumber',
				header: t('filters.labels.orderNumber'),
				filter: {
					group: t('filters.groups.labels.allFilters'),
				},
				cell: ({ row }) => (
					<Link
						to='/orders/$poNumber'
						params={{ poNumber: row.original.purchaseOrderNumber }}
						title={t('searchResults.table.links.viewOrderDetails')}
					>
						<Text size='sm'>{row.original.purchaseOrderNumber}</Text>
					</Link>
				),
			},
			{
				id: 'orderDate',
				header: t('searchResults.table.columns.orderDate'),
				accessorKey: 'orderDate',
				enableColumnFilter: false,
				cell: ({ row }) => (
					<FormattedDate value={row.original.orderDate} humanize size='sm' />
				),
			},
			createFilterOnlyColumn<OrderListing, OrderSearchFilters>({
				group: t('filters.groups.labels.allFilters'),
				label: t('filters.labels.orderDate'),
				field: 'orderDateRangeInDays',
				variant: 'select',
				getFilterDisplayValue: value =>
					value === 'custom'
						? t('controls:dataTable.filters.options.dateRange.custom')
						: t('controls:dataTable.filters.options.dateRange.lastNumberOfDays', {
								days: value,
							}),
			}),
			createFilterOnlyColumn<OrderListing, OrderSearchFilters>({
				group: t('filters.groups.labels.allFilters'),
				label: t('filters.labels.startDate'),
				field: 'startDate',
				variant: 'date',
				hidden: tableState.currentFilterData.orderDateRangeInDays !== 'custom',
			}),
			createFilterOnlyColumn<OrderListing, OrderSearchFilters>({
				group: t('filters.groups.labels.allFilters'),
				label: t('filters.labels.endDate'),
				field: 'endDate',
				variant: 'date',
				hidden: tableState.currentFilterData.orderDateRangeInDays !== 'custom',
			}),
			createFilterOnlyColumn<OrderListing, OrderSearchFilters>({
				group: t('filters.groups.labels.allFilters'),
				label: t('filters.labels.partNumber'),
				variant: 'text',
				field: 'partNumber',
			}),
			{
				id: 'store',
				header: t('filters.labels.store'),
				enableColumnFilter: false,
				// grow: 1,
				cell: ({ row }) => (
					<PopoverText
						size='sm'
						label={row.original.storeName}
						testId={`display-address-${row.original.purchaseOrderNumber}`}
					>
						<AddressDisplay
							stackProps={{ m: 0, p: 0 }}
							label={row.original.storeName}
							line1={row.original.line1 ?? ''}
							line2={row.original.line2}
							line3={row.original.line3}
							city={row.original.city}
							stateOrProvince={row.original.stateOrProvince}
							postalCode={row.original.postalCode}
							countryCode={row.original.countryCode}
							countryDisplayName={row.original.countryDisplayName}
						/>
					</PopoverText>
				),
			},
			{
				id: 'orderStatus',
				accessorKey: 'status',
				header: t('searchResults.table.columns.status'),
				filter: {
					group: t('filters.groups.labels.allFilters'),
					variant: 'select',
					getFilterDisplayValue: value => t(`orderStatus.${camelCase(value as string)}`),
				},
				cell: ({ row }) => (
					<OrderStatusDisplay
						status={row.original.status}
						isOrderMarkedForCancellation={row.original.isOrderMarkedForCancellation}
						size='sm'
					/>
				),
			},
			createFilterOnlyColumn<OrderListing, OrderSearchFilters>({
				group: t('filters.groups.labels.allFilters'),
				label: t('filters.labels.plant'),
				field: 'warehouseCode',
				variant: 'select',
				getFilterDisplayValue: value =>
					plantsQuery.data?.find(x => x.warehouseCode === value)?.name ?? String(value),
				hidden: !auth.canAccessMultipleStores,
			}),
			createFilterOnlyColumn<OrderListing, OrderSearchFilters>({
				group: t('filters.groups.labels.allFilters'),
				label: t('filters.labels.country'),
				field: 'countryCode',
				variant: 'select',
				// hidden: !filterableFields.includes('Country'),
				getFilterDisplayValue: value =>
					countriesQuery.data?.find(x => x.code === value)?.name ?? String(value),
				hidden: !auth.canAccessMultipleStores,
			}),
			createFilterOnlyColumn<OrderListing, OrderSearchFilters>({
				group: t('filters.groups.labels.allFilters'),
				label: t('filters.labels.market'),
				field: 'customerGroupId',
				variant: 'select',
				getFilterDisplayValue: value =>
					marketsQuery.data?.find(x => x.id === value)?.name ?? String(value),
				hidden: !auth.canAccessMultipleStores,
			}),
			createFilterOnlyColumn<OrderListing, OrderSearchFilters>({
				group: t('filters.groups.labels.allFilters'),
				label: t('filters.labels.showCancelledDistroOrders'),
				field: 'showCancelledDistroOrders',
				variant: 'switch',
				getFilterDisplayValue: value => t(`common:label.${camelCase(String(value))}`),
			}),
		],
	});

	return (
		<TableLayout
			title={t('searchResults.title')}
			table={table}
			toolbarButtons={[
				<ToolbarExportButton
					key='export'
					tooltip={t('controls:dataTable.exportResults')}
					exportUrl={getOrderSearchExportUrl()}
					fallbackFilename='OrderSearchResult.xlsx'
					filters={tableState.currentFilterData}
				/>,
			]}
		>
			<DataTable
				table={table}
				loading={orderSearchQuery.isFetching}
				variant='apple-table'
				minWidth='1200px'
			/>
		</TableLayout>
	);

	// TODO: Show/hide columns based on filterableFields
}
