import { Card, Group, Stack, Text, Title } from '@mantine/core';

import { useTranslation } from '@apple/lib/i18next';
import type { FiscalCalendarPeriod } from '@apple/features/calendar/models';

import { BudgetDetails } from './BudgetDetails';
import type { BudgetSummaryItem } from '../models/budget';

export interface BudgetItem extends BudgetSummaryItem {
	fiscalPeriod: FiscalCalendarPeriod | undefined;
}

export interface BudgetSummaryProps {
	fiscalYear: number;
	periodNumber: number;
	singleStoreCustomerCode: string;
	budgetItem?: BudgetItem;
}

export function BudgetSummary({
	fiscalYear,
	periodNumber,
	singleStoreCustomerCode,
	budgetItem,
}: BudgetSummaryProps) {
	const { t } = useTranslation('common');

	return (
		<>
			{periodNumber && budgetItem ? (
				<>
					<Card pt='xs' my={0}>
						<Stack>
							<Group justify='flex-start'>
								<Text>{`Budgeted: ${budgetItem?.budgetedAmount}`}</Text>
								<Text
									c={budgetItem?.isOverBudget ? 'red' : 'green'}
								>{`Remaining: ${budgetItem?.difference}`}</Text>
							</Group>
						</Stack>
					</Card>

					<BudgetDetails
						fiscalYear={fiscalYear}
						singleStoreCustomerCode={singleStoreCustomerCode}
						periodNumber={periodNumber}
						fiscalCalendarPeriod={budgetItem.fiscalPeriod}
					/>
				</>
			) : (
				<Stack>
					<Title order={3}>{t('pagination.empty')}</Title>
				</Stack>
			)}
		</>
	);
}
