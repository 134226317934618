import { useCallback } from 'react';
import { notifications } from '@mantine/notifications';
import { useMutation, useQuery } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';
import { getLogger } from 'loglevel';

import { ErrorIcon, Success } from '@apple/assets/icons';
import { requireAuth } from '@apple/features/auth';
import { addUser } from '@apple/features/user-retail/api/management';
import { UserForm } from '@apple/features/user-retail/components/UserForm';
import { userManageQueries } from '@apple/features/user-retail/queries/manage';
import { useTranslation } from '@apple/lib/i18next';
import { DetailsLayout } from '@apple/ui/layouts';
import { ServerValidationError } from '@apple/utils/api';

const log = getLogger('manage-user');

export const Route = createFileRoute('/_authed/_admin/manage/users_/add')({
	beforeLoad: ({ ...args }) => {
		requireAuth(args, {
			requirePermission: 'Manzanita.Security.Features.UserManagement.Write',
		});

		return {
			userManagementOptionsQueryOptions: userManageQueries.options,
		};
	},
	component: AddUserRoute,
});

function AddUserRoute() {
	const { queryClient, userManagementOptionsQueryOptions } = Route.useRouteContext();
	const { t } = useTranslation('user');
	const navigate = Route.useNavigate();

	const options = useQuery(userManagementOptionsQueryOptions);

	const onSuccessfulChange = useCallback(
		async (message: string) => {
			await queryClient.invalidateQueries({ queryKey: ['users-odata'] });
			notifications.show({
				message: message,
				icon: <Success />,
				color: 'green',
				autoClose: 5000,
			});
			await navigate({
				to: '/manage/users',
			});
		},
		[navigate, queryClient],
	);

	const onFailedChange = useCallback(
		(error: Error) => {
			if (!(error instanceof ServerValidationError)) {
				log.error(error);
				notifications.show({
					message: t('common:error.generic'),
					icon: <ErrorIcon />,
					color: 'red',
					autoClose: 5000,
				});
			}
		},
		[t],
	);

	const addUserMutation = useMutation({
		mutationFn: addUser,
		onSuccess: async () => {
			await onSuccessfulChange(t('notifications.createSuccess'));
		},
		onError: onFailedChange,
	});

	return (
		<DetailsLayout title={t('addUser.title')}>
			<UserForm saveUserMutation={addUserMutation} options={options.data} />
		</DetailsLayout>
	);
}
