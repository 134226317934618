import { z } from 'zod';

import type { StoreId } from '@apple/features/store/models';
import { localDateTimeSchema } from '@apple/utils/globalization';

import {
	type StoreEventStatus,
	type StoreEventType,
	storeEventStatusSchema,
	storeEventTypeSchema,
} from './shared';

export type StoreEventId = string;

export interface StoreEventFilters
	extends Partial<
		Omit<
			StoreEventListingModel,
			| 'id'
			| 'storeName'
			| 'storeNumber'
			| 'updatedDate'
			| 'fullKitDeliveryDate'
			| 'fullKitShipDate'
			| 'hasAuditLogEntries'
		>
	> {}

/**
 * @see AppleRetail.Web.Features.StoreEvents.StoreEventManagementODataController.StoreEventListingModel
 */
export interface StoreEventListingModel {
	id: StoreEventId;
	status: StoreEventStatus;
	storeName: string;
	storeNumber: string;
	thirdPartyCustomerId: StoreId;
	type: StoreEventType | '';
	updatedDate: string;
	warehouseCode: string;
	fullKitDeliveryDate: string | null;
	fullKitShipDate: string | null;
	hasAuditLogEntries: boolean;
	hasLongPendingLockedItems: boolean;
}

/**
 * @see AppleRetail.Web.Areas.StoreEvents.Models.StoreEventAuditLogEntryModel
 */
export interface StoreEventAuditLogEntryModel {
	username: string;
	timestamp: string | Date;
	categoryName: string;
	eventText: string | null;
	comments: string | null;
}

/** @see AppleRetail.Web.Features.StoreEvents.StoreEventManagementODataController.StoreEventListingModel */
export const storeEventListingModelSchema = z.object({
	id: z.string(),
	status: storeEventStatusSchema,
	storeName: z.string(),
	storeNumber: z.string(),
	thirdPartyCustomerId: z.string(),
	type: storeEventTypeSchema.or(z.literal('')),
	updatedDate: z.string(),
	warehouseCode: z.string(),
	fullKitDeliveryDate: z.string().nullable(),
	fullKitShipDate: z.string().nullable(),
	hasAuditLogEntries: z.boolean(),
	hasLongPendingLockedItems: z.boolean(),
}) satisfies z.ZodType<StoreEventListingModel>;

/**
 * @see AppleRetail.Web.Areas.StoreEvents.Models.StoreEventAuditLogEntryModel
 */
export const storeEventAuditLogEntryModelSchema = z.object({
	username: z.string(),
	timestamp: localDateTimeSchema,
	categoryName: z.string(),
	eventText: z.string().nullable(),
	comments: z.string().nullable(),
}) satisfies z.ZodType<StoreEventAuditLogEntryModel>;

export const storeEventFiltersSchema = storeEventListingModelSchema.partial().omit({
	id: true,
	storeName: true,
	updatedDate: true,
	fullKitDeliveryDate: true,
	fullKitShipDate: true,
	hasAuditLogEntries: true,
}) satisfies z.ZodType<StoreEventFilters>;
