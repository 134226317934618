import type { StoreId } from '@apple/features/store/models';
import {
	type CountryCode,
	countryCodeSchema,
	dateTimeOffsetSchema,
	emptyOrNullableLocalDateTimeSchema,
} from '@apple/utils/globalization';
import { z } from 'zod';
import {
	type StoreEventStatusId,
	type StoreEventType,
	type StoreEventTypeId,
	storeEventStatusIdSchema,
	storeEventTypeSchema,
	storeEventTypeToIdSchema,
} from './shared';

/** @see AppleRetail.Web.Areas.StoreEvents.Models.StoreEventModel */
export interface StoreEventModel {
	id: string;
	storeName: string;
	/** Maps to the store's third party id in the backend */
	storeNumber: StoreId;
	storeCountryCode: CountryCode;
	storeUtcOffsetMinutes: number;
	customerCode: string | null;
	transitDays: number | null;
	type: StoreEventType | null;
	status: StoreEventStatusId | null;
	eventDate: Date | string | null;
	updatedDate: Date | string;
	tablePickDate: Date | string | null;
	tableShipDate: Date | string | null;
	tableDeliveryDate: Date | string | null;
	earlyManagementKitPickDate: Date | string | null;
	earlyManagementKitShipDate: Date | string | null;
	earlyManagementKitDeliveryDate: Date | string | null;
	fullKitPickDate: Date | string | null;
	fullKitShipDate: Date | string | null;
	fullKitDeliveryDate: Date | string | null;
}

/** @see AppleRetail.Web.Features.StoreEvents.StoreEventManagementController.StoreEventDetailUpdateRequest */
export interface StoreEventUpdateModel {
	transitDays: number;
	type: StoreEventType;
	eventDate: Date | string | null;
	tablePickDate: Date | string | null;
	tableShipDate: Date | string | null;
	tableDeliveryDate: Date | string | null;
	earlyManagementKitPickDate: Date | string | null;
	earlyManagementKitShipDate: Date | string | null;
	earlyManagementKitDeliveryDate: Date | string | null;
	fullKitPickDate: Date | string | null;
	fullKitShipDate: Date | string | null;
	fullKitDeliveryDate: Date | string | null;
	comment: string | null;
}

type StoreEventUpdateEnumModel = Omit<StoreEventUpdateModel, 'type'> & {
	type: StoreEventTypeId;
};

export const DEFAULT_STORE_EVENT_UPDATE_MODEL = {
	transitDays: 0,
	type: 'NewStore',
	eventDate: '',
	tablePickDate: '',
	tableShipDate: '',
	tableDeliveryDate: '',
	earlyManagementKitPickDate: '',
	earlyManagementKitShipDate: '',
	earlyManagementKitDeliveryDate: '',
	fullKitPickDate: '',
	fullKitShipDate: '',
	fullKitDeliveryDate: '',
	comment: 'N/A',
} as const as StoreEventUpdateModel;

/** @see AppleRetail.Web.Areas.StoreEvents.Models.StoreEventModel */
export const storeEventModelSchema = z.object({
	id: z.string(),
	storeName: z.string(),
	storeNumber: z.string(),
	storeCountryCode: countryCodeSchema,
	storeUtcOffsetMinutes: z.number(),
	customerCode: z.string().nullable(),
	transitDays: z.number().nullable(),
	type: storeEventTypeSchema.nullable(),
	status: storeEventStatusIdSchema.nullable(),
	eventDate: dateTimeOffsetSchema.nullable(),
	updatedDate: dateTimeOffsetSchema,
	tablePickDate: dateTimeOffsetSchema.nullable(),
	tableShipDate: dateTimeOffsetSchema.nullable(),
	tableDeliveryDate: dateTimeOffsetSchema.nullable(),
	earlyManagementKitPickDate: dateTimeOffsetSchema.nullable(),
	earlyManagementKitShipDate: dateTimeOffsetSchema.nullable(),
	earlyManagementKitDeliveryDate: dateTimeOffsetSchema.nullable(),
	fullKitPickDate: dateTimeOffsetSchema.nullable(),
	fullKitShipDate: dateTimeOffsetSchema.nullable(),
	fullKitDeliveryDate: dateTimeOffsetSchema.nullable(),
}) as z.ZodType<StoreEventModel>;

/** @see AppleRetail.Web.Features.StoreEvents.StoreEventManagementController.StoreEventDetailUpdateRequest */
export const storeEventUpdateModelSchema = z.object({
	transitDays: z.number(),
	type: storeEventTypeSchema,
	eventDate: emptyOrNullableLocalDateTimeSchema,
	tablePickDate: emptyOrNullableLocalDateTimeSchema,
	tableShipDate: emptyOrNullableLocalDateTimeSchema,
	tableDeliveryDate: emptyOrNullableLocalDateTimeSchema,
	earlyManagementKitPickDate: emptyOrNullableLocalDateTimeSchema,
	earlyManagementKitShipDate: emptyOrNullableLocalDateTimeSchema,
	earlyManagementKitDeliveryDate: emptyOrNullableLocalDateTimeSchema,
	fullKitPickDate: emptyOrNullableLocalDateTimeSchema,
	fullKitShipDate: emptyOrNullableLocalDateTimeSchema,
	fullKitDeliveryDate: emptyOrNullableLocalDateTimeSchema,
	comment: z.string().nullable(),
}) satisfies z.ZodType<StoreEventUpdateModel>;

/**
 * Same as `storeEventUpdateModelSchema`, but `type` gets converted to `StoreEventTypeId` enum.
 * @see AppleRetail.Web.Features.StoreEvents.StoreEventManagementController.StoreEventDetailUpdateRequest
 */
export const storeEventUpdateEnumModelSchema = storeEventUpdateModelSchema
	.omit({ type: true })
	.merge(
		z.object({
			type: storeEventTypeToIdSchema,
		}),
	) as z.ZodType<StoreEventUpdateEnumModel, z.ZodTypeDef, StoreEventUpdateModel>;

export const storesByEventStatusResponseSchema = z.object({
	storeIds: z.array(z.string()),
	storeCodes: z.array(z.string()),
});
