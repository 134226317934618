import { useCallback } from 'react';
import { notifications } from '@mantine/notifications';
import { useMutation, useQuery, useSuspenseQuery } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';
import { getLogger } from 'loglevel';
import { z } from 'zod';

import { ErrorIcon, Success } from '@apple/assets/icons';
import { requireAuth } from '@apple/features/auth';
import { deleteUser, saveUser, unlockUser } from '@apple/features/user-retail/api/management';
import { UserForm } from '@apple/features/user-retail/components/UserForm';
import { userManageQueries } from '@apple/features/user-retail/queries/manage';
import { useTranslation } from '@apple/lib/i18next';
import { DetailsLayout } from '@apple/ui/layouts';
import { ServerValidationError } from '@apple/utils/api';

const log = getLogger('manage-user');

export const Route = createFileRoute('/_authed/_admin/manage/users_/$userName')({
	parseParams: ({ userName }) => ({
		userName: z.string().parse(userName),
	}),
	beforeLoad: ({ params: { userName }, ...args }) => {
		requireAuth(args, {
			requirePermission: 'Manzanita.Security.Features.UserManagement.Write',
		});

		return {
			userManagementOptionsQueryOptions: userManageQueries.options,
			userQueryOptions: userManageQueries.user(userName),
		};
	},
	loader: ({ context: { queryClient, userManagementOptionsQueryOptions, userQueryOptions } }) => {
		void queryClient.prefetchQuery(userManagementOptionsQueryOptions);
		void queryClient.prefetchQuery(userQueryOptions);
	},
	component: EditUserRoute,
	wrapInSuspense: true,
});

function EditUserRoute() {
	const { userName } = Route.useParams();
	const { t } = useTranslation('user');
	const { userManagementOptionsQueryOptions, userQueryOptions, queryClient } =
		Route.useRouteContext();

	const userManagementOptionsQuery = useQuery(userManagementOptionsQueryOptions);
	const userQuery = useSuspenseQuery(userQueryOptions);

	const navigate = Route.useNavigate();

	const onSuccessfulChange = useCallback(
		async (message: string) => {
			await queryClient.invalidateQueries({ queryKey: ['users-odata'] });
			await queryClient.invalidateQueries({
				queryKey: ['user', userName],
			});
			notifications.show({
				message: message,
				icon: <Success />,
				color: 'green',
				autoClose: 5000,
			});
			await navigate({
				to: '/manage/users',
			});
		},
		[navigate, queryClient, userName],
	);

	const onFailedChange = useCallback(
		(error: Error) => {
			if (!(error instanceof ServerValidationError)) {
				log.error(error);
				notifications.show({
					message: t('common:error.generic'),
					icon: <ErrorIcon />,
					color: 'red',
					autoClose: 5000,
				});
			}
		},
		[t],
	);

	const updateUserMutation = useMutation({
		mutationFn: saveUser,
		onSuccess: async () => {
			await onSuccessfulChange(t('notifications.updateSuccess'));
		},
		onError: onFailedChange,
	});

	const deleteUserMutation = useMutation({
		mutationFn: deleteUser,
		onSuccess: async () => {
			await onSuccessfulChange(t('notifications.deleteSuccess'));
		},
		onError: onFailedChange,
	});

	const unlockUserMutation = useMutation({
		mutationFn: unlockUser,
		onSuccess: async () => {
			await onSuccessfulChange(t('notifications.unlockSuccess'));
		},
		onError: onFailedChange,
	});

	return (
		<DetailsLayout title={t('editUser.title', { userName })}>
			<UserForm
				isEdit
				saveUserMutation={updateUserMutation}
				deleteUserMutation={deleteUserMutation}
				unlockUserMutation={unlockUserMutation}
				user={userQuery.data}
				options={userManagementOptionsQuery.data}
			/>
		</DetailsLayout>
	);
}
