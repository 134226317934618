import { createElement, useCallback, useState } from 'react';
import { Anchor, FocusTrap, Group, Modal, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';
import {
	getCoreRowModel,
	getFacetedMinMaxValues,
	getFacetedUniqueValues,
	useReactTable,
} from '@tanstack/react-table';
import dayjs from 'dayjs';
import { getLogger } from 'loglevel';
import type { SortingState } from '@tanstack/react-table';

import { icons } from '@apple/assets';
import { requireAuth } from '@apple/features/auth';
import {
	getExportTemplateFileName,
	uploadBulkTemplate,
} from '@apple/features/order-retail/api/distro';
import { OrderUploadForm } from '@apple/features/order-retail/components/OrderUploadForm';
import {
	getDistroOrderHistoryDownloadUrl,
	updateConfidentialOrder,
} from '@apple/features/order/api/distro';
import { BulkTemplateSelection } from '@apple/features/order/components/BulkTemplateSelection';
import { DistroOrderConfirmationDialog } from '@apple/features/order/components/DistroOrderConfirmationDialog';
import { getDistroHistoryQueryOptions } from '@apple/features/order/queries/distro';
import { useTranslation } from '@apple/lib/i18next';
import { createFilterOnlyColumn, DataTable, useTableState } from '@apple/ui/data-table';
import { ToolbarButton } from '@apple/ui/data-table/controls/Toolbar';
import { TableLayout } from '@apple/ui/layouts';
import { ServerValidationError } from '@apple/utils/api';
import { useFileDownload } from '@apple/utils/files';
import { FormattedBoolean, FormattedDate } from '@apple/utils/globalization';
import type { OrderUploadResponse } from '@apple/features/order-retail/models/distro';
import type {
	DistroHistoryFilters,
	DistroHistoryListing,
} from '@apple/features/order/models/distro';

const defaultSorting: SortingState = [{ id: 'orderData', desc: true }];
const defaultFilters: DistroHistoryFilters = {
	orderDateRangeInDays: 60,
};
export const Route = createFileRoute('/_authed/_admin/distro/history')({
	component: DistroHistoryRoute,
	beforeLoad: args =>
		requireAuth(args, {
			requirePermission: 'Manzanita.Security.Features.DistroOrderHistory',
		}),
});

const log = getLogger('distro-history');

function DistroHistoryRoute() {
	const { t } = useTranslation('distro-history');
	const queryClient = useQueryClient();
	const [loading, setLoading] = useState(false);

	const search = Route.useSearch();
	const navigate = Route.useNavigate();

	const [uploadResult, setUploadResult] = useState<OrderUploadResponse>();
	const [salesOrderNumber, setSalesOrderNumber] = useState<string | null>(null);
	const [
		bulkTemplateSelectionOpened,
		{ open: openBulkTemplateSelection, close: closeBulkTemplateSelection },
	] = useDisclosure(false);
	const [uploadFormOpened, { open: openUploadForm, close: closeUploadForm }] =
		useDisclosure(false);
	const [
		orderConfirmationOpened,
		{ open: openOrderConfirmation, close: closeOrderConfirmation },
	] = useDisclosure(false);

	const distroBulkOrderDownload = useFileDownload({
		method: 'post',
		url: '',
		fallbackFilename: `DistroOrderExport_${dayjs().format('YYYY-MM-DD')}.xlsx`,
		errorTitle: t('common:error.title'),
		errorMessage: t('common:error.fileDownload'),
	});

	const sampleTemplateDownload = useFileDownload<{ fileName?: string }>({
		method: 'get',
		url: '/api/bulk-order-import/download-generated-template',
		fallbackFilename: 'BulkOrderUpload_template.xlsx',
		errorTitle: t('common:error.title'),
		errorMessage: t('common:error.fileDownload'),
	});

	const onDownloadSampleTemplate = useCallback(() => {
		async function downloadTemplateFile() {
			try {
				const templateFile = await getExportTemplateFileName(true, false);
				await sampleTemplateDownload.download({
					params: {
						fileName: templateFile,
					},
				});
			} catch (error) {
				notifications.show({
					message: t('common:error.generic'),
					icon: <icons.Warning />,
					color: 'red',
					autoClose: 5000,
				});
				log.error('Error downloading export sample template', error);
			}
		}

		void downloadTemplateFile();
	}, [sampleTemplateDownload, t]);

	const handleUploadResult = useCallback(
		(result: OrderUploadResponse) => {
			closeBulkTemplateSelection();
			setUploadResult(result);
			openUploadForm();
		},
		[closeBulkTemplateSelection, openUploadForm],
	);

	const handleOrderClick = useCallback(
		async (salesOrderNumber: string) => {
			await distroBulkOrderDownload.download({
				url: getDistroOrderHistoryDownloadUrl(salesOrderNumber),
			});
		},
		[distroBulkOrderDownload],
	);

	const handleStartOver = useCallback(() => {
		closeUploadForm();
		closeOrderConfirmation();
		setUploadResult(undefined);
		setSalesOrderNumber('');
		openBulkTemplateSelection();
	}, [closeOrderConfirmation, closeUploadForm, openBulkTemplateSelection]);

	const handleOrderCreated = useCallback(
		(salesOrderNumber: string) => {
			setSalesOrderNumber(salesOrderNumber);
			closeUploadForm();
			openOrderConfirmation();
		},
		[closeUploadForm, openOrderConfirmation],
	);

	const changeOrderConfidentialState = useMutation({
		mutationFn: (order: DistroHistoryListing) => {
			setLoading(true);
			return updateConfidentialOrder(order.salesOrderNumber, {
				key: 'IsGhostShipment',
				value: !order.isGhostOrder,
			});
		},
		onSuccess: async () => {
			await queryClient.invalidateQueries({
				queryKey: [
					'distro-history',
					tableState.currentFilterData,
					tableState.state.pagination,
				],
			});
			setLoading(false);
		},
		onError: (error: Error) => {
			if (!(error instanceof ServerValidationError)) {
				notifications.show({
					message: t('common:error.generic'),
					icon: <icons.ErrorIcon />,
					color: 'red',
					autoClose: 5000,
				});
			}
		},
	});

	const tableState = useTableState<DistroHistoryListing, DistroHistoryFilters>({
		search,
		navigate,
		defaultFilters,
		defaultSorting,
		fieldMap: [],
	});

	const distroHistoryQuery = useQuery(
		getDistroHistoryQueryOptions(
			tableState.state.pagination,
			tableState.currentFilterData,
			_ => {
				notifications.show({
					color: 'red',
					message: t('common:error.fetchOrders'),
					icon: createElement(icons.ErrorIcon),
				});
			},
		),
	);

	const table = useReactTable({
		data: distroHistoryQuery.data.rows,
		rowCount: distroHistoryQuery.data.totalRowCount,
		...tableState,
		enableFilters: true,
		enableColumnFilters: true,
		enableSorting: true,
		manualFiltering: true,
		manualSorting: true,
		manualPagination: true,
		getCoreRowModel: getCoreRowModel(),
		getFacetedMinMaxValues: getFacetedMinMaxValues(),
		getFacetedUniqueValues: (table, columnId) => {
			switch (columnId) {
				case 'orderDateRangeInDays':
					return () =>
						new Map([
							['30', 1],
							['60', 1],
							['90', 1],
							['custom', 1],
						]);
				default:
					return getFacetedUniqueValues<DistroHistoryListing>()(table, columnId);
			}
		},
		columnResizeMode: 'onChange',
		columns: [
			{
				header: t('searchResults.table.salesOrderNumber'),
				accessorKey: 'orderNumber',
				filter: {
					group: t('filters.groups.labels.allFilters'),
				},
				cell: ({ row }) => (
					<Anchor onClick={() => void handleOrderClick(row.original.salesOrderNumber)}>
						{row.original.salesOrderNumber}
					</Anchor>
				),
			},
			createFilterOnlyColumn<DistroHistoryListing, DistroHistoryFilters>({
				group: t('filters.groups.labels.allFilters'),
				label: t('filters.labels.orderDate'),
				field: 'orderDateRangeInDays',
				variant: 'select',
				getFilterDisplayValue: value =>
					value === 'custom'
						? t('controls:dataTable.filters.options.dateRange.custom')
						: t('controls:dataTable.filters.options.dateRange.lastNumberOfDays', {
								days: value,
							}),
			}),
			createFilterOnlyColumn<DistroHistoryListing, DistroHistoryFilters>({
				group: t('filters.groups.labels.allFilters'),
				label: t('filters.labels.startDate'),
				field: 'startDate',
				variant: 'date',
				hidden: tableState.currentFilterData.orderDateRangeInDays !== 'custom',
			}),
			createFilterOnlyColumn<DistroHistoryListing, DistroHistoryFilters>({
				group: t('filters.groups.labels.allFilters'),
				label: t('filters.labels.endDate'),
				field: 'endDate',
				variant: 'date',
				hidden: tableState.currentFilterData.orderDateRangeInDays !== 'custom',
			}),
			{
				header: t('searchResults.table.userName'),
				accessorKey: 'userName',
				enableColumnFilter: false,
			},
			createFilterOnlyColumn<DistroHistoryListing, DistroHistoryFilters>({
				group: t('filters.groups.labels.allFilters'),
				label: t('filters.labels.userName'),
				field: 'userId',
				variant: 'text',
			}),
			{
				header: t('searchResults.table.projectName'),
				accessorKey: 'projectName',
				filter: {
					group: t('filters.groups.labels.allFilters'),
				},
				cell: ({ row }) => <Text>{row.original.orderName}</Text>,
			},
			{
				header: t('searchResults.table.ghostOrder'),
				accessorKey: 'isGhostOrder',
				cell: ({ row }) => (
					<Group>
						<FormattedBoolean value={row.original.isGhostOrder} />
						<icons.ConfidentialStatusSwitch
							c={row.original.isGhostOrder ? 'red' : 'green'}
							disabled={loading}
							loading={loading}
							tooltip={
								row.original.isGhostOrder
									? t('buttons.unMarkConfidential')
									: t('buttons.markConfidential')
							}
							onClick={() => changeOrderConfidentialState.mutate(row.original)}
						/>
					</Group>
				),
				enableColumnFilter: false,
			},
			{
				header: t('searchResults.table.orderDate'),
				accessorKey: 'orderDate',
				cell: ({ row }) => <FormattedDate value={row.original.orderDate} humanize={true} />,
				filter: {
					group: t('filters.groups.labels.allFilters'),
					variant: 'date-range',
				},
				enableColumnFilter: false,
			},
			{
				header: t('searchResults.table.submitted'),
				accessorKey: 'isSubmitted',
				cell: ({ row }) => <FormattedBoolean value={row.original.isSubmitted} />,
				enableColumnFilter: false,
			},
			{
				header: t('searchResults.table.comments'),
				accessorKey: 'comments',
				filter: {
					group: t('filters.groups.labels.allFilters'),
				},
			},
			{
				header: t('searchResults.table.totalLinesItemsCount'),
				accessorKey: 'totalLineItemsCount',
				enableColumnFilter: false,
			},
			{
				header: t('searchResults.table.cancelled'),
				accessorKey: 'isCancelled',
				cell: ({ row }) => <FormattedBoolean value={row.original.isCancelled} />,
				enableColumnFilter: false,
			},
			{
				header: t('searchResults.table.totalOrdersCount'),
				accessorKey: 'totalOrdersCount',
				enableColumnFilter: false,
			},
			{
				header: t('searchResults.table.deliverFrom'),
				accessorKey: 'deliverFrom',
				cell: ({ row }) =>
					row.original.deliverFrom !== null && (
						<FormattedDate value={row.original.deliverFrom} />
					),
				enableColumnFilter: false,
			},
			{
				header: t('searchResults.table.deliverBy'),
				accessorKey: 'deliverBy',
				cell: ({ row }) =>
					row.original.deliverBy !== null && (
						<FormattedDate value={row.original.deliverBy} />
					),
				enableColumnFilter: false,
			},
		],
	});

	return (
		<TableLayout
			title={t('searchResults.title')}
			table={table}
			toolbarButtons={[
				<ToolbarButton
					key='export'
					tooltip={t('buttons.export')}
					icon={icons.Download}
					onClick={onDownloadSampleTemplate}
					disabled={sampleTemplateDownload.downloading}
					loading={sampleTemplateDownload.downloading}
				/>,
				<ToolbarButton
					key='import'
					tooltip={t('buttons.import')}
					icon={icons.Upload}
					onClick={openBulkTemplateSelection}
				/>,
			]}
		>
			<DataTable table={table} loading={distroHistoryQuery.isFetching} />
			<Modal
				id='bulk-template-selection-dialog'
				title={t('distro-upload:bulkImport.title')}
				opened={bulkTemplateSelectionOpened}
				onClose={closeBulkTemplateSelection}
				closeOnClickOutside={false}
				closeOnEscape={false}
				size='lg'
			>
				<FocusTrap.InitialFocus />
				<BulkTemplateSelection
					uploadTemplate={uploadBulkTemplate}
					onUploadTemplateResult={handleUploadResult}
					onClose={closeBulkTemplateSelection}
				/>
			</Modal>
			<Modal
				id='upload-result-dialog'
				title={t('distro-upload:bulkImport.title')}
				opened={uploadFormOpened}
				onClose={closeUploadForm}
				closeOnClickOutside={false}
				closeOnEscape={false}
				size='xl'
			>
				<FocusTrap.InitialFocus />
				{uploadResult && (
					<OrderUploadForm
						result={uploadResult}
						onStartOver={handleStartOver}
						onClose={closeUploadForm}
						onOrderCreated={handleOrderCreated}
					/>
				)}
			</Modal>
			<Modal
				id='order-confirmation-dialog'
				title={t('distro-upload:bulkImport.title')}
				opened={orderConfirmationOpened}
				onClose={closeOrderConfirmation}
				closeOnClickOutside={false}
				closeOnEscape={false}
				size='lg'
			>
				<FocusTrap.InitialFocus />
				<DistroOrderConfirmationDialog
					salesOrderNumber={salesOrderNumber ?? ''}
					onCreateNewOrder={handleStartOver}
				/>
			</Modal>
		</TableLayout>
	);
}
