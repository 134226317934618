import { notifications } from '@mantine/notifications';
import { useMutation } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';
import { getLogger } from 'loglevel';

import { ErrorIcon, Success } from '@apple/assets/icons';
import { requireAuth } from '@apple/features/auth';
import { addStore } from '@apple/features/store/api/management';
import { StoreForm } from '@apple/features/store/components/StoreForm';
import { storeQueryKeys } from '@apple/features/store/queries';
import { useTranslation } from '@apple/lib/i18next';
import { DetailsLayout } from '@apple/ui/layouts';
import { ServerValidationError } from '@apple/utils/api';

const log = getLogger('manage-store');

export const Route = createFileRoute('/_authed/_admin/manage/stores_/add')({
	beforeLoad: ({ ...args }) => {
		requireAuth(args, {
			requirePermission: 'Manzanita.Security.Features.CustomerManagement',
		});
	},
	wrapInSuspense: true,
	component: StoresRoute,
});

function StoresRoute() {
	const { t } = useTranslation('store');
	const { queryClient } = Route.useRouteContext();
	const navigate = Route.useNavigate();

	async function handleSuccessfulChange(message: string) {
		await queryClient.invalidateQueries({
			queryKey: storeQueryKeys.all,
		});

		notifications.show({
			message: message,
			icon: <Success />,
			color: 'green',
			autoClose: 5000,
		});
		await navigate({
			to: '/manage/stores',
		});
	}

	const saveStoreMutation = useMutation({
		mutationFn: addStore,
		onSuccess: async () => {
			await handleSuccessfulChange(t('addStore.success'));
		},
		onError: (error: Error) => {
			if (!(error instanceof ServerValidationError)) {
				log.error(error);
				notifications.show({
					message: t('common:error.generic'),
					icon: <ErrorIcon />,
					color: 'red',
					autoClose: 5000,
				});
			}
		},
	});

	return (
		<DetailsLayout title={t('titles.addStore')}>
			<StoreForm isEdit={false} saveStoreMutation={saveStoreMutation} />
		</DetailsLayout>
	);
}
