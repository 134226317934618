import { keepPreviousData, queryOptions } from '@tanstack/react-query';
import type { PaginationState } from '@tanstack/react-table';

import type { Guid } from '@apple/utils/primitive';

import { getStoreEventAudits } from '../api/audit';
import { getAll } from '../api/categories';
import { getStoreEvent, getStoreIdsForExistingEvents } from '../api/management';
import type { StoreEventFilters } from '../models/listing';

export const storeEventQueryKeys = {
	all: ['store-events'],
	categories: () => [...storeEventQueryKeys.all, 'categories'],
	list: () => [...storeEventQueryKeys.all, 'list'],
	listPage: (pagination: PaginationState, filters: StoreEventFilters) => [
		...storeEventQueryKeys.list(),
		pagination,
		filters,
	],
	getStoreIdsForExistingEvents: () => [...storeEventQueryKeys.all, 'existing-store-ids'],
	storeEvent: (storeEventId: Guid) => [...storeEventQueryKeys.all, storeEventId],
	audit: (storeEventId: Guid) => [...storeEventQueryKeys.storeEvent(storeEventId), 'audit'],
};

export const storeEventQueries = {
	categories: queryOptions({
		queryKey: storeEventQueryKeys.categories(),
		queryFn: ({ signal }) => getAll(signal),
	}),
	audit: (storeEventId: Guid) =>
		queryOptions({
			queryKey: storeEventQueryKeys.audit(storeEventId),
			queryFn: ({ signal }) => getStoreEventAudits(storeEventId, signal),
			placeholderData: keepPreviousData,
			staleTime: 5 * 60 * 1000, // 5 minutes
			initialDataUpdatedAt: 0,
			initialData: [],
		}),
	getById: (storeEventId: Guid) =>
		queryOptions({
			queryKey: storeEventQueryKeys.storeEvent(storeEventId),
			queryFn: ({ signal }) => getStoreEvent(storeEventId, signal),
			throwOnError: true,
		}),
	getStoreIdsForExistingEvents: () =>
		queryOptions({
			queryKey: storeEventQueryKeys.getStoreIdsForExistingEvents(),
			queryFn: ({ signal }) => getStoreIdsForExistingEvents(signal),
			throwOnError: true,
		}),
};
