import axios from 'axios';

import { cartResponseSchema } from '../models/cart';

/** Service wrapper for AppleRetail.Web.ApiControllers.AppleRetailBulkOrderApiController */
export async function getOrCreateCart(signal?: AbortSignal) {
	const { data } = await axios.get<unknown>('/api/shop/order', { signal });
	return cartResponseSchema.parse(data);
}

/** Service wrapper for AppleRetail.Web.ApiControllers.OrderItemQuantityApiController */
export async function getItemsCount(signal?: AbortSignal) {
	return (await axios.get<number>('/api/bulk-order/items/count', { signal })).data;
}

/** Service wrapper for Manzanita.Web.ApiControllers.Shop.BulkOrderApiController */
export async function resetBulkOrder(signal?: AbortSignal) {
	await axios.post('/api/bulk-order/reset', { signal });
}
