import { z } from 'zod';

import { weekdayOptionSchema } from '@apple/utils/globalization/components/days-of-week/model';
import { emptyOrNullableLocalDateTimeSchema } from '@apple/utils/globalization/models';
import type { WeekdayOption } from '@apple/utils/globalization/components/days-of-week/model';

/** AppleRetail.Web.Features.Budgets.BudgetReportDetailsController.BudgetSummaryItem*/
export interface BudgetSummaryItem {
	periodNumber: number;
	budgetedAmount: string;
	amountSpent: string;
	difference: string;
	isOverBudget?: boolean;
}

/** AppleRetail.Web.Features.Budgets.BudgetReportDetailsController.BudgetDetailsItem*/
export interface BudgetDetailsItem {
	categoryId: number;
	categoryName: string;
	amountSpent: string;
	formattedPercentOfTotalBudget: string;
	percentOfTotalBudget: number;
}

/** AppleRetail.Web.Features.Budgets.BudgetReportDetailsController.ProductPurchaseDetailItem*/
export interface ProductPurchaseDetailItem {
	partNumber: string;
	description: string;
	quantity: number;
	formattedTotalCost: string;
}

/** AppleRetail.Web.Features.Stores.CurrentStoreStateController */
export interface StoreStateDetails {
	storeName: string;
	arvatoCustomerCode: string;
	thirdPartyId: string;
	fiscalYear: number;
	periodNumber: number;
	periodStartDate: Date | null | string;
	periodEndDate: Date | null | string;
	remainingBudget: number;
	allocatedAmount: number;
	formattedAllocatedAmount: string;
	consumedAmount: number;
	formattedRemainingBudget: string;
	formattedConsumedAmount: string;
	storeImageSmall: string;
	storeImageLarge: string;
	shipmentDays: WeekdayOption[];
	shipmentCutOffTime: number;
	storeUtcOffsetMinutes: number;
	currentShipmentDate: string;
	nextShipmentDate: string;
	pendingAmount: number;
	formattedPendingAmount: string;
	totalRemainingBudget?: number;
	formattedTotalRemainingBudget?: string;
	userCurrentDate: Date | null | string;
}

/** AppleRetail.Web.Features.Budgets.BudgetReportDetailsController.BudgetSummaryItem*/
export const budgetSummaryItemSchema = z.object({
	periodNumber: z.number(),
	budgetedAmount: z.string(),
	amountSpent: z.string(),
	difference: z.string(),
	isOverBudget: z.boolean().optional(),
}) satisfies z.ZodType<BudgetSummaryItem>;

/** AppleRetail.Web.Features.Budgets.BudgetReportDetailsController.BudgetDetailsItem*/
export const budgetDetailsItemSchema = z.object({
	categoryId: z.number(),
	categoryName: z.string(),
	amountSpent: z.string(),
	formattedPercentOfTotalBudget: z.string(),
	percentOfTotalBudget: z.number(),
}) satisfies z.ZodType<BudgetDetailsItem>;

/** AppleRetail.Web.Features.Budgets.BudgetReportDetailsController.ProductPurchaseDetailItem*/
export const productPurchaseDetailItemSchema = z.object({
	partNumber: z.string(),
	description: z.string(),
	quantity: z.number(),
	formattedTotalCost: z.string(),
}) satisfies z.ZodType<ProductPurchaseDetailItem>;

/** AppleRetail.Web.Features.Stores.CurrentStoreStateController */
export const storeStateDetailsSchema = z.object({
	storeName: z.string(),
	arvatoCustomerCode: z.string(),
	thirdPartyId: z.string(),
	fiscalYear: z.number(),
	periodNumber: z.number(),
	periodStartDate: emptyOrNullableLocalDateTimeSchema,
	periodEndDate: emptyOrNullableLocalDateTimeSchema,
	remainingBudget: z.number(),
	allocatedAmount: z.number(),
	formattedAllocatedAmount: z.string(),
	consumedAmount: z.number(),
	formattedRemainingBudget: z.string(),
	formattedConsumedAmount: z.string(),
	storeImageSmall: z.string(),
	storeImageLarge: z.string(),
	shipmentDays: z.array(weekdayOptionSchema),
	shipmentCutOffTime: z.number(),
	storeUtcOffsetMinutes: z.number(),
	currentShipmentDate: z.string(),
	nextShipmentDate: z.string(),
	pendingAmount: z.number(),
	formattedPendingAmount: z.string(),
	totalRemainingBudget: z.number().optional(),
	formattedTotalRemainingBudget: z.string().optional(),
	userCurrentDate: emptyOrNullableLocalDateTimeSchema,
}) satisfies z.ZodType<StoreStateDetails>;
