import { createFileRoute } from '@tanstack/react-router';

import { requireAuth } from '@apple/features/auth';
import { MarketForm, useMarketManagement } from '@apple/features/market';
import { useTranslation } from '@apple/lib/i18next';
import { useRouterHistory } from '@apple/lib/tanstack-router/hooks/use-router-history';
import { DetailsLayout } from '@apple/ui/layouts';
import { countryCodeSchema } from '@apple/utils/globalization/models';

export const Route = createFileRoute('/_authed/_admin/manage/markets_/add')({
	beforeLoad: ({ ...args }) => {
		requireAuth(args, {
			requirePermission: 'Manzanita.Security.Features.CustomerGroupManagement.Read',
		});
	},
	component: MarketRoute,
});

function MarketRoute() {
	const navigate = Route.useNavigate();
	const history = useRouterHistory();
	const { t } = useTranslation('market');
	const { mutateAsync } = useMarketManagement();

	return (
		<DetailsLayout title={t('title.add')}>
			<MarketForm
				isEdit={false}
				onSaveMarket={async market =>
					await mutateAsync(
						{
							action: 'add',
							market: {
								...market,
								countryCode: countryCodeSchema.parse(market.countryCode),
							},
						},
						{
							onSuccess: () => {
								history.canGoBack()
									? history.back()
									: void navigate({ to: '/manage/markets' });
							},
						},
					)
				}
			/>
		</DetailsLayout>
	);
}
