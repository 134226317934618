import { Button, Center, Stack, Title } from '@mantine/core';
import { createFileRoute } from '@tanstack/react-router';

import { requireAuth } from '@apple/features/auth/utils/require-auth';
import { getBudgetReportUrl } from '@apple/features/store/api/management';
import { useTranslation } from '@apple/lib/i18next';
import { useFileDownload } from '@apple/utils/files/hooks/useFileDownload';

export const Route = createFileRoute('/_authed/_admin/reports/budgets')({
	beforeLoad: args => {
		requireAuth(args, {
			requirePermission: 'Manzanita.Security.Features.Reports',
		});
	},
	component: Budgets,
});

function Budgets() {
	const { t } = useTranslation('common');

	const excelTemplate = useFileDownload({
		method: 'post',
		url: getBudgetReportUrl(),
		fallbackFilename: 'storeBudget.xlsx',
		errorTitle: t('error.title'),
		errorMessage: t('error.fileDownload'),
	});
	return (
		<Center mt={100}>
			<Stack>
				<Title order={3}>{t('error.notImplemented')}</Title>
				<Button
					variant='outline'
					loading={excelTemplate.downloading}
					onClick={() => {
						void excelTemplate.download();
					}}
				>
					{t('manage:stores.buttons.downloadBudget.title')}
				</Button>
			</Stack>
		</Center>
	);
}
