import { icons } from '@apple/assets';
import { useTranslation } from '@apple/lib/i18next';
import { ToolbarButton } from '@apple/ui/data-table/controls/Toolbar';

import { useFileDownload } from '../hooks/useFileDownload';

export function ToolbarExportButton<TFilters>({
	tooltip,
	filters,
	exportUrl,
	fallbackFilename,
	disabled = false,
	visible = true,
}: {
	tooltip: string;
	filters: TFilters;
	exportUrl: string;
	fallbackFilename: string;
	disabled?: boolean;
	visible?: boolean;
}) {
	const { t } = useTranslation('common');
	const exportFilteredOrders = useFileDownload({
		method: 'post',
		url: exportUrl,
		data: filters,
		fallbackFilename: fallbackFilename,
		errorTitle: t('error.title'),
		errorMessage: t('error.fileDownload'),
	});

	return !visible ? null : (
		<ToolbarButton
			tooltip={tooltip}
			icon={icons.Download}
			disabled={disabled}
			loading={exportFilteredOrders.downloading}
			onClick={() => void exportFilteredOrders.download()}
			data-testid='export-results'
		/>
	);
}
