import { Text } from '@mantine/core';
import { keepPreviousData, queryOptions, useQuery } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';
import {
	getCoreRowModel,
	getFacetedMinMaxValues,
	getFacetedRowModel,
	getFacetedUniqueValues,
	getFilteredRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	useReactTable,
} from '@tanstack/react-table';
import type { SortingState } from '@tanstack/react-table';

import { icons } from '@apple/assets';
import { getAllAnnouncements } from '@apple/features/announcements/api/management';
import { requireAuth } from '@apple/features/auth';
import { useTranslation } from '@apple/lib/i18next';
import { DataTable, useTableState } from '@apple/ui/data-table';
import { ToolbarButton } from '@apple/ui/data-table/controls/Toolbar';
import { TableLayout } from '@apple/ui/layouts';
import { Link } from '@apple/ui/link';
import type { Announcement } from '@apple/features/announcements/models/management';

const defaultFilters: { name?: string } = {};
const defaultSorting: SortingState = [{ id: 'active', desc: true }];
export const Route = createFileRoute('/_authed/_admin/content/announcements')({
	beforeLoad: args => {
		requireAuth(args);

		return {
			announcementsQueryOptions: queryOptions({
				queryKey: ['announcements'],
				queryFn: () => getAllAnnouncements(),
				placeholderData: keepPreviousData,
				throwOnError: true,
				initialData: [],
			}),
		};
	},
	loader: ({ context: { queryClient, announcementsQueryOptions } }) => {
		void queryClient.prefetchQuery(announcementsQueryOptions);
	},
	component: AnnouncementsManagePageRoute,
});

function AnnouncementsManagePageRoute() {
	const { t } = useTranslation('content');
	const navigate = Route.useNavigate();
	const search = Route.useSearch();
	const { announcementsQueryOptions } = Route.useRouteContext();
	const announcementsQuery = useQuery(announcementsQueryOptions);

	const tableState = useTableState<Announcement, { name?: string }>({
		search,
		navigate,
		defaultFilters,
		defaultSorting,
		fieldMap: [],
	});

	const table = useReactTable<Announcement>({
		data: announcementsQuery.data,
		...tableState,
		getCoreRowModel: getCoreRowModel(),
		getFacetedMinMaxValues: getFacetedMinMaxValues(),
		getFacetedUniqueValues: getFacetedUniqueValues(),
		getFacetedRowModel: getFacetedRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getSortedRowModel: getSortedRowModel(),
		enableFilters: false,
		enableColumnFilters: false,
		enableSorting: true,
		columnResizeMode: 'onChange',
		columns: [
			{
				accessorKey: 'name',
				header: t('announcements.headers.name'),
				cell: ({ row }) => (
					<Link
						to='/content/announcements/$announcementId'
						params={{ announcementId: row.original.contentGroupId! }}
						children={row.original.name}
					/>
				),
			},
			{
				accessorKey: 'active',
				header: t('announcements.headers.status'),
				cell: ({ row }) => {
					const status = row.original.active
						? t('announcements.labels.active')
						: t('announcements.labels.inactive');
					return <Text>{status}</Text>;
				},
			},
		],
	});

	return (
		<TableLayout
			title={t('announcements.title')}
			table={table}
			toolbarButtons={[
				<ToolbarButton
					key='add'
					tooltip={t('announcements.buttons.addAnnouncement')}
					icon={icons.AddIcon}
					onClick={() =>
						void navigate({
							to: '/content/announcements/add',
							viewTransition: false,
						})
					}
				/>,
			]}
		>
			<DataTable table={table} loading={announcementsQuery.isFetching} />
		</TableLayout>
	);
}
