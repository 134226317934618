import { useMemo } from 'react';
import { Container, Group, Stack } from '@mantine/core';
import type { RowData, Table } from '@tanstack/table-core';
import type { PropsWithChildren } from 'react';

import { FilterOutlineIcon, FilterSolidIcon } from '@apple/assets/icons';
import { useTranslation } from '@apple/lib/i18next';
import { TableFilters } from '@apple/ui/data-table';
import { Toolbar } from '@apple/ui/data-table/controls/Toolbar';
import { ToggleRightSidebarButton } from '@apple/ui/shell/components/ToggleRightSidebarButton';
import {
	useRightSidebarCollapsed,
	useRightSidebarSetComponent,
} from '@apple/ui/shell/contexts/right-sidebar';

type Props<T extends RowData> = PropsWithChildren<{
	title: string;
	table: Table<T>;
	toolbarButtons?: React.ReactNode[];
	debug?: boolean;
}>;

export function TableLayout<T extends RowData>({
	title,
	table,
	debug = false,
	toolbarButtons = [],
	children,
}: Props<T>) {
	const { t } = useTranslation();
	const hasFilterableColumns = useMemo(
		() => table.getAllColumns().some(column => column.getCanFilter()),
		[table],
	);

	useRightSidebarSetComponent({
		component: !hasFilterableColumns ? undefined : (
			<TableFilters
				table={table}
				clearLabel={t('controls:dataTable.resetFilters')}
				debug={debug}
			/>
		),
		toggleButtonProps: {
			icons: {
				expand: FilterOutlineIcon,
				collapse: FilterSolidIcon,
			},
			labels: {
				expand: t('controls:dataTable.showFilters'),
				collapse: t('controls:dataTable.hideFilters'),
			},
		},
	});

	const rightSidebar = useRightSidebarCollapsed();

	return (
		<Container p={0} fluid h='100vh'>
			<Group gap={0} align='flex-start' wrap='nowrap' w='100%' h='100%'>
				<Stack
					align='stretch'
					justify='space-between'
					h='100%'
					w='100%'
					gap={0}
					style={{
						flex: 1,
						direction: table.options.columnResizeDirection,
					}}
				>
					<Toolbar
						groupProps={{ bg: 'var(--apple-header-bg-color)' }}
						title={title}
						rightSection={() => [
							...toolbarButtons,
							(rightSidebar.isCollapsed ||
								(rightSidebar.isSmallerThanBreakpoint &&
									!rightSidebar.isCollapsed &&
									hasFilterableColumns)) && (
								<ToggleRightSidebarButton key='right-sidebar-toggle' />
							),
						]}
					/>
					{children}
				</Stack>
			</Group>
		</Container>
	);
}
