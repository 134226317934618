import axios from 'axios';
import type { PaginationState } from '@tanstack/table-core';

import { createODataPageResultSchema } from '@apple/utils/api/odata';
import type { ODataPageResult } from '@apple/utils/api/odata';

import { activityLogListingSchema } from '../models/activity';
import type {
	ActivityLogEntityType,
	ActivityLogFilters,
	ActivityLogListing,
} from '../models/activity';

const activityLogResultsSchema =
	createODataPageResultSchema<ActivityLogListing>(activityLogListingSchema);

const activityLogListItemArraySchema = activityLogListingSchema.array();

/** @see AppleRetail.Web.Features.Events.AppleRetailActivityLogSearchController */
export async function search(
	pagination: PaginationState,
	filters: ActivityLogFilters,
	signal?: AbortSignal,
): Promise<ODataPageResult<ActivityLogListing>> {
	const response = await axios.get<unknown>('/api/activity-log-search', {
		params: {
			filter: filters,
			pageNumber: pagination.pageIndex + 1,
			pageSize: pagination.pageSize,
		},
		signal,
	});

	return activityLogResultsSchema.parse(response.data);
}

/** @see AppleRetail.Web.Features.Events.AppleRetailActivityLogSearchController */
export async function getEntries(
	correlationId: string,
	entityType: ActivityLogEntityType,
	limit: number,
	signal?: AbortSignal,
): Promise<ActivityLogListing[]> {
	const response = await axios.get<unknown>('/api/activity-log-search/group', {
		params: {
			correlationId,
			entityType,
			limit,
		},
		signal,
	});

	return activityLogListItemArraySchema.parse(response.data);
}

/** @see AppleRetail.Web.Features.Events.AppleRetailActivityLogSearchController */
export function getUserActivityExportUri(): string {
	return axios.getUri({
		url: '/api/activity-log-search/export',
	});
}
