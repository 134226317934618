import { useState } from 'react';
import { Anchor, Modal, Stack, Title } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useReactTable } from '@tanstack/react-table';
import {
	getCoreRowModel,
	getFacetedMinMaxValues,
	getFacetedRowModel,
	getFilteredRowModel,
	getPaginationRowModel,
	getSortedRowModel,
} from '@tanstack/table-core';

import { useTranslation } from '@apple/lib/i18next';
import { DataTable } from '@apple/ui/data-table';

import { PurchaseSummaryTable } from './PurchaseSummaryTable';
import type { BudgetDetailsItem } from '../models/budget';

export interface BudgetDetailsTableProps {
	fiscalYear: number;
	singleStoreCustomerCode: string;
	periodNumber: number;
	budgetDetailData: BudgetDetailsItem[];
	isLoading: boolean;
	isFetching: boolean;
}

export function BudgetDetailsTable({
	fiscalYear,
	singleStoreCustomerCode,
	periodNumber,
	budgetDetailData,
	isLoading,
	isFetching,
}: BudgetDetailsTableProps) {
	const { t } = useTranslation('store');
	const [opened, { open, close }] = useDisclosure(false);
	const [categoryId, setCategoryId] = useState<number | null>(null);

	const handleCategoryClick = (selectedCategory: number) => {
		setCategoryId(selectedCategory);
		open();
	};

	const table = useReactTable({
		data: budgetDetailData ?? [],
		getCoreRowModel: getCoreRowModel(),
		getFacetedMinMaxValues: getFacetedMinMaxValues(),
		getFacetedRowModel: getFacetedRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getSortedRowModel: getSortedRowModel(),
		enableSorting: true,
		columnResizeMode: 'onChange',
		enableFilters: false,
		enableColumnFilters: false,
		manualFiltering: false,
		manualSorting: false,
		columns: [
			{
				accessorKey: 'categoryName',
				header: t('budgets.budgetDetails.headers.category'),
				cell: ({ row }) => (
					<Anchor onClick={() => handleCategoryClick(row.original.categoryId)}>
						{row.original.categoryName}
					</Anchor>
				),
			},
			{
				accessorKey: 'amountSpent',
				header: t('budgets.budgetDetails.headers.spent'),
			},
			{
				accessorKey: 'formattedPercentOfTotalBudget',
				header: t('budgets.budgetDetails.headers.budgetpercentage'),
			},
		],
	});

	function renderEmpty() {
		return (
			<Stack align='center'>
				<Title order={3}>{t('common:pagination:empty')}</Title>
			</Stack>
		);
	}

	return (
		<>
			<DataTable
				hidePageSize
				hidePagination
				table={table}
				variant='apple-table'
				minWidth='300px'
				renderEmpty={renderEmpty}
				loading={isFetching || isLoading}
			/>

			<Modal
				size='auto'
				opened={opened}
				onClose={close}
				title={t('budgets.purchaseSummary.title')}
				id='purchase-summary'
			>
				{categoryId && (
					<PurchaseSummaryTable
						singleStoreCustomerCode={singleStoreCustomerCode}
						periodNumber={periodNumber}
						categoryId={categoryId}
						fiscalYear={fiscalYear}
					/>
				)}
			</Modal>
		</>
	);
}
