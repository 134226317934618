import { notifications } from '@mantine/notifications';
import { useMutation, useSuspenseQueries } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';
import { getLogger } from 'loglevel';
import { z } from 'zod';

import { ErrorIcon, Success } from '@apple/assets/icons';
import { requireAuth } from '@apple/features/auth';
import { deleteProduct, saveProduct } from '@apple/features/product-retail/api/management';
import { ProductForm } from '@apple/features/product-retail/components/ProductManagement/ProductForm';
import { retailProductQueryOptions } from '@apple/features/product-retail/queries/management';
import { PRODUCTS_ODATA_QUERY_KEY } from '@apple/features/product-retail/queries/odata';
import { productQueryKeys, productQueryOptions } from '@apple/features/product/queries/management';
import { propagateChanges } from '@apple/features/store-events/api/change-propagation';
import { useTranslation } from '@apple/lib/i18next';
import { DetailsLayout } from '@apple/ui/layouts';
import { ServerValidationError } from '@apple/utils/api';
import { decodeSlashes, encodeSlashes } from '@apple/utils/url';
import type { ProductFormData } from '@apple/features/product-retail/models/management';

const log = getLogger('manage-product');

export const Route = createFileRoute('/_authed/_admin/manage/products_/$itemId')({
	params: {
		parse: ({ itemId }) => ({
			itemId: z.string().parse(decodeSlashes(itemId)),
		}),
		stringify: ({ itemId }) => ({
			itemId: z.string().parse(encodeSlashes(itemId)),
		}),
	},
	beforeLoad: ({ params: { itemId }, ...args }) => {
		requireAuth(args, {
			requirePermission: 'Manzanita.Security.Features.ProductManagement.Write',
		});
		return {
			productQueryOptions: retailProductQueryOptions.product(itemId),
			keywordsQueryOptions: productQueryOptions.keywords,
			productCategoriesQueryOptions: productQueryOptions.categories,
		};
	},
	loader: ({
		context: {
			queryClient,
			productQueryOptions,
			keywordsQueryOptions,
			productCategoriesQueryOptions,
		},
	}) => {
		void queryClient.prefetchQuery(productQueryOptions);
		void queryClient.prefetchQuery(keywordsQueryOptions);
		void queryClient.prefetchQuery(productCategoriesQueryOptions);
	},
	wrapInSuspense: true,
	component: EditProductRoute,
});

function EditProductRoute() {
	const { t } = useTranslation('product');
	const {
		productQueryOptions,
		productCategoriesQueryOptions,
		keywordsQueryOptions,
		queryClient,
	} = Route.useRouteContext();

	const [productQuery, productLinesQuery, keywordsQuery] = useSuspenseQueries({
		queries: [productQueryOptions, productCategoriesQueryOptions, keywordsQueryOptions],
	});

	const navigate = Route.useNavigate();

	async function handleSuccessfulChange(message: string) {
		void queryClient.invalidateQueries({
			queryKey: productQueryOptions.queryKey,
		});
		void queryClient.invalidateQueries({
			queryKey: [PRODUCTS_ODATA_QUERY_KEY],
		});
		notifications.show({
			message: message,
			icon: <Success />,
			color: 'green',
			autoClose: 5000,
		});
		await navigate({
			to: '/manage/products',
		});
	}

	function showErrorNotification(error: Error) {
		if (!(error instanceof ServerValidationError)) {
			log.error(error);
			notifications.show({
				message: t('common:error.generic'),
				icon: <ErrorIcon />,
				color: 'red',
				autoClose: 5000,
			});
		}
	}

	const updateProductMutation = useMutation({
		mutationFn: async (product: ProductFormData) => await saveProduct(product, 'N/A'),
		onSuccess: async (_, product) => {
			if (product.eventRulePropagations && product.eventRulePropagations.length > 0) {
				await propagateChanges(product.eventRulePropagations, 'N/A');
			}
			await handleSuccessfulChange(t('notifications.updated'));
		},
		onError: showErrorNotification,
	});

	const deleteProductMutation = useMutation({
		mutationFn: async (itemId: string) => await deleteProduct(itemId, 'N/A'),
		onSuccess: async () => {
			void queryClient.invalidateQueries({
				queryKey: productQueryKeys.unassignedSkus,
				exact: true,
			});
			await handleSuccessfulChange(t('notifications.deleted'));
		},
		onError: showErrorNotification,
	});

	return (
		<DetailsLayout title={t('titles.editProduct', { itemId: productQuery.data?.itemId })}>
			<ProductForm
				isEdit
				product={productQuery.data}
				productCategoriesQuery={productLinesQuery}
				keywordsQuery={keywordsQuery}
				saveProductMutation={updateProductMutation}
				deleteProductMutation={deleteProductMutation}
			/>
		</DetailsLayout>
	);
}
