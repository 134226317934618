import { createFileRoute } from '@tanstack/react-router';

import { requireAuth } from '@apple/features/auth';
import { DistroUploadPage } from '@apple/features/order-channel/components/DistroUploadPage';

export const Route = createFileRoute('/_authed/_admin/distro/upload')({
	beforeLoad: args =>
		requireAuth(args, {
			requirePermission: 'Manzanita.Security.Orders.CanPlaceDistroOrders',
		}),
	component: DistroUploadRoute,
});

function DistroUploadRoute() {
	const { auth } = Route.useRouteContext();

	const canPlaceGhostOrders = auth.hasPermissions([
		'Manzanita.Security.Orders.CanPlaceGhostOrders',
	]);
	const canPlaceFutureOrders = auth.hasPermissions([
		'Manzanita.Security.Orders.CanPlaceFutureOrders',
	]);

	return (
		<DistroUploadPage
			showAdminOptions={canPlaceGhostOrders || canPlaceFutureOrders}
			showGhostOrderOptions={canPlaceGhostOrders}
		/>
	);
}
