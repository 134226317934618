import { createFileRoute } from '@tanstack/react-router';

import { StoreSelectionStep } from '@apple/features/cart-retail/components/steps/stores';

export const Route = createFileRoute('/_authed/cart/stores')({
	component: RouteComponent,
});

function RouteComponent() {
	return <StoreSelectionStep />;
}
