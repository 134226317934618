import { notifications } from '@mantine/notifications';
import { useMutation, useSuspenseQuery } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';
import { getLogger } from 'loglevel';
import { z } from 'zod';

import { ErrorIcon, Success } from '@apple/assets/icons';
import { requireAuth } from '@apple/features/auth';
import { deleteStore, saveStore } from '@apple/features/store/api/management';
import { StoreForm } from '@apple/features/store/components/StoreForm';
import { storeQueries, storeQueryKeys } from '@apple/features/store/queries';
import { useTranslation } from '@apple/lib/i18next';
import { DetailsLayout } from '@apple/ui/layouts';
import { ServerValidationError } from '@apple/utils/api';

const log = getLogger('manage-store');

export const Route = createFileRoute('/_authed/_admin/manage/stores_/$storeCode')({
	parseParams: ({ storeCode }) => ({
		storeCode: z.string().parse(storeCode),
	}),
	beforeLoad: ({ ...args }) => {
		requireAuth(args, {
			requirePermission: 'Manzanita.Security.Features.CustomerManagement',
		});
	},
	wrapInSuspense: true,
	component: StoresRoute,
});

function StoresRoute() {
	const { t } = useTranslation('store');
	const { storeCode } = Route.useParams();
	const { queryClient } = Route.useRouteContext();
	const navigate = Route.useNavigate();

	const storeDetailQuery = useSuspenseQuery(storeQueries.store(storeCode));

	async function handleSuccessfulChange(message: string) {
		await queryClient.invalidateQueries({
			queryKey: storeQueryKeys.all,
		});

		notifications.show({
			message: message,
			icon: <Success />,
			color: 'green',
			autoClose: 5000,
		});
		await navigate({
			to: '/manage/stores',
		});
	}

	function showErrorNotification(error: Error) {
		if (!(error instanceof ServerValidationError)) {
			log.error(error);
			notifications.show({
				message: t('common:error.generic'),
				icon: <ErrorIcon />,
				color: 'red',
				autoClose: 5000,
			});
		}
	}
	const saveStoreMutation = useMutation({
		mutationFn: saveStore,
		onSuccess: async () => {
			await handleSuccessfulChange(t('editStore.success'));
		},
		onError: showErrorNotification,
	});

	const deleteStoreMutation = useMutation({
		mutationFn: deleteStore,
		onSuccess: async () => {
			await handleSuccessfulChange(t('deleteStore.success'));
		},
		onError: showErrorNotification,
	});

	return (
		<DetailsLayout title={t('titles.editStore', { storeId: storeDetailQuery.data?.storeId })}>
			<StoreForm
				saveStoreMutation={saveStoreMutation}
				store={storeDetailQuery.data}
				deleteStoreMutation={deleteStoreMutation}
			/>
		</DetailsLayout>
	);
}
