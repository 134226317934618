import { StepLayout } from '@apple/features/cart/layouts/step';
import { NotImplementedPage } from '@apple/ui/shell/pages/NotImplementedPage';
import { notifications } from '@mantine/notifications';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/_authed/store-events/_steps/$storeEventId/products')({
	component: RouteComponent,
});

function RouteComponent() {
	const { storeEventId } = Route.useParams();

	return (
		<StepLayout
			buttonLeft={{
				to: '/store-events/$storeEventId/categories',
				params: {
					storeEventId,
				},
			}}
			buttonRight={{
				text: 'Submit',
				onClick: () => {
					notifications.show({
						title: 'Not Implemented',
						message: 'This feature is not implemented yet',
						color: 'red',
					});
				},
				// disabled: !isStepComplete,
			}}
		>
			<NotImplementedPage name={'Store Event Products'} />
		</StepLayout>
	);
}
