import { useQueryClient } from '@tanstack/react-query';
import type { AnyRouter, RegisteredRouter } from '@tanstack/react-router';
import type { PropsWithChildren } from 'react';

import { AuthProvider as AuthProviderBase } from '@apple/features/auth/contexts/auth';
import { useAuth } from '@apple/features/auth/hooks/useAuth';
import type { UpdateProfileRequest } from '@apple/features/auth/models/user';
import { globalizationQueryKeys } from '@apple/utils/globalization/queries';

import * as AuthService from '../api/authentication';
import { profileQueryOptions } from '../queries/profile';
import { AuthContext } from './auth-context';

export function AuthProvider<TRouter extends AnyRouter = RegisteredRouter>({
	children,
	router,
}: PropsWithChildren<{ router: TRouter }>) {
	const auth = useAuth({ router, profileQueryOptions });
	const client = useQueryClient();

	async function updateProfile(request: UpdateProfileRequest) {
		await AuthService.updateProfile(request);
		await Promise.all([
			client.resetQueries({ queryKey: profileQueryOptions().queryKey }),
			client.resetQueries({ queryKey: globalizationQueryKeys.all }),
		]);
	}

	return (
		<AuthContext.Provider
			value={{
				...auth,
				canAccessMultipleStores: auth.profile?.hasMultipleStores ?? false,
				updateProfile,
			}}
		>
			<AuthProviderBase auth={{ ...auth, updateProfile }} loading={auth.loading}>
				{children}
			</AuthProviderBase>
		</AuthContext.Provider>
	);
}
