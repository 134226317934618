import { useMemo } from 'react';
import { Container, rem, Stepper } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { createFileRoute, Outlet, useMatches } from '@tanstack/react-router';

import { Success } from '@apple/assets/icons';
import { useTranslation } from '@apple/lib/i18next';

export const Route = createFileRoute('/_authed/cart')({
	component: RouteComponent,
});

function RouteComponent() {
	const { auth } = Route.useRouteContext();
	const matches = useMatches();
	const { t } = useTranslation('shop');
	const vertical = useMediaQuery('(max-width: lg)');
	const currentStepIndex = useMemo(() => {
		const lastMatch = matches.at(-1);
		switch (lastMatch?.fullPath) {
			case '/cart/stores':
				return 0;
			case '/cart/products':
				return 1;
			case '/cart/quantities':
				return 2;
			case '/cart/shipping':
				return 3;
			case '/cart/confirmation/$salesOrderNumber':
				return 4;
			default:
				return 0;
		}
	}, [matches]);

	return (
		<Container size='xl' p='md'>
			<Stepper
				size='sm'
				completedIcon={<Success c='white' />}
				active={currentStepIndex}
				// onStepClick={onGotoStep}
				allowNextStepsSelect={false}
				orientation={vertical ? 'vertical' : 'horizontal'}
				styles={{
					steps: {
						paddingLeft: rem(32),
						paddingRight: rem(32),
						paddingTop: rem(32),
					},
				}}
			>
				{auth.canAccessMultipleStores && (
					<Stepper.Step
						label={t('steps.stores.title')}
						description={t('steps.stores.description')}
					>
						<Outlet />
					</Stepper.Step>
				)}

				<Stepper.Step
					label={t('steps.products.title')}
					description={t('steps.products.description')}
				>
					<Outlet />
				</Stepper.Step>
				<Stepper.Step
					label={t('steps.quantities.title')}
					description={t('steps.quantities.description')}
				>
					<Outlet />
				</Stepper.Step>
				<Stepper.Step
					label={t('steps.shipping.title')}
					description={t('steps.shipping.description')}
				>
					<Outlet />
				</Stepper.Step>
				<Stepper.Completed>
					<Outlet />
				</Stepper.Completed>
			</Stepper>
		</Container>
	);
}
