import { useMemo } from 'react';
import { keepPreviousData, queryOptions, useSuspenseQuery } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';

import { getAllAnnouncements } from '@apple/features/announcements/api/management';
import { AnnouncementForm } from '@apple/features/announcements/components/AnnouncementForm';
import { useAnnouncementManagement } from '@apple/features/announcements/hooks/useAnnouncementManagement';
import { requireAuth } from '@apple/features/auth';
import { useTranslation } from '@apple/lib/i18next';
import { DetailsLayout } from '@apple/ui/layouts';

export const Route = createFileRoute('/_authed/_admin/content/announcements_/add')({
	beforeLoad: args => {
		requireAuth(args);

		return {
			announcementsQueryOptions: queryOptions({
				queryKey: ['announcements'],
				queryFn: () => getAllAnnouncements(),
				placeholderData: keepPreviousData,
				throwOnError: true,
			}),
		};
	},
	loader: ({ context: { queryClient, announcementsQueryOptions } }) => {
		void queryClient.prefetchQuery(announcementsQueryOptions);
	},
	wrapInSuspense: true,
	component: ManageAnnouncementRoute,
});

function ManageAnnouncementRoute() {
	const { t } = useTranslation('content');
	const { announcementsQueryOptions } = Route.useRouteContext();
	const announcementsQuery = useSuspenseQuery(announcementsQueryOptions);
	const { add } = useAnnouncementManagement();

	const activeAllowed = useMemo(() => {
		const activeAnnouncement = announcementsQuery.data.find(x => x.active);
		return !activeAnnouncement;
	}, [announcementsQuery.data]);

	return (
		<DetailsLayout title={t('announcements.add.title')}>
			<AnnouncementForm
				activeAllowed={activeAllowed}
				onSave={add.mutateAsync}
				loading={add.isPending}
				submitted={add.isSuccess}
			/>
		</DetailsLayout>
	);
}
