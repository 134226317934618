import { createFileRoute } from '@tanstack/react-router';

import { ProductSelectionStep } from '@apple/features/cart-retail/components/steps/products';

export const Route = createFileRoute('/_authed/cart/products')({
	component: RouteComponent,
});

function RouteComponent() {
	const { auth } = Route.useRouteContext();
	return <ProductSelectionStep canAccessMultipleStores={auth.canAccessMultipleStores} />;
}
