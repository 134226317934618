import { z } from 'zod';

/** @see Manzanita.Associations.AssociationType */
export enum AssociationType {
	CustomerGroup = 1,
	Customer = 2,
	Plant = 3,
	Country = 4,
	CustomerGroupPlant = 5,
	CustomerGroupCountry = 6,
	All = 7,
	UserRole = 8,
}

/** @see Manzanita.Associations.AssociationType */
export const associationTypeSchema = z.nativeEnum(AssociationType);
