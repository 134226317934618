import { StepLayout } from '@apple/features/cart/layouts/step';
import { updateStoreEvent } from '@apple/features/store-events/api/management';
import { StoreEventDetails } from '@apple/features/store-events/components/details';
import {
	DEFAULT_STORE_EVENT_UPDATE_MODEL,
	type StoreEventUpdateModel,
	storeEventUpdateModelSchema,
} from '@apple/features/store-events/models/management';
import { storeEventQueries, storeEventQueryKeys } from '@apple/features/store-events/queries';
import { BlockNavigation } from '@apple/ui/form/components/BlockNavigation';
import { ServerValidatedZodForm } from '@apple/ui/form/components/ValidatedForm/ServerValidatedZodForm';
import { useLogger } from '@apple/utils/logging/useLogger';
import { useMutation, useSuspenseQuery } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';
import { t } from 'i18next';
import { defaults } from 'lodash-es';
import { useMemo } from 'react';

export const Route = createFileRoute('/_authed/store-events/_steps/$storeEventId/details')({
	component: RouteComponent,
	wrapInSuspense: true,
});

function RouteComponent() {
	const navigate = Route.useNavigate();
	const { readOnly, queryClient } = Route.useRouteContext();
	const { storeEventId } = Route.useParams();
	const storeEventQuery = useSuspenseQuery(storeEventQueries.getById(storeEventId));

	// TODO: Make this into a custom hook (maybe useFormInitialValues(defaultValues, currentValues) or something)
	const initialValues = useMemo<StoreEventUpdateModel>(() => {
		return defaults(storeEventQuery.data, DEFAULT_STORE_EVENT_UPDATE_MODEL);
	}, [storeEventQuery.data]);

	useLogger({
		log: 'store-events',
		name: 'StoreEventDetails',
		props: [{ readOnly, storeEvent: storeEventQuery.data, initialValues }],
	});

	const editMutation = useMutation({
		mutationFn: (value: StoreEventUpdateModel) => updateStoreEvent(storeEventId, value),
		onSuccess: async () => {
			await Promise.all([
				queryClient.invalidateQueries({
					queryKey: storeEventQueryKeys.list(),
				}),
				queryClient.invalidateQueries({
					queryKey: storeEventQueryKeys.storeEvent(storeEventId),
				}),
			]);
			await navigate({
				to: '/store-events/$storeEventId/categories',
				params: {
					storeEventId,
				},
			});
		},
	});

	return (
		<ServerValidatedZodForm
			name={'store-event-details'}
			validationType='EditEventDetails'
			schema={storeEventUpdateModelSchema}
			initialValues={initialValues}
			ignoreModelErrorParent
			onValidSubmit={async value => {
				await editMutation.mutateAsync(value);
			}}
		>
			{({ form, submit, isSubmitting }) => (
				<StepLayout
					mutationErrors={[editMutation.error]}
					buttonLeft={{
						text: t('common:buttons.back'),
						to: '/store-events',
					}}
					buttonRight={{
						loading: editMutation.isPending || isSubmitting,
						disabled: editMutation.isPending || isSubmitting,
						onClick: () => {
							submit();
						},
					}}
				>
					<BlockNavigation
						condition={
							// Stop navigation when the form is dirty and not saved
							form.isDirty() &&
							!(editMutation.isPending || editMutation.isSuccess || isSubmitting)
						}
					/>
					<StoreEventDetails
						readOnly={readOnly}
						form={form}
						storeId={storeEventQuery.data.storeNumber ?? undefined}
						storeUtcOffsetMinutes={storeEventQuery.data.storeUtcOffsetMinutes}
					/>
				</StepLayout>
			)}
		</ServerValidatedZodForm>
	);
}
